import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeHr from '@angular/common/locales/hr';

registerLocaleData(localeHr, 'en-HR');

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateAdapter} from '@angular/material/core';


import { PocetnaComponent } from './pocetna/pocetna.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ReactiveFormsModule } from '@angular/forms';
import { RacuniComponent } from './racuni/racuni.component'; 
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PlacanjaComponent } from './placanja/placanja.component';
import { KlijentiComponent } from './klijenti/klijenti.component';
import { SaldakontiComponent } from './saldakonti/saldakonti.component';
import { PostavkeComponent } from './postavke/postavke.component';
import { MomentModule } from 'ngx-moment';
import { KlijentiIrComponent } from './klijenti-ir/klijenti-ir.component';
import { KlijentiIrStavkeComponent } from './klijenti-ir-stavke/klijenti-ir-stavke.component';
import { KlijentiIrUredjivanjeComponent } from './klijenti-ir-uredjivanje/klijenti-ir-uredjivanje.component';
import { AppDateAdapter, APP_DATE_FORMATS } from './dateformats';
import { KlijentiPartneriComponent } from './klijenti-partneri/klijenti-partneri.component';
import { KlijentiPartneriUredComponent } from './klijenti-partneri-ured/klijenti-partneri-ured.component';
import { PlacanjeByOzn, PlacanjeById } from './custom-pipes';
import { KlijentiIrStavUredComponent } from './klijenti-ir-stav-ured/klijenti-ir-stav-ured.component';
import { KlijentiArtikliComponent } from './klijenti-artikli/klijenti-artikli.component';
import {HotkeyModule} from 'angular2-hotkeys';
import { IrNapomenePredlozakComponent } from './ir-napomene-predlozak/ir-napomene-predlozak.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { KlijentiArtikliSifarnikComponent } from './klijenti-artikli-sifarnik/klijenti-artikli-sifarnik.component';
import { InterniAktComponent } from './interni-akt/interni-akt.component';
import { KlijentiPrometComponent } from './klijenti-promet/klijenti-promet.component';
import { KpiComponent } from './kpi/kpi.component';


export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MM/YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MM/YYYY'
  },
}; 

@NgModule({
  declarations: [
    AppComponent,
    PocetnaComponent,
    LoginComponent,
    RacuniComponent,
    PlacanjaComponent,
    KlijentiComponent,
    SaldakontiComponent,
    PostavkeComponent,
    KlijentiIrComponent,
    KlijentiIrStavkeComponent,
    KlijentiIrUredjivanjeComponent,
    KlijentiPartneriComponent,
    KlijentiPartneriUredComponent,
    PlacanjeByOzn,
    PlacanjeById,
    KlijentiIrStavUredComponent,
    KlijentiArtikliComponent,
    IrNapomenePredlozakComponent,
    KlijentiArtikliSifarnikComponent,
    InterniAktComponent,
    KlijentiPrometComponent,
    KpiComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule ,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatCardModule,
    MatProgressBarModule,
    MatDialogModule,
    MatDividerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    HotkeyModule.forRoot()
  ],
  
  providers: [
    {provide: LOCALE_ID, useValue: 'en-HR' },
    { provide: DateAdapter, useClass: AppDateAdapter }, 
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    {provide: MAT_DATE_LOCALE, useValue: 'hr'},
    /* {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}} */
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

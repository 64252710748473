import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AppService } from '../app.service';
import { KlijentiIrService, RacunIr, RacunNapomena, RacunStavka, RnNapomenaPred, VracunIR } from '../klijenti-ir.service';
import { NaplUredjaj, Podaci, PoslovniProstor, VposNaplUred } from '../klijenti.service';
import { BillNumber, TaxRate, FisRacun, Refund } from '../modeli/fis-racun';
import { DOCUMENT } from '@angular/common';
import { FontawesomeIconsService } from '../fontawesome-icons.service';
import { ArrDropDown, ArrLeaveUp, DropDownAnimation, NavMsg } from '../animacije';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IrNapomenePredlozakComponent } from '../ir-napomene-predlozak/ir-napomene-predlozak.component';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

@Component({
  selector: 'app-klijenti-ir',
  templateUrl: './klijenti-ir.component.html',
  styleUrls: ['./klijenti-ir.component.css'],
  animations: [DropDownAnimation, NavMsg, ArrDropDown, ArrLeaveUp]
})
export class KlijentiIrComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router,
    private http: HttpClient,
    public appService: AppService,
    public srvKlijentiIR: KlijentiIrService,
    public srvFa: FontawesomeIconsService,
    private frmBuilder: FormBuilder,
    private hotkeysService: HotkeysService
  ) { }

  @ViewChild('etPrint', { static: true }) etPrint: ElementRef;
  @ViewChild('napPred', { static: true }) napPred: IrNapomenePredlozakComponent;

  objPoslNp: VposNaplUred[];
  objPoslNpSel: VposNaplUred;
  objGodine: number[];
  godSel: number;
  objVracuni: VracunIR[];
  objRnSel: VracunIR = null;
  intScroll: number = 0;

  frmNapomena: FormGroup;
  napFlag: boolean = true;

  intFiskalStart: number = 0;

  compareObjects(obj1: any, obj2: any): boolean {
    return (obj1 == obj2);
  }


  ngOnInit() {

    this.hotkeysService.add(new Hotkey('f5', (event: KeyboardEvent): boolean => {
      console.log('F5 klijenti ir');
      this.noviRacun();
      return false; // Prevent bubbling
    }));

    //Učitavanje Poslovni protora i naplatnih uređaja ->localstorage
    if (localStorage.getItem('vPosNaplUred')) {
      this.objPoslNp = JSON.parse(localStorage.getItem('vPosNaplUred'));
    }

    //Učitavaje vPoslNaplSel -> localstorage
    if (localStorage.getItem('objPoslNpSel')) {
      this.objPoslNpSel = JSON.parse(localStorage.getItem('objPoslNpSel'));
    } else {
      if (this.objPoslNp && this.objPoslNp.length == 1 && this.objPoslNp[0].np.length == 1) {
        this.objPoslNpSel = this.objPoslNp[0];
        localStorage.setItem('objPoslNpSel', JSON.stringify(this.objPoslNpSel));
      }
    }

    //Učitavanje Godina[]
    this.srvKlijentiIR.godineIR(parseInt(localStorage.getItem('korID')), rez => {
      this.objGodine = rez;
    })

    //Učitavanje odabranog računa
    if (localStorage.getItem('objRnSel')) {
      this.objRnSel = JSON.parse(localStorage.getItem('objRnSel'));
    }

    //Postavljanje početne godine
    if (localStorage.getItem('godinaSel')) {
      this.godSel = parseInt(localStorage.getItem('godinaSel'));
    } else {
      this.godSel = parseInt(moment().format('YYYY'));
    }

    localStorage.setItem('godinaSel', this.godSel.toString());

    this.ucitajRacune(this.godSel);

    this.srvKlijentiIR.napomenePred(this.appService.objPodaciSel.PodaciID, (rez: RnNapomenaPred[]) => {
      console.log(rez);
      this.srvKlijentiIR.objPredlosci = rez;
    })

    //Subscribe Napomene predlošci Subject change
    this.srvKlijentiIR.objIrNapomene$.subscribe(data => {

      //objVracuni
      this.objVracuni.forEach(val2 => {
        if (val2.rnID == this.objRnSel.rnID) {
          data.forEach(val3 => {
            if (!val2.napomene) {
              val2.napomene = [];
            }
            val2.napomene.push(val3);
          });
          this.objRnSel = val2;
          localStorage.setItem('objRnSel', JSON.stringify(this.objRnSel));
        }
      });

    });

  }

  //OPIS PLAĆANJA BY ID
  getPlacanjeByID(id: number) {

    return this.appService.getPlacanjeByID(id);

  }

  ngAfterViewInit() {

  }

  //postavi Godinu rada
  godinaSel(ev) {

    //godinaSel == [(value)]

    this.godSel = ev.source.value;
    localStorage.setItem('godinaSel', ev.source.value);

    this.ucitajRacune(ev.source.value);

  }

  //Odabir naplatnog uređaja
  postaviVposNaplUred(x: VposNaplUred, npuID: number) {

    this.objPoslNpSel = x;

    let npTmp: NaplUredjaj

    x.np.forEach(va => {
      if (va.npuID == npuID) {
        npTmp = va;
      }
    });

    this.objPoslNpSel.np = [];
    this.objPoslNpSel.np.push(npTmp);

    localStorage.setItem('objPoslNpSel', JSON.stringify(this.objPoslNpSel));

    this.objPoslNp = JSON.parse(localStorage.getItem('vPosNaplUred'));

    this.ucitajRacune(this.godSel);

  }

  //Učitavanje računa (vRacunIR)
  ucitajRacune(god) {

    if (this.objPoslNpSel) {
      let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=vIRbyGodina";
      let obj: string = JSON.stringify({ parKlijentID: parseInt(localStorage.getItem('korID')), npuID: this.objPoslNpSel.np[0].npuID, god: god });
  
      this.http.post(url, obj,).subscribe((rez: VracunIR[]) => {
        console.log(rez);
        this.objVracuni = rez;
      });
    } else {
      this.objVracuni = null;
    }

  }

  //Novi račun
  noviRacun() {

    this.objRnSel = null;
    localStorage.removeItem('objRnSel');

    this.router.navigate(['klijenti-ir-uredjivanje']);

  }

  //Scroll
  scrollInto() {
    if (this.intScroll == 0) {
      if (this.objRnSel) {
        setTimeout(() => {
          document.getElementById('rn' + this.objRnSel.rnID.toString()).scrollIntoView({ behavior: "smooth", block: "center" });
          console.log('scroll');
        }, 200);

      }
      this.intScroll = 1;
    }
  }

  //Odabir naplatnih uređaja - poništavanje objPoslNpSel
  ponistiVposNplSel() {

    localStorage.removeItem('objPoslNpSel');
    this.objPoslNpSel = null;

  }

  //frmNapomena
  napraviFrmNapomena(val: RacunNapomena) {

    this.frmNapomena = this.frmBuilder.group({
      rnnID: [val.rnnID],
      rnIDnap: [val.rnIDnap],
      rnnNapomena: [val.rnnNapomena, Validators.required]
    });

  }

  //Nova napomena
  novaNapomena() {
    let v: RacunNapomena = {
      rnnID: 0,
      rnIDnap: this.objRnSel.rnID,
      rnnNapomena: ''
    }
    this.napraviFrmNapomena(v);
  }

  //Spremi napomenu
  spremiNapomenu(pred: number) { // 0 = spremanje ; 1 = spremanje + predložak

    this.frmNapomena.markAllAsTouched();

    if (this.frmNapomena.valid) {

      let rnnIdTmp: number = this.frmNapomena.get('rnnID').value;

      this.srvKlijentiIR.spremiNapomenu(this.frmNapomena.value, pred, (rez: RacunNapomena) => {

        if (rez) {

          this.frmNapomena.patchValue(rez);

          //Update objekata
          if (rnnIdTmp) {
            //Update - splice
            this.objVracuni.forEach(v0 => {
              if (v0.rnID == rez.rnIDnap) {
                v0.napomene.forEach((v1, ind1) => {
                  if (v1.rnnID == rez.rnnID) {
                    v0.napomene.splice(ind1, 1, rez);
                    this.objRnSel = v0;
                  }
                })
              }
            });

            this.objRnSel.napomene.forEach((val, ind) => {
              if (val.rnnID == rez.rnnID) {
                /* this.objRnSel.napomene.splice(ind, 1, rez); */
                this.objRnSel.napomene[ind]['add'] = true;
              } else {
                this.objRnSel.napomene[ind]['add'] = false;
              }
            });


          } else {
            //Novi - push
            if (!this.objRnSel.napomene) {
              this.objRnSel.napomene = [];
            }
            /* this.objRnSel.napomene.push(rez); */
            this.objVracuni.forEach((val, ind) => {
              if (val.rnID == rez.rnIDnap) {
                if (!val.napomene) {
                  val.napomene = [];
                }
                val.napomene.push(rez);
                this.objRnSel = val;
              }
            });
          }

          localStorage.setItem('objRnSel', JSON.stringify(this.objRnSel));
          this.frmNapomena = null;

          this.srvKlijentiIR.napomenePred(this.objRnSel.rnKlijentID, (rez: RnNapomenaPred[]) => {
            this.srvKlijentiIR.objPredlosci = rez;
          })

          //this.appService.msgBox(1, "Uspješno spremljeno!", 3000);

        } else {
          this.appService.msgBox(2, "Greška prilikom spremanja!", 3000);
        }

        console.log(rez);

      });


    } else {
      this.appService.msgBox(2, "Provjerite ispravnost upisnih polja!", 3000);
    }

  }

  //napomene predlošci start
  napomenaPredStart() {
    document.getElementById('btnMdlIrNapPred').click();
  }

  //Obriši napomenu
  obrisiNapomenu(y2: RacunNapomena) {

    this.srvKlijentiIR.obrisiNapomenu(y2, rez => {

      if (rez == 'ok') {

        let rnnID: number = y2.rnnID;
        let rnIDnap: number = y2.rnIDnap;

        //objVracuni
        this.objVracuni.forEach(v1 => {
          if (v1.rnID == rnIDnap) {
            v1.napomene.forEach((v2, ind2) => {
              if (v2.rnnID == rnnID) {
                v1.napomene.splice(ind2, 1);
              }
            });
            this.objRnSel = v1;
          }
        });

        localStorage.setItem('objRnSel', JSON.stringify(this.objRnSel));

        this.appService.msgBox(1, "Napomena je uspješno obrisana", 4000);

      } else {
        this.appService.msgBox(2, 'Greška prilikom brisanja', 3000);
      }

    });

  }

  //Uređivanje napomene
  urediNapomenu(val: RacunNapomena) {

    console.log(val);
    this.napraviFrmNapomena(val);

  }

  otkaziNapomenu() {
    console.log('reset');
    this.frmNapomena = null;
  }

  //fiskaliziraj račun
  fiskalizirajRacun() {

    //PDV
    let pdv: TaxRate[] = [];
    /* let pdv1: TaxRate = { name: null, rate: 25.00, baseValue: 150.00, value: 40.00 };
    pdv.push(pdv1);
    let pdv2: TaxRate = { name: null, rate: 13.00, baseValue: 150.00, value: 23.73 };
    pdv.push(pdv2); */

    //porez na potrošnju
    let pnp: TaxRate[] = [];
    let pnp1: TaxRate = { name: null, rate: 3.00, baseValue: 70.00, value: 4.02 };
    pnp.push(pnp1);

    //Ostali porezi
    let otherTr: TaxRate[] = [];
    let otherTr1: TaxRate = { name: 'Porez na osiguranje', rate: 5.00, baseValue: 23.12, value: 1.99 };
    otherTr.push(otherTr1);

    //refundacije (povratna naknada...)
    let refund: Refund[] = [];
    let refund1: Refund = { nameRefund: 'Povratna naknada', valueRefund: 10.18 };
    refund.push(refund1);

    //Broj računa
    let billNum: BillNumber = { numberNoteBill: this.objRnSel.rnBroj, noteOfBusinessArea: this.objPoslNpSel.posOzn, noteOfExcangeDevice: Number(this.objPoslNpSel.np[0].npuNaplUr) };

    let objKl: Podaci = JSON.parse(localStorage.getItem('objKlijent'));

    //Račun
    let rn = new FisRacun();

    rn.oib = objKl.OIB;
    rn.havePDV = objKl.Porezni_Obveznik_Da_NE_int == 1 ? true : false;
    rn.dateTime = moment(this.objRnSel.rnDatum).format('DD.MM.YYYYThh:mm:ss');
    rn.noteOfOrder = this.objPoslNpSel.posOznSl;
    rn.billNumber = billNum;
    rn.noteOfBusinessArea = this.objPoslNpSel.posOzn;
    rn.numberNoteBill = this.objRnSel.rnBroj;

    if (rn.havePDV) {

      this.objRnSel.pdv.forEach((val) => {

        let pdv1: TaxRate = { name: null, rate: val.stopa, baseValue: val.osnovica, value: val.iznos };
        pdv.push(pdv1);

      });

      rn.listPDV = pdv;

    }


    //rn.listPNP = pnp;
    //rn.listOtherTaxRate = otherTr;
    rn.taxFreeValuePdv = 0;
    rn.marginForTaxRate = 0;
    rn.taxFreeValue = 0;
    //rn.refund = refund;

    let totalTmp: number = 0;
    this.objRnSel.stavke.forEach((val: RacunStavka) => {
      totalTmp += Number(val.ukupno);
    });
    rn.totalValue = totalTmp;

    rn.typeOfPaying = this.objPoslNpSel.np[0].npNplacanja;
    rn.oibOperative = this.appService.objInfoKorinsik.operOIB;
    rn.noteOfRedelivary = false;
    rn.noteOfParagonBill = null;
    rn.specificPurpose = 'Sharp Robotics obrt za proizvodnju i informatičke usluge, OIB: 89209470523';

    rn['rnID'] = this.objRnSel.rnID;
    rn['rnKlijentID'] = this.objRnSel.rnKlijentID;

    console.log(rn);

    //let url: string = 'http://localhost/fiskalApi/racun.php'; // FINANCING
    //let url: string = 'https://financing.hr/php/fiskalApi/racun.php';
    let url: string = 'https://sharprobotics.synology.me/sharpAccounting/php/fiskalApi/racun.php'; // LOKALNO
    let obj = JSON.stringify(rn);

    this.http.post(url, obj).subscribe(rez => {
      //console.log(rez['zki']);

      if (rez) {

        if (rez['err']) {

          this.objRnSel.rnZKI = rez['zki'];
          this.objRnSel.rnJIR = rez['jir'];
          console.log(rez['err']);
          this.azururajZkiJir(this.objRnSel.rnID, this.objRnSel.rnZKI, this.objRnSel.rnJIR);
          this.appService.msgBox(2, "Greška! Račun ćete morati naknadno fiskalizirati!", 2000);

        } else {

          this.srvKlijentiIR.updateZkiJir(this.objRnSel.rnID, rez['zki'], rez['jir'], rez2 => {

            console.log(rez2);

            if (rez2) {

              this.objRnSel.rnZKI = rez['zki'];
              this.objRnSel.rnJIR = rez['jir'];
              localStorage.setItem('objRnSel', JSON.stringify(this.objRnSel));
              this.azururajZkiJir(this.objRnSel.rnID, this.objRnSel.rnZKI, this.objRnSel.rnJIR);
              this.intFiskalStart = 0;
              this.appService.msgBox(1, "Uspješno fiskalizirano", 2000);

            } else {
              console.log('null');
              this.appService.msgBox(2, "Greška! Račun ćete morati naknadno fiskalizirati!", 2000);
            }

          });

        }

      } else {

        console.log('null');
        this.appService.msgBox(2, "Greška! Račun ćete morati naknadno fiskalizirati!", 2000);
      }

    });

    console.log(this.objVracuni);

  }

  //Ažuriranje zki i jir u listi računa
  azururajZkiJir(rnID: number, zki: string, jir: string) {

    this.objVracuni.forEach((val, ind) => {

      if (val.rnID == rnID) {
        this.objVracuni[ind].rnZKI = zki;
        this.objVracuni[ind].rnJIR = jir;
        console.log(zki + ' ' + jir);
      }

    })

  }

  //Postavljanje objRnSel
  odaberiRacun(rn: VracunIR) {

    if (this.objRnSel && this.objRnSel.rnID == rn.rnID) {
      this.objRnSel = null;
      localStorage.removeItem('objRnSel');
    } else {
      this.objRnSel = rn;
      localStorage.setItem('objRnSel', JSON.stringify(rn));
    }



    /* if (this.objRnSel) {
      if (this.objRnSel != rn) {
 
        this.objRnSel = rn;
        localStorage.setItem('objRnSel', JSON.stringify(rn));
      } else {
        this.objRnSel = null;
        localStorage.removeItem('objRnSel');
      }
    } else {
      this.objRnSel = rn;
      localStorage.setItem('objRnSel', JSON.stringify(rn));
    } */


  }

  ponistiRnSel(rnID) {

    if (this.objRnSel && rnID == this.objRnSel.rnID) {
      //document.getElementById('detalji' + rnID).classList.add('collapse');
      this.objRnSel = null;
      localStorage.removeItem('objRnSel');
    }


  }

  //Otvori stavke
  otvoriStavke(x: VracunIR) {
    this.router.navigate(['klijenti-ir-stavke']);
  }

  //Otvori unos / uređivanje
  otvoriUredjivanje() {
    this.router.navigate(['klijenti-ir-uredjivanje']);
  }

  //Ispis
  ispis() {
    /* print(); */

    this.srvKlijentiIR.racuniIrPdf(this.objRnSel, (rez) => {
      //console.log(rez);
    });

  }

}
function DOMContent(DOMContent: any, arg1: string[], body: HTMLElement) {
  throw new Error('Function not implemented.');
}


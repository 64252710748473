import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../login.service';
import { AppComponent } from '../app.component';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit {

  constructor(
    private loginService: LoginService,
    private router: Router,
    private formBuilder: FormBuilder,
    private appComponent: AppComponent,
    private http: HttpClient,
    public appService: AppService
  ) { }

  registerForm: FormGroup;
  submitted = false;

  /* kor: string;
  pass: string; */

  navBool: boolean = true;
  dlgFail: boolean = false;


  ngOnInit() {

    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4)]]
    });



  }

  fnKorID() {

    this.submitted = true;

    // stop the process here if form is invalid
    if (this.registerForm.invalid) {
      this.dlgFail = true;
      return;
    }

    let kor = this.registerForm.get('email').value;
    let pass = this.registerForm.get('password').value;

    this.loginService.login(kor, pass, () => {

      let rez = this.loginService.autorizirajStranicu((rez) => {

        if (rez) {

          this.dlgFail = false;
          this.navBool = true;

          this.appComponent.korID = this.loginService.intKorID;

          //alert('login: ' + this.appComponent.korID);

          this.router.navigate(['/pocetna']);

          let urlTmp: string = this.appService.webUrl + "php/";

          this.http.get(urlTmp + 'zr_rn.php?podaciID=' + localStorage.korID).subscribe(response => {

            this.appComponent.objZrRn = response;
            localStorage.objZrRn = JSON.stringify(response);

          });

        } else {
          this.dlgFail = true;
          this.appComponent.korID = 0;
        }

      });

      this.dlgFail = true;
      console.log(this.dlgFail);

    });

  }

}

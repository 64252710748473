import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppService, Mjesto } from '../app.service';
import { KlijentiIrService } from '../klijenti-ir.service';
import { KlijentiPartneriUredComponent } from '../klijenti-partneri-ured/klijenti-partneri-ured.component';
import { KlijentiPartneriService, Vpartner } from '../klijenti-partneri.service';

@Component({
  selector: 'app-klijenti-partneri',
  templateUrl: './klijenti-partneri.component.html',
  styleUrls: ['./klijenti-partneri.component.css']
})
export class KlijentiPartneriComponent implements OnInit, OnDestroy {

  constructor(
    public appService: AppService,
    public srvKlijentiPartneri: KlijentiPartneriService,
    public srvKlijentiIr: KlijentiIrService,
  ) { }

  @ViewChild('partner') partner1: KlijentiPartneriUredComponent;

  objPartneri: Vpartner[];
  fltPartneri: Vpartner[];
  strPretaga: string;


  ngOnInit() {
    this.ucitajPartnere();
    //Observable frmRačun
    this.srvKlijentiIr.objPartner$.subscribe(data => {

      this.srvKlijentiPartneri.objPartTmp = null;
      this.ucitajPartnere();

    });
  }

  //Partneri by Klijent ID
  ucitajPartnere() {
    this.srvKlijentiPartneri.partneriSelectAll((rez: Vpartner[]) => {
      console.log(rez);
      this.objPartneri = rez;
      this.fltPartneri = rez;
    });
  }

  //Filtriranje partnera
  filterPartneri() {
    if (!this.strPretaga) {
      this.fltPartneri = this.objPartneri;
    } else {
      this.fltPartneri = this.srvKlijentiPartneri.filterPartneri(this.objPartneri, this.strPretaga);
    }

  }

  //Dialog - Partner
  dlgPartner() {

    document.getElementById('btnMdlPartner').click();

    setTimeout(() => {
      /* this.partner.napraviFrmPartner(() => {}); */
      if (document.getElementById('oib')) {
        document.getElementById('oib').focus();
      }
    }, 500);

  }

  //Uređivanje podataka
  urediPartnera(x: Vpartner) {

    console.log(x);

    this.srvKlijentiPartneri.objPartTmp = x;

    setTimeout(() => {
      if (!x.oib) {
        this.partner1.oibDaNe();
      }
      this.partner1.frmPartner.patchValue(x);
      
      this.appService.mjestaSelectAll((rez: Mjesto[]) => {
        this.partner1.objMjesta = rez;
        this.partner1.nadjiMjestoById(x.mjestoID);
        console.log(x.Mjesto_ID);
      });
      
    }, 200);

  }

  ngOnDestroy() {
    this.srvKlijentiPartneri.objPartTmp = null;
  }

}

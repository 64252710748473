import { Component, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Artikl, KlijentiIrService, VracunIR } from '../klijenti-ir.service';
import { KlijentiService } from '../klijenti.service';
import { decimalCheck, MustMatch } from '../validatori';

@Component({
  selector: 'app-klijenti-ir-stav-ured',
  templateUrl: './klijenti-ir-stav-ured.component.html',
  styleUrls: ['./klijenti-ir-stav-ured.component.css']
})
export class KlijentiIrStavUredComponent implements OnInit {

  constructor(
    private frmBuilder: FormBuilder,
    public srvKlijenti: KlijentiService,
    public srvKlijentiIr: KlijentiIrService
  ) { }

  frmStavka: FormGroup;
  rnID: number;

  objArtikli: Artikl[];
  fltArtikli: Artikl[];

  ngOnInit() {

    if (localStorage.getItem('objRnSel')) {
      let rnTmp: VracunIR = JSON.parse(localStorage.getItem('objRnSel'));
      this.rnID = rnTmp.rnID;
    }

    this.srvKlijenti.artikliByKlijentId(parseInt(localStorage.getItem('korID')), (rez: Artikl[]) => {
      this.objArtikli = rez;
      this.napraviFrmStavka(() => {
        setTimeout(() => {
          console.log('time out');
          document.getElementById('artNaziv').focus();
        }, 500);
      });
    });

  }

  //frmStavka - create
  napraviFrmStavka(callback) {

    this.frmStavka = this.frmBuilder.group({
      rsID: [0],
      rsRnID: [this.rnID],
      rsArtiklID: [0],
      rsJedMj: [],
      artNaziv: ['', Validators.required],
      artNazivChk: ['', Validators.required],
      kol: [0, [Validators.required, decimalCheck]],
      cijena: [0, [Validators.required, decimalCheck]],
      iznos: [0],
      rabat: [0, [Validators.required, decimalCheck]],
      rabatIznos: [0],
      povNak: [0],
      povNakIznos: [0],
      osnovica: [0],
      pdvStopa: [0],
      pdvIznos: [0],
      ukupno: [0]
    }, { validator: MustMatch('artNaziv', 'artNazivChk') } as AbstractControlOptions);

    this.fltArtikli = null;

    callback(this.frmStavka.value);

  }

  //Formatiranje decimalnog broja
  decFormat(event) {
    let strNaziv: string = event.srcElement.ariaLabel;
    if (this.frmStavka.get(strNaziv).value) {
      this.frmStavka.get(strNaziv).setValue(this.frmStavka.get(strNaziv).value.replace(',', '.'));
    }
  }

  //Filtriranje artikala
  filterArtikli(strP) {
    this.fltArtikli = this.srvKlijentiIr.filterArtikli(this.objArtikli, strP);
  }

  //Odabir artikla
  postaviArtikl(event, art: Artikl) {

    if (event.source.selected) {
      this.frmStavka.get('rsArtiklID').setValue(art.artID);
      this.frmStavka.get('artNazivChk').setValue(art.artNaziv);
      this.frmStavka.get('rsJedMj').setValue(art.jedMj);
      this.frmStavka.get('cijena').setValue(art.artCijena);
      this.frmStavka.get('pdvStopa').setValue(art.artPdvStopa);
      this.frmStavka.get('povNak').setValue(art.artPovNak);
    }

  }

  //Glavni izračun
  izracun() {

    //Količina - parse number
    let sKol: string = String(this.frmStavka.get('kol').value).replace(",", ".");
    let kol: number = Number(sKol);

    //Cijena - parse number
    let sCijena: string = String(this.frmStavka.get('cijena').value).replace(",", ".");
    let cijena: number = Number(sCijena);

    //Iznos - izračun
    let iznos: number = kol * cijena;
    this.frmStavka.get('iznos').setValue(iznos);

    //Rabat - parse number
    let sRabat: string = String(this.frmStavka.get('rabat').value).replace(",", ".");
    let rabat: number = Number(sRabat);

    //Iznos - izračun
    let rabatIznos: number = iznos * rabat / 100;
    this.frmStavka.get('rabatIznos').setValue(rabatIznos);

    //Povratna naknada - parse number
    let sPovNak: string = String(this.frmStavka.get('povNak').value).replace(",", ".");
    let povNak: number = Number(sPovNak);

    //Povratna naknada iznos - izračun
    let povNakIznos: number = kol * povNak;
    this.frmStavka.get('povNakIznos').setValue(povNakIznos);

    //Iznos - izračun
    let osnovica: number = iznos - rabatIznos;
    this.frmStavka.get('osnovica').setValue(osnovica);

    //PDV stopa - parse number
    let sPdvStopa: string = String(this.frmStavka.get('pdvStopa').value).replace(",", ".");
    let pdvStopa: number = Number(sPdvStopa);

    //PDV iznos - izračun
    let pdvIznos: number = osnovica * pdvStopa / 100;
    this.frmStavka.get('pdvIznos').setValue(pdvIznos);

    //Ukupno - izračun
    let ukupno: number = povNakIznos + osnovica + pdvIznos;
    this.frmStavka.get('ukupno').setValue(ukupno);

  }

}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LoginService } from '../login.service';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { HttpClient, } from '@angular/common/http';
import { AppService } from '../app.service';

export interface gogoTmp {
  token: string;
  naziv: string;
  mjesto: string;
  adresa: string;
  oib: string;
}

@Component({
  selector: 'app-pocetna',
  templateUrl: './pocetna.component.html',
  styleUrls: ['./pocetna.component.css']
})

export class PocetnaComponent implements OnInit, AfterViewInit {

  constructor(
    private loginService: LoginService, 
    private appComponent: AppComponent, 
    private router: Router, 
    private http: HttpClient,
    public appService: AppService
    ) { }

  Naziv: string = localStorage.getItem('nazivP');
  accAdmin: boolean = false;
  
  objNaselja;
  fltNaselja;
  strNaselje;

  ngOnInit() {

    this.loginService.autorizirajStranicu(() => {
      
      this.appComponent.korID = this.loginService.intKorID;
      
      if (localStorage.accAdmin == 'true') {
        this.accAdmin = true;
      } else{
        this.accAdmin = false;
      }

    }); 

    //this.ucitajNaselja();

  }

  ngAfterViewInit() {

  }

  ucitajNaselja() {


  }

  filtrirajNaselja(strP: string) {

    //alert(strP);

    let output = [];

    this.objNaselja.forEach(objIme => {

      //alert(objIme.DokumentID.indexOf(intKD));

      if (objIme.Naselje.toLowerCase().indexOf(strP.toLowerCase()) >= 0) {
        output.push(objIme);
      }

    });

    this.fltNaselja = output;

    //alert(JSON.stringify(this.fltNaselja));

    

  }

  utvoriUrl(url: string, izbornik: string) {

    //alert(url);

    this.router.navigate([url]);
    this.appComponent.strIzbornik = izbornik;
    //this.router.navigate(['/pocetna']);

  }

}

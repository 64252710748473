<div class="container">

    <div class="row" style="margin: 0;">
        <div class="col-12">
            <!--TextBox Filter-->
            <div class="form-group">
                <mat-form-field class="example-full-width" style="width: 250px;">
                    <mat-label>Filter</mat-label>
                    <input matInput name="upitR" [(ngModel)]="upitK" placeholder="Naziv" (ngModelChange)="filterKlijenti(upitK)">
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="row" *ngFor="let x of fltKlijenti" style="margin: 0; padding: 10px" (click)="postaviKlijenta(x)" >

        <button class="btn" style="width: 100%">
            <span>{{x.Naziv}}, </span>
            <span class="inline">{{x.Adresa}} </span>
        </button>

    </div>



</div>

<!--Zaglavlje-->
<div class="alert alert-info">
    <div class="row">

        <div class="col-6">
            <p style="color: gainsboro">{{lblPoruka}}</p>
            <span style="color: gainsboro">Vrsta: </span><span>{{this.appComponent.vrstaR}}</span>
        </div>
        <div class="col-6">
            <span style="color: gainsboro">Razdoblje:
            </span><span>{{this.appComponent.dat1 | amDateFormat:'DD.MM.YYYY.'}}</span>
            <span> - {{this.appComponent.dat2 | amDateFormat:'DD.MM.YYYY.'}}</span>
            <br />
            <span style="color: gainsboro" *ngIf="this.appComponent.upitR">Filter:
            </span><span>{{this.appComponent.upitR}}</span>
        </div>


    </div>
</div>


<div class="container" *ngIf="this.racuniService.ucitavanje">
    <div style="font-size: 0.8rem;">učitavanje...</div>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <br />
</div>

<div class="alert alert-info" *ngIf="!this.racuniService.rsDataSource?.length && !this.racuniService.ucitavanje">

    <div style="font-size: 1.2rem;">
        <p>Nema podataka za prikaz!</p>
    </div>

</div>

<div class="container card" *ngFor="let x of this.racuniService.rsDataSource">

    <!--UR i Naziv-->
    <div class="row" style="padding: 0; margin: 0;">
        <div class="col-12 tblHead1" style="padding: 2px;">
            <h6>{{x.Naziv}} <span class="badge badge-pill badge-secondary float-right">{{x.URIR}}</span></h6>
        </div>
    </div>

    <div class="row" style="padding: 0; margin: 0;">
        <div class="col-6" style="padding: 2px;">
            <h6><span class="tblHead2Lbl">Broj računa: </span><span class="tblHead2">{{x.BrRn}}</span></h6>
        </div>
    </div>

    <!---->
    <div class="row" style="padding: 0; margin: 0;">

        <div class="col-6" style="padding: 2px;">
            <p>{{x.Opis}}</p>
        </div>
        <div class="col-6 text-right" style="padding: 2px;">
            <button class="btn text-center" *ngIf="x.cStavke > 0" data-toggle="modal" data-target="#myModal" (click)="btnStavkeClick(x.RacunID)">
                <span><i class="material-icons" style="vertical-align: middle;color: brown;">assignment</i></span>
                <span>Stavke</span>
            </button>

        </div>

    </div>

    <!--Footer-->
    <div class="row" style="padding: 0; padding-top: 3px; margin: 0;">

        <div class="col-6" style="padding: 2px;">
            <p>{{x.DatumRn }}</p>
        </div>
        <div class="col-6 d-flex" style="justify-content: flex-end;">
            <strong>
                {{x.Iznos | number:'1.2-2'}} kn
            </strong>
        </div>

        <div *ngIf="false" class="col-12" style="margin: 0px; padding: 0; background-color: ghostwhite;">
            <button *ngIf="x.placanja" class="btn btn-success" style="border-radius: 0px 0px 5px 5px;" data-toggle="collapse" [attr.data-target]="'#placanje' + x.RacunID">Plaćanja</button>
            <div *ngIf="!x.placanja" class="alert alert-secondary">Nema evidentiranih plaćanja</div>
        </div>



    </div>

    <div class="row collapse" style="margin-top: 5px; padding: 0; margin: 0;" *ngFor="let y of x.placanja" id="{{'placanje' + x.RacunID}}">
        <div class="col-3" style="padding: 5px 10px;">{{y.DatumKnj | amDateFormat:'DD.MM.YYYY.'}}</div>
        <div class="col-6" style="padding: 5px 5px;">{{y.ZrRn}}</div>
        <div class="col-3 d-flex" style="padding: 5px 10px; justify-content: right;">{{y.Iznos | number:'1.2-2'}}</div>
    </div>

</div>
<!-- <button type="button" class="btn btn-primary" (click)="this.appComponent.dlgModal = 'block'">Launch demo modal</button> -->
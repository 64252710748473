import { Component, OnInit } from '@angular/core';
import { KlijentiService, Podaci, PosNpOperater, VposNaplUred } from '../klijenti.service';

@Component({
  selector: 'app-interni-akt',
  templateUrl: './interni-akt.component.html',
  styleUrls: ['./interni-akt.component.css']
})
export class InterniAktComponent implements OnInit {

  constructor(
    public srvKlijenti: KlijentiService
  ) { }

  objKlijent: Podaci
  objPosNaplUred: VposNaplUred[];
  objPosNpOperater: PosNpOperater[];

  arrSample: number[] = [1, 2, 3];

  ngOnInit(): void {

    //osnovni podaci
    this.objKlijent = JSON.parse(localStorage.getItem('objKlijent'))

    //Poslovni prostori i naplatni uređaji
    this.objPosNaplUred = JSON.parse(localStorage.getItem('vPosNaplUred'));

    //Operateri po pos. prostorima, napl. uređ. - by Klijent ID (podaciID)
    this.ucitajOperatere();

  }

  //Operateri po pos. prostorima, napl. uređ. - by Klijent ID (podaciID)
  ucitajOperatere() {
    this.srvKlijenti.operateri(this.objKlijent.PodaciID, (rez: PosNpOperater[]) => {
      this.objPosNpOperater = rez;
      console.log(this.objKlijent.PodaciID);
      console.log(this.objPosNpOperater);
    });
  }

}

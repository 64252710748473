<div class="container">

    <div class="row">
        <div class="col-12 alert alert-info">
            <p>{{Naziv}}</p>
        </div>
    </div>


    <div class="row">

        <!--Klijenti-->
        <div class="col gumb text-center" *ngIf="accAdmin" routerLink="/klijenti">
            <i class="material-icons">assignment_ind</i>
            <h6>Klijenti</h6>
        </div>

        <!-- Izdavanje računa -->
        <div class="col gumb text-center" (click)="utvoriUrl('klijenti-ir','klijenti-ir')">
            <i class="material-icons" style="color: #13f913;">description</i>
            <h6>Izdavanje dokumenata</h6>
        </div>

        <!-- Pregled prometa -->
        <div class="col gumb text-center" (click)="utvoriUrl('klijenti-promet','klijenti-promet')">
            <i class="material-icons" style="color: #13f913;">compare_arrows</i>
            <h6>Pregled prometa</h6>
        </div>

        <!-- Pregled Računa-->
        <div class="col gumb text-center" (click)="utvoriUrl('racuni','racuni')">
            <i class="material-icons">chrome_reader_mode</i>
            <h6>Pregled računa</h6>
        </div>

        <!--Plaćanja-->
        <div class="col gumb text-center" (click)="utvoriUrl('placanja','placanja')">
            <i class="material-icons"> attach_money</i>
            <h6>Plaćanja</h6>
        </div>

        <!--Salda Konti-->
        <div class="col gumb text-center" (click)="utvoriUrl('saldakonti','saldakonti')">
            <i class="material-icons">supervised_user_circle</i>
            <h6>Kupci / Dobavljači</h6>
        </div>

        <!--Primitci izdaci-->
        <div class="col gumb text-center" (click)="utvoriUrl('kpi','kpi')">
            <i class="material-icons">supervised_user_circle</i>
            <h6>KPI</h6>
        </div>

        <!-- Postavke -->
        <div class="col gumb text-center" (click)="utvoriUrl('postavke','postavke')">
            <i class="material-icons">settings_applications</i>
            <h6>Postavke</h6>
        </div>


    </div>

    <!-- <form class="example-form">
        <mat-form-field class="example-full-width">
            <input type="text" [(ngModel)]="strNaselje" (ngModelChange)="filtrirajNaselja(strNaselje)" name="sNaselje"
                placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let x of fltNaselja" [value]="x.IDnas">
                    {{x.Naselje}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form> -->

    <!-- <form class="example-form">
        <mat-form-field class="example-full-width">
            <input matInput name="strNaselje" type="text" [(ngModel)]="strNaselje" (ngModelChange)="filtrirajNaselja($event)"
                 placeholder="Assignee" aria-label="Assignee" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" name="matAutocomplete" >
                <mat-option name="opt" *ngFor="let x of fltNaselja" [value]="x.Naselje">
                    {{x.Naselje}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <span style="color:rgb(253, 244, 238)"></span>
    </form> -->

</div>
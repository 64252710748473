import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from '../app.component';
import { RacuniService } from '../racuni.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';

@Component({
  selector: 'app-saldakonti',
  templateUrl: './saldakonti.component.html',
  styleUrls: ['./saldakonti.component.css']
})
export class SaldakontiComponent implements OnInit {

  constructor(public appComp: AppComponent, public racuniService: RacuniService, private _bottomSheet: MatBottomSheet) { }

  @ViewChild('mainCont') el: ElementRef;
  @ViewChild('content') content: ElementRef;

  ngOnInit() {

    this.appComp.sidenav.open();
    this.appComp.ucitajPartnere();

  }

  exportPdf() {
    let doc = new jsPDF()

    doc.text('Hello world!', 10, 10)
    doc.save('a4.pdf')
  }

  download() {
    let doc = new jsPDF();
    
    
    doc.fromHTML(document.getElementById("content"),10,10,{}, function() {
      doc.save("obrz.pdf");
    },2);
    /* doc.addHTML(document.getElementById("content"), function () {
      doc.save("obrz.pdf");
    }); */
  }

  downloadPdf() {
    let doc = new jsPDF();
    doc.addHTML(document.getElementById("mainCont").innerHTML, function () {
      doc.save("obrz.pdf");
    });
  }


  ispis() {
    let mm = document.getElementById("content").innerText;
    window.print();
  }



}

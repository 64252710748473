import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { Artikl } from './klijenti-ir.service';

export interface Mjesta {
  Mjesto_ID: number,
  Mjesto: string,
  Broj_poste: number,
  Naziv_PU: string,
  Zupanija: string
}

export interface Podaci { //Klijent
  PodaciID: number,
  Naziv: string,
  R_oznaka: string,
  Djelatnost: string,
  Vl: string,
  Adresa: string,
  Mjesto: string,
  Br_poste: string,
  Drzava: string,
  MB: string,
  Dno_racuna: string,
  Tekst_na_racunu: string,
  Dno_racuna2: string,
  OIB: string,
  Tel: string,
  Fax: string,
  Mob: string,
  e_mail: string,
  web: string,
  JJIB: string,
  Porezni_Obveznik_Da_NE_int: number,
  Sifra_djelatnosti: string,
  Napomena_IR: string,
  Kucni_br: number,
  Dodatak_kcbr: string
}

export interface PoslovniProstor {
  poslID: number,
  posKlijentID: number,
  posAdresa: string,
  posBrojPoste: number,
  posMjesto: string,
  posOzn: string;
  posOznSl: string,
  radVrijeme: string
}

export interface NaplUredjaj {
  npuID: number,
  npVrstaDok: string,
  npuPoslID: number,
  npuNaplUr: string,
  npuAkt: boolean,
  npOpis: string,
  npNplacanja: string,
  npFiskal: string,
  intAkt: number,
  polog: number
}

export interface Operater {
  korID: number,
  operIme: string,
  operPrezime: string,
  operOIB: string
}

export interface NpOperater extends NaplUredjaj {
  operateri: Operater[]
}

export interface PosNpOperater extends PoslovniProstor {
  NpOper: NpOperater[]
}

export interface VposNaplUred extends PoslovniProstor {
  np: NaplUredjaj[]
}



@Injectable({
  providedIn: 'root'
})
export class KlijentiService {

  constructor(
    private http: HttpClient, public appService: AppService
  ) { }

  //Odabir klijenta (iz tg_cmd) i spremanje u local storage
  public podaciByID(PodaciIDinf: number, callback) {

    let url: string = this.appService.webUrl + "php/klijenti-podaci.php?funk=podaciByID";
    let obj: string = JSON.stringify({ podaciID: PodaciIDinf });

    this.http.post(url, obj).subscribe((rez: Podaci) => {

      localStorage.setItem('objKlijent', JSON.stringify(rez));

      this.appService.objPodaciSel = rez;
      localStorage.setItem('objPodaciSel', JSON.stringify(this.appService.objPodaciSel));

      callback(rez);

    });

  }

  //Učitavanje poslovnih prostora i naplatnih uređaja
  ucitajPoslNapl(posKlijentID: number, callback) {

    let url: string = this.appService.webUrl + "php/klijenti-podaci.php?funk=ucitajPoslNapl";
    let obj: string = JSON.stringify({ posKlijentID: posKlijentID });

    console.log(obj);

    this.http.post(url, obj).subscribe((rez: VposNaplUred[]) => {

      console.log(rez);

      if (!rez['err']) {

        localStorage.setItem('vPosNaplUred', JSON.stringify(rez))

      } else {
        console.log(rez['err']);
      }

    });

  }

  //Učitavanje artikala by klijentID
  artikliByKlijentId(klID: number, callback) {

    let url: string = this.appService.webUrl + "php/klijenti-artikli.php?funk=artikliByKlijentId";
    let obj: string = JSON.stringify({ artKlijentID: klID });

    this.http.post(url, obj).subscribe((rez: Artikl[]) => {

      /* if (!rez['err']) {

        console.log(rez);

      } else {
        console.log(rez['err']);
      } */

      callback(rez);

    });

  }

  //Artikl by ArtiklID
  artiklById(artID: number, callback) {

    let url: string = this.appService.webUrl + "php/klijenti-artikli.php?funk=artiklById";
    let obj: string = JSON.stringify({ artID: artID });

    this.http.post(url, obj).subscribe((rez: Artikl) => {
      callback(rez);
    });

  }

  //Operateri po posl. prostorima, naplatnim uređajima - by Klijent ID (PodaciID)
  operateri(podaciID: number, callback) {

    let url: string = this.appService.webUrl + "php/klijenti-podaci.php?funk=operateri";
    let obj: string = JSON.stringify({ podaciID: podaciID });

    this.http.post(url, obj).subscribe((rez: PosNpOperater[]) => {
      callback(rez);
    });

  }

}

import { trigger, state, style, animate, transition, sequence, query, stagger } from '@angular/animations';

//msg_anim
export const NavMsg = trigger('navMsg', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)' }), animate('400ms ease-out', style({ transform: 'translateY(0)' }))]
  ),
  transition(':leave', [
    style({ transform: 'translateY(0)' }), animate('300ms ease-in', style({ transform: 'translateY(-100%)' }))]
  )
]);

//Array splice
export const ArrDropDown = trigger('arrDropDown', [

  transition(':enter', [
    style({ transform: 'translateX(-100%)' }), animate('400ms ease-out', style({ transform: 'translateX(0)' }))]
  )/* ,
  transition(':leave', [
    style({ transform: 'translateX(0)' }), animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))]
  ) */

]);

//Array leave - Up
export const ArrLeaveUp = trigger('arrLeaveUp', [

  transition(':leave', [
    style({ transform: 'translateY(0)' }), animate('1000ms ease-in', style({ transform: 'translateY(-100%)' }))]
  )

]);

//Drop Down 
export const DropDownAnimation = trigger("dropDownMenu", [
  transition(":enter", [
    style({ height: 0, overflow: "hidden" }),
    query(".menu-item", [
      style({ opacity: 0, transform: "translateY(-50px)" })
    ]),
    sequence([
      animate("200ms", style({ height: "*" })),
      query(".menu-item", [
        stagger(-50, [
          animate("400ms ease", style({ opacity: 1, transform: "none" }))
        ])
      ])
    ])
  ]),

  transition(":leave", [
    style({ height: "*", overflow: "hidden" }),
    query(".menu-item", [style({ opacity: 1, transform: "none" })]),
    sequence([
      query(".menu-item", [
        stagger(50, [
          animate(
            "400ms ease",
            style({ opacity: 0, transform: "translateY(-50px)" })
          )
        ])
      ]),
      animate("200ms", style({ height: 0 }))
    ])
  ])
]);
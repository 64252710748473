import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AppService } from './app.service';
import { Partner } from './klijenti-partneri.service';
import { Mjesta } from './klijenti.service';

//Račun
export interface RacunIr {
  rnID: number,
  rnKlijentID: number,
  rnNaplUrID: number,
  rnKupacID: number,
  rnBroj: number;
  rnDatum: moment.Moment,
  rnDatumVal: moment.Moment,
  rnDatumDVO: moment.Moment,
  rnPlacanjeID: number,
  rnOperaterID: number,
  rnZKI: string,
  rnJIR: string,
  rnNakDost: boolean
}

//vRačun (partneri, mjesta)
export interface VracunIR extends RacunIr, Partner, Mjesta {
  neoporezivo: number;
  pdv: PorezIR[];
  stavke: VracunStavka[];
  napomene: RacunNapomena[];
}

//Porez
export interface PorezIR {
  povNakIznos: number
  stopa: number;
  osnovica: number;
  iznos: number,
  ukupno: number;
}

//Napomena
export interface RacunNapomena {
  rnnID: number,
  rnIDnap: number,
  rnnNapomena: string
}

//Napomena predložak
export interface RnNapomenaPred {
  rpID: number,
  rpKlijentID: number,
  redBr: number,
  rpTekst: string
}

//Račun stavka
export interface RacunStavka {
  rsID: number,
  rsRnID: number,
  rsArtiklID: number,
  kol: number,
  cijena: number,
  iznos: number,
  rabat: number,
  rabatIznos: number,
  povNak: number,
  povNakIznos: number,
  osnovica: number,
  pdvStopa: number,
  pdvIznos: number,
  ukupno: number
}

//vRačunStavka (artikli)
export interface VracunStavka extends RacunStavka, Artikl {

}

export interface RnIznosi {
  iznos: number,
  rabatIznos: number,
  povNakIznos: number,
  osnovica: number,
  pdvIznos: number,
  ukupno: number
}

export interface Artikl {
  artID: number,
  artKlijentID: number,
  artVrsta: number,
  artNaziv: string,
  jedMj: string,
  artPdvStopa: number,
  artCijena: number,
  artPovNak: number
}

export interface FrmFilterPromet {
  npID: number,
  vrstaID: number,
  datOd: string,
  datDo: string
}

export interface DnevniPromet {
  rnID: number,
  rnBroj: number,
  rnDatum: moment.Moment,
  rnPlacanjeID: number,
  iznos: number,
  rabatIznos: number,
  povNakIznos: number,
  osnovica: number,
  pdvIznos: number,
  ukupno: number,
  naziv: string
}

@Injectable({
  providedIn: 'root'
})
export class KlijentiIrService {

  constructor(
    public appService: AppService,
    private http: HttpClient
  ) { }

  //Partneri (odabranog klijenta)
  /* ucitajPartnere(callback) {

    let url: string = this.appService.webUrl + "php/klijenti_ir.php";
    let obj: string;

    this.http.post(url, obj).subscribe((rez: Partner[]) => {
      callback(rez);
    });

  } */

  objPredlosci: RnNapomenaPred[];

  //Učitavanje godina za izlazne račune (u kojimaima računa i trenutna godina)
  godineIR(parKlijentID, callback) {

    let url: string = this.appService.webUrl + "php/klijenti-podaci.php?funk=godineIR";
    let obj: string = JSON.stringify({ parKlijentID: parKlijentID });

    this.http.post(url, obj).subscribe((rez: number[]) => {
      callback(rez);
    });

  }

  //Iznosi na računu (sum)
  iznosiNaRn(rnStavke: RacunStavka[]): RnIznosi {

    let iznTmp: RnIznosi = {
      iznos: 0,
      rabatIznos: 0,
      povNakIznos: 0,
      osnovica: 0,
      pdvIznos: 0,
      ukupno: 0
    };

    if (rnStavke) {
      rnStavke.forEach(val => {
        iznTmp.iznos += Number(val.iznos);
        iznTmp.rabatIznos += Number(val.rabatIznos);
        iznTmp.povNakIznos += Number(val.povNakIznos);
        iznTmp.osnovica += Number(val.osnovica);
        iznTmp.pdvIznos += Number(val.pdvIznos);
        iznTmp.ukupno += Number(val.ukupno);
      });
    }

    return iznTmp;

  }

  //Rekapitulacija PDV-a by rnID
  pdvIznosiByRnID(rnID: number, callback) {

    let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=pdvIznosiByRnID";
    let obj: string = JSON.stringify({ rnID: rnID });

    this.http.post(url, obj).subscribe((rez: PorezIR[]) => {
      callback(rez);
    });


  }

  //Stavke by Račun ID
  stavkeByRnId(rsRnID: number, callback) {

    let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=stavkeByRnId";
    let obj: string = JSON.stringify({ rsRnID: rsRnID });

    this.http.post(url, obj).subscribe((rez: VracunStavka[]) => {

      if (rez && !rez['err']) {

        //this.appService.msgBox(1, "valja", 3000);

      } else {

        //this.appService.msgBox(2, "Greška prilikom učitavanja", 3000);

      }

      callback(rez);

    });

  }

  public objArtiklSub = new Subject<any>();
  public objArtiklSub$ = this.objArtiklSub.asObservable();

  public objArtStav = new Subject<any>();
  public objArtStav$ = this.objArtStav.asObservable();

  public objPartner = new Subject<any>();
  public objPartner$ = this.objPartner.asObservable();

  public objIrNapomene = new Subject<RacunNapomena[]>();
  public objIrNapomene$ = this.objIrNapomene.asObservable();

  //Spremi Artikl
  spremiArtikl(art: Artikl, callback) {

    let url: string = this.appService.webUrl + "php/klijenti-artikli.php?funk=spremiArtikl";
    let obj: string = JSON.stringify(art);

    this.http.post(url, obj).subscribe((rez: Artikl) => {

      if (rez) {
        this.objArtiklSub.next(rez);
      }
      callback(rez);
    });

  }

  //Filtriranje artikala
  filterArtikli(obj: Artikl[], strP: string) {

    let output = [];

    if (strP.length > 0) {

      obj.forEach(val => {

        if (val.artNaziv.toLowerCase().indexOf(strP.toLowerCase()) >= 0) {
          output.push(val);
        }

      });

      return output;

    } else {
      return null;
    }


  }

  //PDF klijenti IR
  racuniIrPdf(objRnSel: VracunIR, callback) {

    let url: string = this.appService.webUrl + "php/pdf_klijenti_ir.php";
    let obj: string = JSON.stringify(objRnSel);

    console.log(objRnSel);

    let frm = document.createElement("form");
    frm.target = "_blank";
    frm.method = "POST"; // or "post" if appropriate
    frm.action = url;
    //frm.append(obj);

    let hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", "forma");
    hiddenField.setAttribute("value", obj);

    frm.appendChild(hiddenField);

    document.body.appendChild(frm);

    frm.submit();

  }

  //Račun napomene
  racunNapomene(rnID: number, callback) {

    let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=racunNapomene";
    let obj: string = JSON.stringify({ rnID: rnID });

    this.http.post(url, obj).subscribe((rez: RacunNapomena[]) => {
      callback(rez);
    });

  }

  //Spremi napomenu
  spremiNapomenu(val: RacunNapomena, pred: number, callback) {

    let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=spremiNapomenu";
    val['pred'] = pred;
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }

  //Obriši napomenu
  obrisiNapomenu(val: RacunNapomena, callback) {

    let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=obrisiNapomenu";
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez) => {
      callback(rez);
    });

  }

  //Napomene predlošci
  napomenePred(podaciID: number, callback) {

    let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=napomenePred";
    let obj: string = JSON.stringify({ podaciID: podaciID });

    this.http.post(url, obj).subscribe((rez: RnNapomenaPred[]) => {
      callback(rez);
    });

  }

  //Spremi predloške
  spremiPredloske(objPred: RnNapomenaPred[], callback) {

    let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=spremiPredloske";
    let obj: string = JSON.stringify(objPred);

    this.http.post(url, obj).subscribe((rez: RnNapomenaPred[]) => {
      callback(rez);
    });

  }

  //Potvrdi odabir predložaka za dodati na račun
  potvrdiOdabir(objPred: RnNapomenaPred[], rnID: number, callback) {
    let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=potvrdiOdabir";

    let obj0 = {
      rnID: rnID,
      objPred: objPred
    }

    let obj: string = JSON.stringify(obj0);

    this.http.post(url, obj).subscribe((rez: RnNapomenaPred[]) => {
      callback(rez);
    });
  }

  //Update zki jir by rnID
  updateZkiJir(rnID: number, zki: string, jir: string, callback) {

    let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=updateZkiJir";
    let obj: string = JSON.stringify({ rnID: rnID, zki: zki, jir: jir });

    this.http.post(url, obj).subscribe(rez => {
      callback(rez);
    });

  }

  //Pregled prometa
  pregledPrometa(obj0: FrmFilterPromet, callback) {

    let url: string;

    obj0.datOd = moment(obj0.datOd).format('YYYY-MM-DD');
    obj0.datDo = moment(obj0.datDo).format('YYYY-MM-DD');

    let obj: string = JSON.stringify(obj0);

    if (obj0.vrstaID == 1) {
      
      //Dnevni promet
      url = this.appService.webUrl + "php/klijenti-promet.php?funk=dnevniPromet";

      this.http.post(url, obj).subscribe((rez: DnevniPromet[]) => {
        callback(rez);
      });

    } else {
      url = this.appService.webUrl + "php/klijenti-promet.php?funk=prometRazdoblje";
    }

  }

   //Polog u kasi - update
   spremiPolog(npID: number, polog: number, callback) {

    let url: string = this.appService.webUrl + "php/klijenti-promet.php?funk=spremiPolog";
    let obj: string = JSON.stringify({ npID: npID, polog: polog });

    this.http.post(url, obj).subscribe((rez: string) => {
      callback(rez);
    });

  }

}

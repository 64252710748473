<div class="container">



    <div *ngIf="objPoslNpSel" class="alert alert-light ne-print" style="padding: 5px;">

        <div class="d-flex flex-wrap">
            <div class="d-flex flex-column align-items-center justify-content-center" style="min-width: 60%;">
                <!-- <div style="margin-right: 5px;">
                    
                </div> -->
                <div>
                    Naplatni uređaj: {{objPoslNpSel.posOzn + '/' + objPoslNpSel.np[0].npuNaplUr + ' (' +
                    objPoslNpSel.np[0].npNplacanja + '-' + (objPoslNpSel.np[0].npNplacanja | placanjeOpisByOzn) + ')'}}
                </div>
                <div>
                    Vrsta dokumenta: {{objPoslNpSel.np[0].npVrstaDok}}
                </div>
            </div>

            <div class="d-flex flex-wrap align-items-center">
                <mat-form-field class="ml-auto" style="width: 100px; margin-right: 20px; text-align: center;">
                    <!-- <mat-label>Godina</mat-label> -->
                    <mat-select name="godSel" [(value)]="godSel" (selectionChange)="godinaSel($event)"
                        [compareWith]="compareObjects">
                        <mat-option *ngFor="let x of objGodine" [value]="x">
                            {{x}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button *ngIf="objPoslNp.length > 1 || (objPoslNp.length == 1 && objPoslNp[0].np.length > 1)"
                    type="button" class="btn btn-outline-info" (click)="ponistiVposNplSel()">Naplatni uređaji</button>

            </div>

        </div>

    </div>


    <div *ngIf="!objPoslNpSel">
        <div class="card card-rn" *ngFor="let x of objPoslNp" style="background-color: #f4ffe3;">

            <div>
                <div>Poslovni prostor:</div>
                <h6>{{x.posAdresa + (x.posMjesto? ', ' + x.posMjesto : '') + ' (' + x.posOzn + ')'}}</h6>
            </div>

            <div *ngIf="x.np">Naplatni uređaji:</div>
            <div style="color: firebrick;" *ngIf="!x.np">NEMA NAPLATNIH UREĐAJA</div>

            <div class="card card-rn" style="background-color: #f6ffba; cursor: pointer;" *ngFor="let y of x.np">
                <div (click)="postaviVposNaplUred(x, y.npuID)">
                    <div class="d-flex flex-wrap">
                        <div style="margin-right: 5px;">Broj naplatnog uređaja:</div>
                        <div style="font-weight: bold;">{{y.npuNaplUr}}</div>

                    </div>
                    <div class="d-felx flex-wrap">
                        <div style="font-size: 0.9em;">
                            Preferirano plaćanje:
                        </div>
                        <div style="color: #012463; font-weight: bold;">
                            {{y.npNplacanja | placanjeOpisByOzn}}
                        </div>
                    </div>
                    <div class="d-felx flex-wrap">
                        <div style="font-size: 0.9em;">
                            Vrsta dokumenta:
                        </div>
                        <div style="color: #012463; font-weight: bold;">
                            {{y.npVrstaDok}}
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <div *ngIf="objPoslNpSel" class="ne-print">

        <div class="row" style="margin: 0;">

            <div class="col-md-4" style="padding: 5px;">
                <button id="btnNoviRn" type="button" class="btn btn-success" style="width: 100%;" (click)="noviRacun()">
                    <fa-icon [icon]="srvFa.faFileSignature"></fa-icon> NOVI DOKUMENT (F5)
                </button>
            </div>
            <div class="col-md-4" style="padding: 5px;">
                <button id="btnPartneri" type="button" class="btn btn-info" style="width: 100%;"
                    routerLink="/klijenti-partneri">
                    <fa-icon [icon]="srvFa.faFileSignature"></fa-icon> ŠIFARNIK PARTNERA
                </button>
            </div>
            <div class="col-md-4" style="padding: 5px;">
                <button id="btnArtikli" type="button" class="btn btn-secondary" style="width: 100%;"
                    routerLink="/klijenti-artikli-sifarnik">
                    <fa-icon [icon]="srvFa.faFileSignature"></fa-icon> ŠIFARNIK ARTIKALA
                </button>
            </div>
        </div>



        <div id="racuniList">

            <div *ngFor="let x of objVracuni; let i=index; let isLast=last">

                <div class="card card-rn" [attr.id]="'rn' + x.rnID"
                    [ngStyle]="{'background-color': objRnSel && objRnSel.rnID == x.rnID ? '#ffeebd' : '#ffffff'}">

                    <div class="row" style="margin: 0; cursor:pointer;" (click)="odaberiRacun(x);"
                        [attr.aria-controls]="'racuniList' + x.rnID">

                        <div class="col-8" style="padding: 0;">

                            <div style="font-weight: bold; color: darkseagreen;">
                                Broj: {{x.rnBroj}}
                            </div>

                            <div style="font-weight: bold;">
                                {{x.naziv}}
                            </div>

                            <div>
                                Datum: {{x.rnDatum | amDateFormat: 'DD.MM.YYYY.'}}
                            </div>

                            <div style="font-weight: bold;">
                                Iznos: {{srvKlijentiIR.iznosiNaRn(x.stavke).ukupno | number:'1.2-2':'en-HR'}}
                            </div>

                        </div>

                        <div class="col-4" style="padding: 0;">

                            <div class="d-flex justify-content-end align-items-center" style="height: 100%;">

                                <div *ngIf="objPoslNpSel.np[0].npFiskal == '1' && !x.rnJIR">
                                    <div class="alert alert-danger"
                                        style="text-align: center; margin: 0; padding: 5px; background-color: transparent; border: none;">
                                        Račun nije fiskaliziran</div>
                                </div>

                                <i *ngIf="x.rnID != objRnSel?.rnID" class="material-icons"
                                    style="font-size: 5em; color: #dad1d0de;">expand_more</i>
                                <i *ngIf="x.rnID == objRnSel?.rnID" class="material-icons"
                                    style="font-size: 5em; color: #dad1d0de; transform: rotate(90deg)">expand_more</i>
                                <!--  <i class="material-icons" style="font-size: 5em; color: #dad1d0de; ">arrow_back_ios</i> -->

                            </div>

                        </div>


                    </div>

                    <div [id]="'detalji' + x.rnID" class="collapse" [ngClass]="{'show': x.rnID == objRnSel?.rnID}"
                        [attr.aria-controls]="'detalji' + x.rnID" [attr.aria-labelledby]="'detalji' + x.rnID"
                        data-parent="#racuniList">

                        <div class="row" style="margin: 0;">
                            <div class="col">
                                <div>
                                    Datum isporuke: {{x.rnDatumDVO | amDateFormat: 'DD.MM.YYYY.'}}
                                </div>

                                <div>
                                    Datum valute: {{x.rnDatumVal | amDateFormat: 'DD.MM.YYYY.'}}
                                </div>
                            </div>
                        </div>


                        <div *ngIf="x.pdv" class="row"
                            style="margin: 10px 0px 2px; border-bottom: solid grey 0.5px; background-color: #f1f0d2;">
                            <div class="col" style="text-align: center;">
                                PDV %
                            </div>
                            <div class="col" style="text-align: right;">
                                Osnovica
                            </div>
                            <div class="col" style="text-align: right;">
                                PDV iznos
                            </div>
                            <div class="col" style="text-align: right;">
                                Ukupno:
                            </div>
                        </div>

                        <div *ngFor="let p of x.pdv" class="row"
                            style="margin: 0px 0px; border-bottom: solid grey 0.5px;">
                            <div class="col" style="text-align: center;">
                                {{p.stopa | number:'1.2-2':'en-HR'}}
                            </div>
                            <div class="col" style="text-align: right;">
                                {{p.osnovica | number:'1.2-2':'en-HR'}}
                            </div>
                            <div class="col" style="text-align: right;">
                                {{p.iznos | number:'1.2-2':'en-HR'}}
                            </div>
                            <div class="col" style="text-align: right;">
                                {{p.ukupno | number:'1.2-2':'en-HR'}}
                            </div>
                        </div>

                        <div *ngIf="x.stavke" class="row"
                            style="margin: 0px 0px; border-bottom: solid grey 0.5px; font-weight: bold;">
                            <div class="col" style="text-align: center;">
                                Ukupno:
                            </div>
                            <div class="col" style="text-align: right;">
                                {{srvKlijentiIR.iznosiNaRn(x.stavke).osnovica | number:'1.2-2':'en-HR'}}
                            </div>
                            <div class="col" style="text-align: right;">
                                {{srvKlijentiIR.iznosiNaRn(x.stavke).pdvIznos | number:'1.2-2':'en-HR'}}
                            </div>
                            <div *ngIf="x.stavke" class="col" style="text-align: right;">
                                {{srvKlijentiIR.iznosiNaRn(x.stavke).ukupno | number:'1.2-2':'en-HR'}}
                            </div>
                        </div>

                        <div *ngIf="!x.stavke" class="alert alert-success" style="text-align: center; margin-top: 5px;">
                            nema stavki na računu
                        </div>

                        <div class="row" style="margin: 10px 0;">

                            <div class="col d-flex justify-content-around" style="padding: 0;">
                                <button *ngIf="objRnSel && !objRnSel.rnJIR" type="button" class="btn btn-info btn-rn"
                                    style="width: 50%; margin-right: 5px;" (click)="otvoriStavke(x)">
                                    <fa-icon [icon]="srvFa.faFileAlt"></fa-icon> Stavke
                                </button>
                                <button type="button" class="btn btn-secondary" style="width: 50%; margin-right: 5px;"
                                    (click)="otvoriUredjivanje()">
                                    <fa-icon [icon]="srvFa.faEditReg"></fa-icon> Zaglavlje
                                </button>
                                <button type="button" class="btn btn-secondary" style="width: 50%;" (click)="ispis()">
                                    <fa-icon [icon]="srvFa.faPrint"></fa-icon> Ispis
                                </button>

                            </div>

                        </div>

                        <div style="background-color: #d8e7e6;">

                            <div style="padding: 5px; color: #2c38d5;">
                                Napomene:
                            </div>

                            <div *ngIf="frmNapomena" style="padding: 5px;">
                                <form [formGroup]="frmNapomena">

                                    <mat-form-field style="width: 100%;">
                                        <mat-label>napomena</mat-label>
                                        <textarea autocomplete="off" type="text" aria-label="rnnNapomena" matInput
                                            formControlName="rnnNapomena"></textarea>
                                        <mat-error *ngIf="frmNapomena.get('rnnNapomena').errors?.required">Polje je
                                            obavezno za unos</mat-error>
                                        <mat-error *ngIf="frmNapomena.get('rnnNapomena').errors?.err">
                                            {{frmNapomena.get('rnnNapomena').errors.err}}</mat-error>
                                    </mat-form-field>

                                    <div class="row">
                                        <div class="col col-4" style="padding: 2px;">
                                            <button class="btn btn-success w-100 h-100" (click)="spremiNapomenu(0)">
                                                <fa-icon [icon]="srvFa.faSave"></fa-icon> SPREMI
                                            </button>
                                        </div>
                                        <div class="col col-4" style="padding: 2px;">
                                            <button class="btn btn-success w-100 h-100" (click)="spremiNapomenu(1)">
                                                <fa-icon [icon]="srvFa.faSave"></fa-icon> SPREMI + DODAJ U PREDLOŠKE
                                            </button>
                                        </div>
                                        <div class="col col-4" style="padding: 2px;">
                                            <button class="btn btn-secondary w-100 h-100" (click)="otkaziNapomenu()">
                                                <fa-icon [icon]="srvFa.faTimes"></fa-icon> OTKAŽI UNOS
                                            </button>
                                        </div>

                                    </div>

                                </form>
                            </div>

                            <div [attr.hidden]="frmNapomena">
                                <!--*ngIf="!frmNapomena"-->


                                <div *ngFor="let y2 of x.napomene" style="overflow: hidden;">

                                    <div class="row" style="margin: 2px 0;">

                                        <div class="col d-flex flex-wrap align-items-center"
                                            style="white-space: pre-line; padding: 5px;" [@arrDropDown]="y2.add">
                                            <div>
                                                {{y2.rnnNapomena}}
                                            </div>

                                        </div>
                                        <div class="col col-2"
                                            style="text-align: center; padding: 2px; cursor: pointer;"
                                            (click)="urediNapomenu(y2)">
                                            <fa-icon style="font-size: 1.3rem;" [icon]="srvFa.faPencilAlt"></fa-icon>
                                            Uredi
                                        </div>
                                        <div class="col col-2" (click)="obrisiNapomenu(y2)"
                                            style="text-align: center; padding: 2px; cursor: pointer; color: #a02b00;">
                                            <fa-icon style="font-size: 1.3rem;" [icon]="srvFa.faTimes"></fa-icon> Obriši
                                        </div>

                                    </div>

                                </div>

                                <div *ngIf="!x.napomene || !x.napomene.length" class="alert alert-warning"
                                    style="text-align: center;">
                                    nema napomena na računu
                                </div>


                                <!-- <div class="d-flex justify-content-between">
                                <button class="btn btn-secondary" style="width: 49%;" (click)="novaNapomena()">
                                    <fa-icon [icon]="srvFa.faFile"></fa-icon> Nova napomena
                                </button>
                                <button class="btn btn-secondary" style="width: 49%;" (click)="napomenaPredStart()">
                                    <fa-icon [icon]="srvFa.faFileAlt"></fa-icon> Nova napomena iz predloška
                                </button>
                            </div> -->

                                <div class="row">
                                    <div class="col-sm-6" style="padding: 2px;">
                                        <button class="btn btn-secondary w-100" (click)="novaNapomena()">
                                            <fa-icon [icon]="srvFa.faFile"></fa-icon> Nova napomena
                                        </button>
                                    </div>
                                    <div class="col-sm-6" style="padding: 2px;">
                                        <button class="btn btn-secondary w-100" (click)="napomenaPredStart()">
                                            <fa-icon [icon]="srvFa.faFileAlt"></fa-icon> Nova napomena iz predloška
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div *ngIf="intFiskalStart == x.rnID" class="row" style="padding: 5px;">

                            <div class="col col-12">
                                Fiskalizacija
                            </div>

                            <div class="col col-6" style="padding: 5px;">
                                <button type="button" class="btn btn-success w-100" (click)="fiskalizirajRacun()">Fiskaliziraj</button>
                            </div>

                            <div class="col col-6" style="padding: 5px;">
                                <button type="button" class="btn btn-secondary w-100" (click)="intFiskalStart = 0">Otkaži</button>
                            </div>

                        </div>

                        <div *ngIf="objPoslNpSel.np[0].npFiskal == '1' && objRnSel && !objRnSel.rnJIR && objRnSel.stavke?.length > 0 && !intFiskalStart"
                            style="padding: 3px;">
                            <button type="button" class="btn btn-success w-100"
                                style="color: black; background-color: #75f392;"
                                (click)="intFiskalStart = x.rnID">Fiskaliziraj račun</button>
                        </div>

                        <div *ngIf="objPoslNpSel.np[0].npFiskal == '1' && objRnSel && objRnSel.rnJIR">
                            <div class="alert alert-success" style="text-align: center; margin: 3px">Račun je fiskaliziran</div>
                        </div>

                    </div>



                    <!-- <div *ngIf="i == x.length -1">{{scrollInto()}}</div> -->
                </div>
                <div *ngIf="isLast">{{scrollInto()}}</div>
            </div>
        </div>
    </div>

    <div id="content" *ngIf="objRnSel" class="da-print" style="display: none; margin-top: 25px;" #etPrint>

        <!--BANNER - samo ispis-->
        <div class="row" style="width: auto; border-top: solid 0.5px; border-bottom: solid 0.5px; margin: 0;">
            <hr />
            <img src="assets/baneri/{{51}}.jpg" class="img-fluid">
            <hr />
        </div>

        <div style="margin-top: 50px;">
            <div class="row" style="margin: 0;">

                <div class="col col-6" style="padding: 0;">

                    <div style="font-size: 0.8rem;">
                        Račun broj:
                    </div>
                    <div style="font-size: 1.2rem; font-weight: bold;">
                        {{objRnSel.rnBroj + '/' + objPoslNpSel.posOzn + '/' + objPoslNpSel.np[0].npuNaplUr}}
                    </div>
                    <div style="font-size: 0.8rem;">
                        Datum računa:
                    </div>
                    <div style="font-size: 1rem; font-weight: bold;">
                        {{objRnSel.rnDatum | amDateFormat: 'DD.MM.YYYY. HH:mm:ss'}}
                    </div>

                    <div class="d-flex flex-inline">
                        <div>
                            <div style="font-size: 0.8rem; margin-right: 15px;">
                                Datum isporuke:
                            </div>
                            <div style="font-size: 1rem; font-weight: bold;">
                                {{objRnSel.rnDatumDVO | amDateFormat: 'DD.MM.YYYY.'}}
                            </div>
                        </div>

                        <div>
                            <div style="font-size: 0.8rem;">
                                Datum valute:
                            </div>
                            <div style="font-size: 1rem; font-weight: bold;">
                                {{objRnSel.rnDatumVal | amDateFormat: 'DD.MM.YYYY.'}}
                            </div>
                        </div>
                    </div>

                    <div style="font-size: 0.8rem;">
                        Način plaćanja:
                    </div>
                    <div style="font-size: 1rem; font-weight: bold;">
                        {{objRnSel.rnPlacanjeID | placanjeOpisById}}
                    </div>


                </div>

                <div class="col col-6">
                    <div class="card" style="padding: 5px;">
                        <div style="font-weight: bold;">
                            {{objRnSel.naziv + ' ' + objRnSel.djelatnost}}
                        </div>
                        <div *ngIf="objRnSel.vlasnik">
                            {{'Vlasnik: ' + objRnSel.vlasnik}}
                        </div>
                        <div>
                            {{objRnSel.adresa}}
                        </div>
                        <div>
                            {{objRnSel.Broj_poste + ' ' + objRnSel.Mjesto}}
                        </div>
                        <div *ngIf="objRnSel.oib">
                            {{'OIB: ' + objRnSel.oib}}
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div *ngIf="objRnSel" style="margin-top: 30px;">

            <div class="row row-stavka"
                style="border-top: solid 0.5px; border-bottom: solid 0.5px; background-color: #e8e8e8; margin: 0">

                <div class="col col-1 col-stavka txt-mid">
                    Red. br.
                </div>

                <div class="col col-5 col-stavka">
                    Roba / usluga
                </div>

                <div class="col col-1 col-stavka txt-right">
                    kol.
                </div>

                <div class="col col-1 col-stavka txt-right">
                    cijena
                </div>

                <div class="col col-1 col-stavka txt-right">
                    rabat %
                </div>

                <div class="col col-1 col-stavka txt-right">
                    PDV %
                </div>

                <div class="col col-2 col-stavka txt-right">
                    iznos
                </div>

            </div>

            <div class="row row-stavka" *ngFor="let x of objRnSel.stavke; let i = index">

                <div class="col col-1 col-stavka txt-mid">
                    {{(i + 1) + '.'}}
                </div>

                <div class="col col-5 col-stavka" style="white-space: pre-wrap;">
                    {{x.artNaziv}}
                </div>

                <div class="col col-1 col-stavka txt-right">
                    {{x.kol | number:'1.2-2':'en-hr'}}
                </div>

                <div class="col col-1 col-stavka txt-right">
                    {{x.cijena | number:'1.2-2':'en-hr'}}
                </div>

                <div class="col col-1 col-stavka txt-right">
                    {{x.rabat | number:'1.2-2':'en-hr'}}
                </div>

                <div class="col col-1 col-stavka txt-right">
                    {{x.pdvStopa | number:'1.2-2':'en-hr'}}
                </div>

                <div class="col col-2 col-stavka txt-right">
                    {{x.iznos | number:'1.2-2':'en-hr'}}
                </div>

            </div>

            <div class="row row-stavka"
                style="border-top: solid 0.5px; border-bottom: solid 0.5px; background-color: #e8e8e8;">

                <div class="col col-1 col-stavka txt-mid">
                    neto:
                </div>

                <div class="col col-5 col-stavka">

                </div>

                <div class="col col-1 col-stavka txt-right">

                </div>

                <div class="col col-1 col-stavka txt-right">

                </div>

                <div class="col col-1 col-stavka txt-right">

                </div>

                <div class="col col-1 col-stavka txt-right">

                </div>

                <div class="col col-2 col-stavka txt-right">
                    iznos
                </div>

            </div>

        </div>

        <div *ngIf="objRnSel" style="margin-top: 40px;">

            <div style="font-size: 0.8rem;">Rekapitulacija PDV-a:</div>

            <div class="row" style="margin: 0;">

                <div class="col col-2 txt-right"
                    style="border-top: solid 0.5px; border-bottom: solid 0.5px; background-color: #e8e8e8;">
                    neoporezivo
                </div>
                <div class="col col-2 txt-right"
                    style="border-top: solid 0.5px; border-bottom: solid 0.5px; background-color: #e8e8e8;">
                    pov. naknada
                </div>
                <div class="col col-1 txt-mid"
                    style="border-top: solid 0.5px; border-bottom: solid 0.5px; background-color: #e8e8e8;">
                    stopa
                </div>
                <div class="col col-2 txt-right"
                    style="border-top: solid 0.5px; border-bottom: solid 0.5px; background-color: #e8e8e8;">
                    osnovica
                </div>
                <div class="col col-2 txt-right"
                    style="border-top: solid 0.5px; border-bottom: solid 0.5px; background-color: #e8e8e8;">
                    iznos PDV-a
                </div>
                <div class="col col-2 txt-right"
                    style="border-top: solid 0.5px; border-bottom: solid 0.5px; background-color: #e8e8e8;">
                    Ukupno
                </div>

            </div>

            <div class="row" style="margin: 0" *ngFor="let x of objRnSel.pdv">
                <div class="col col-2 txt-right">
                    {{(x.stopa == 0 ? x.osnovica : 0) | number:'1.2-2':'en-hr'}}
                </div>
                <div class="col col-2 txt-right">
                    {{x.povNakIznos| number:'1.2-2':'en-hr'}}
                </div>
                <div class="col col-1 txt-mid">
                    {{x.stopa | number:'1.2-2':'en-hr'}}
                </div>
                <div class="col col-2 txt-right">
                    {{(x.stopa != 0 ? x.osnovica : 0) | number:'1.2-2':'en-hr'}}
                </div>
                <div class="col col-2 txt-right">
                    {{x.iznos | number:'1.2-2':'en-hr'}}
                </div>
                <div class="col col-2 txt-right">
                    {{x.ukupno | number:'1.2-2':'en-hr'}}
                </div>
            </div>

        </div>


    </div>

</div>
<div class="container" style="font-size: 1.2rem;">


    <div style="padding-top: 20px;">
        Temeljem članka 11. stavka 2. Zakona o fiskalizaciji u prometu gotovinom (Narodne novine, broj 133/12), {{objKlijent.Naziv + ', ' + objKlijent.Adresa + ', ' + objKlijent.Mjesto + ', OIB: ' + objKlijent.OIB}}, donosi
    </div>

    <h5 style="padding: 20px; text-align: center;">
        ODLUKU
    </h5>
    <h5 style="text-align: center;">
        o pravilima slijednosti numeričkih brojeva računa, o poslovnim prostorima, o oznakama naplatnih uređaja te označavanju operatera na naplatnim uređajima
    </h5>

    <h6 style="text-align: center;">
        I.
    </h6>
    <p>
        Ovom Odlukom uređuje se popis poslovnih prostora s pripadajućim adresama i oznakama, oznakama naplatnih uređaja, oznakama operatora na naplatnim uređajima, radno vrijeme te pravilo slijednosti numeričkih brojeva računa.
    </p>

    <h6 style="text-align: center;">
        II.
    </h6>
    <p>
        Numerički broj računa po svakom naplatnom uređaju u poslovnom prostoru počinje s početkom kalendarske godine, na dan 1. siječnja, od rednog broja 1 do broja „n“ na dan 31. prosinca tekuće godine poštujući pravilo slIjednosti računa.
    </p>

    <h6 style="text-align: center;">
        III.
    </h6>
    <p>
        Popis svih poslovnih prostora sa oznakama, pripadajućim adresama i radno vrijeme, daje se u tablici u nastavku:
    </p>

    <div class="row t-head" style="margin-top: 40px;">

        <div class="col col-2" style="padding: 0;">
            Oznaka
        </div>

        <div class="col col-5" style="padding: 0;">
            Adresa
        </div>

        <div class="col col-5" style="padding: 0;">
            Radno vrijeme
        </div>

    </div>

    <div *ngFor="let x of objPosNaplUred" style="margin-bottom: 20px;">
        <div class="row" *ngIf="x.np">

            <div class="col col-2" style="padding: 0;">
                {{x.posOzn}}
            </div>

            <div class="col col-5" style="padding: 0;">
                {{x.posAdresa + ', ' + x.posMjesto}}
            </div>

            <div class="col col-5" style="padding: 0;">
                {{x.radVrijeme}}
            </div>

        </div>
    </div>


    <h6 style="text-align: center;">
        IV.
    </h6>
    <p>
        S obzirom na to da je na računu obvezno navesti oznaku operatera na naplatnim uređajima, daju se u tablici oznake poslovnog prostora, oznake naplatnih uređaja i oznake operatera po svakom naplatnom uređaju, kako slijedi:
    </p>

    <div *ngFor="let x of objPosNpOperater">
        <div class="row" *ngIf="x.np.length" style="margin-bottom: 30px;">
            <div class="col" style="padding: 0;">
                <div style="display: inline;">
                    Poslovni prostor:
                </div>
                <div style="font-weight: bold; display: inline;">
                    {{x.posOzn}}
                </div>
                <div *ngFor="let y of x.np">
                    <div style="display: inline;">
                        Naplatni uređaj:
                    </div>
                    <div style="font-weight: bold; display: inline;">
                        {{y.npuNaplUr}}
                    </div>
                    <div *ngFor="let z of y.oper">
                        {{'- ' + z.operIme + ' ' + z.operPrezime + ', OIB: ' + z.operOIB}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <h6 style="text-align: center;">
        V.
    </h6>
    <p>
        Računi će se izdavati na način da svaki naplatni uređaj unutar svog poslovnog prostora ima svoj broj računa, stoga će slijed računa biti, kako slijedi:
    </p>

    <div>
        <div *ngFor="let x of objPosNpOperater">
            <div *ngIf="x.np.length" class="row" style="margin-bottom: 30px;">
                <div class="col" style="padding: 0;">
                    <div style="display: inline;">
                        Poslovni prostor:
                    </div>
                    <div style="font-weight: bold; display: inline;">
                        {{x.posOzn}}
                    </div>
                    <div *ngFor="let y of x.np">
                        <div style="display: inline;">
                            Naplatni uređaj
                        </div>
                        <div style="font-weight: bold; display: inline;">
                            {{y.npuNaplUr}}:
                        </div>
                        <div *ngFor="let z of arrSample; let last = last" style="display: inline;">
                            {{z + '/' + x.posOzn + '/' + y.npuNaplUr}} <span *ngIf="!last">, </span><span *ngIf="last">itd.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="margin: 40px 0 120px 0;">
        Ova Odluka stupa na snagu ________________ i primjenjuje se od ________________.
    </div>

    <div class="row">
        <div class="col-6">

        </div>

        <div class="col-6" style="margin-bottom: 40px; border-top: solid 0.5px; text-align: center;">
            Potpis i pečat ovlaštene osobe
        </div>

    </div>



</div>
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { AppService } from '../app.service';
import { Podaci } from '../klijenti.service';

@Component({
  selector: 'app-klijenti',
  templateUrl: './klijenti.component.html',
  styleUrls: ['./klijenti.component.css']
})
export class KlijentiComponent implements OnInit {

  constructor(private http: HttpClient, public appComp: AppComponent, public appSrv: AppService) { }

  objKlijenti;
  fltKlijenti;
  upitK: string;

  ngOnInit() {
    this.dohvatiPodatke();
  }

  dohvatiPodatke() {

    let urlTmp: string = this.appSrv.webUrl + 'php/klijenti.php'

    this.http.get(urlTmp).toPromise().then(rez => {

      this.objKlijenti = rez;
      this.fltKlijenti = rez;

    })

  }

  postaviKlijenta(x: Podaci) {

    localStorage.korID = x.PodaciID;
    this.appComp.korID = x.PodaciID;
    localStorage.nazivP = x.Naziv;
    this.appSrv.strKorisnik = x.Naziv;

    this.appSrv.objPodaciSel = x;
    localStorage.setItem('objPodaciSel', JSON.stringify(x));
    
    let urlTmp: string = this.appSrv.webUrl + "php/";

    this.http.get(urlTmp + 'zr_rn.php?podaciID=' + localStorage.korID).toPromise().then(response => {

      this.appComp.objZrRn = response;
      localStorage.objZrRn = JSON.stringify(response);

    });

  }

  //Filter - pretraga
  filterKlijenti(strP: string) {

    var output = [];

    this.objKlijenti.forEach(objIme => {

      if (objIme.Naziv.toLowerCase().indexOf(strP.toLowerCase()) >= 0) {
        output.push(objIme);
      }

    });

    this.fltKlijenti = output;

  };

}

<div class="container">

    <div *ngIf="!srvKlijentiPartneri.objPartTmp">

        <div style="padding: 10px 0;">
            <mat-form-field style="width: 100%;">
                <mat-label>Pretraga</mat-label>
                <button matPrefix mat-icon-button aria-label="Search">
                    <mat-icon>search</mat-icon>
                </button>
                <input autocomplete="off" type="text" aria-label="strPretaga" matInput [(ngModel)]="strPretaga"
                    (ngModelChange)="filterPartneri()" name="strPretaga" style="text-align: start; font-weight: bold;"
                    #artNaziv>
                <button *ngIf="strPretaga" matSuffix mat-icon-button aria-label="Clear"
                    (click)="strPretaga=''; filterPartneri()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div style="padding: 10px 0;">
            <button class="btn btn-success w-100" (click)="dlgPartner()"><i class="fa fa-user-plus" aria-hidden="true"></i> Unos novog partnera</button>
        </div>

        <div *ngFor="let x of fltPartneri" class="row-partner" (click)="urediPartnera(x)">

            <div class="row">
                <div class="col" style="font-weight: bold; padding: 0;">
                    {{x.naziv}}
                </div>
            </div>
            <div class="row">
                <div class="col" style="padding: 0;">
                    {{x.adresa}}
                </div>
            </div>

        </div>
    </div>

    <div>
        <div *ngIf="fltPartneri && !fltPartneri.length" class="alert alert-success" style="text-align: center;">
            nema stavki za prikaz
        </div>
    </div>

    <div *ngIf="srvKlijentiPartneri.objPartTmp" style="padding: 15px 0;">
        <app-klijenti-partneri-ured #partner></app-klijenti-partneri-ured>
    </div>
    

</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALIDATORS, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppService } from '../app.service';
import { DnevniPromet, KlijentiIrService } from '../klijenti-ir.service';
import { NaplUredjaj, Podaci, VposNaplUred } from '../klijenti.service';
import * as validatori from '../validatori';

interface PosNp {
  npID: number,
  pos: string,
  np: string,
  vrstaDok: string,
  polog: number,
  npNplacanja: string
}

@Component({
  selector: 'app-klijenti-promet',
  templateUrl: './klijenti-promet.component.html',
  styleUrls: ['./klijenti-promet.component.css'],
})
export class KlijentiPrometComponent implements OnInit {

  constructor(
    public appService: AppService,
    public srvKlijentiIr: KlijentiIrService,
    private frmBuilder: FormBuilder
  ) { }

  objKlijent: Podaci;
  objPosNp: PosNp[];
  objPosNpSel: PosNp;
  objDnevniPromet: DnevniPromet[];

  pologUred: number = 0;
  ukupno: number = 0;

  frmFilter: FormGroup;
  frmPolog: FormGroup;

  ngOnInit(): void {

    if (localStorage.getItem('objKlijent')) {
      this.objKlijent = JSON.parse(localStorage.getItem('objKlijent'));
      console.log(this.objKlijent);
    }

    this.sloziPosNp(() => {
      this.napraviFrmFilter(val => {
        this.frmFilter.get('npID').setValue(this.objPosNp[0].npID);
        this.objPosNpSel = this.objPosNp[0];
      });
    });
  }

  //frm Filter
  napraviFrmFilter(callback) {

    this.frmFilter = this.frmBuilder.group({
      npID: [0],
      vrstaID: [1],
      datOd: [moment().format('YYYY-MM-DD')],
      datDo: [moment().format('YYYY-MM-DD')]
    });

    callback(this.frmFilter.value);

  }

  //frmPolog
  napraviFrmPolog() {

    this.frmPolog = this.frmBuilder.group({
      npID: [this.objPosNpSel.npID],
      polog: [this.objPosNpSel.polog, [Validators.required, validatori.decimalCheck]]
    });

  }

  //Lista naplatnih uređaja
  sloziPosNp(callback) {

    if (localStorage.getItem('vPosNaplUred')) {

      this.objPosNp = [];

      let objTmp: VposNaplUred[] = JSON.parse(localStorage.getItem('vPosNaplUred'));

      objTmp.forEach(val => {

        if (val.np) {
          val.np.forEach(val1 => {
            val1.npuID = Number(val1.npuID);
            let rTmp: PosNp = { npID: val1.npuID, pos: val.posOzn, np: val1.npuNaplUr, vrstaDok: val1.npVrstaDok, polog: val1.polog, npNplacanja: val1.npNplacanja };
            this.objPosNp.push(rTmp);
          });
        }

      });

    } else {
      this.appService.msgBox(2, "Greška kod učitavanja naplatnih uređaja!", 2500);
    }

    callback()

  }

  //Postavi pos / np
  postaviPosNp(x: PosNp) {
    this.objDnevniPromet = null;
    this.ukupno = 0;
    this.objPosNpSel = x;
    console.log(this.objPosNpSel);
  }

  //Prikaži promet
  prikazi() {

    this.srvKlijentiIr.pregledPrometa(this.frmFilter.value, (rez: DnevniPromet[]) => {
      this.objDnevniPromet = rez;
      this.zbrojiPromet();
    });

  }

  //Zbroj prometa
  zbrojiPromet() {

    this.ukupno = 0;

    if (this.frmFilter.get('vrstaID').value == 1) {

      this.objDnevniPromet.forEach(val => {
        this.ukupno += Number(val.ukupno);
      });

    }

  }

  //polog uređivanje
  pologUredjivanje() {

    this.pologUred = 1;

    this.napraviFrmPolog();

  }

  //Spremi polog
  spremiPolog() {

    this.frmPolog.markAllAsTouched();

    if (this.frmPolog.valid) {

      let polTmp: string = this.frmPolog.get('polog').value.toString();
      let polTmpN: number = Number(polTmp.replace(",", "."));

      this.srvKlijentiIr.spremiPolog(this.frmPolog.get('npID').value, polTmpN, (rez: string) => {

        console.log(rez);
        console.log(this.objPosNp);

        if (rez == 'ok') {

          this.appService.msgBox(1, "Spremljeno!", 2000);
          this.pologUred = 0;

          this.objPosNpSel.polog = polTmpN;

          //ažuriraj VposNaplUred
          let objPosNpTmp: VposNaplUred[] = JSON.parse(localStorage.getItem('vPosNaplUred'));

          objPosNpTmp.forEach((val: VposNaplUred) => {
            if (val.np) {
              val.np.forEach((val1: NaplUredjaj) => {
                if (val1.npuID == this.objPosNpSel.npID) {
                  val1.polog = polTmpN;
                }
              });
            }
          });

          console.log(objPosNpTmp);
          localStorage.setItem('vPosNaplUred', JSON.stringify(objPosNpTmp));


        } else {
          this.appService.msgBox(2, "Greška prilikom spremanja", 3000);
        }

      })

      console.log(polTmp);

    } else {
      this.appService.msgBox(2, "Popunite ispravno sva polja", 3000);
    }

  }

  //Ispis
  ispis() {
    window.print();
  }

}

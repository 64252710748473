import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService, Mjesto } from '../app.service';
import { KlijentiIrService } from '../klijenti-ir.service';
import { KlijentiPartneriService, Partner, Vpartner } from '../klijenti-partneri.service';
import * as validatori from '../validatori';
import { MustMatch } from '../validatori';

@Component({
  selector: 'app-klijenti-partneri-ured',
  templateUrl: './klijenti-partneri-ured.component.html',
  styleUrls: ['./klijenti-partneri-ured.component.css']
})

export class KlijentiPartneriUredComponent implements OnInit, OnDestroy {

  constructor(
    private http: HttpClient,
    private frmBuilder: FormBuilder,
    public appService: AppService,
    public srvKlPartneri: KlijentiPartneriService,
    private srvKlijentiIr: KlijentiIrService
  ) { }

  frmPartner: FormGroup;

  objMjesta: Mjesto[];
  fltMjesta: Mjesto[];
  objParneriOib: Partner[];

  bolOibDaNe: boolean = true;

  ngOnInit() {

    console.log('kl-part-ured in init');

    //Učitavanje Mjesta
    this.appService.mjestaSelectAll((rez: Mjesto[]) => {

      this.objMjesta = rez;

      // Listener za mjesta
      this.frmPartner.get('Mjesto').valueChanges.subscribe(value => {

        if (value && value.length >= 3) {
          this.filterMjesta(value);
        } else {
          this.fltMjesta = [];
        }
      });

    });

    //napravi frmPartner
    this.napraviFrmPartner(() => {

    });

  }

  //frmPartner
  napraviFrmPartner(callback) {

    this.frmPartner = this.frmBuilder.group({
      oib: ['', [Validators.required, validatori.oibChk]],
      parID: [0],
      parKlijentID: [JSON.parse(localStorage.getItem('korID'))],
      naziv: ['', Validators.required],
      djelatnost: [],
      adresa: [],
      mjestoID: [0],
      vlasnik: [],
      Mjesto: [],
      MjestoChk: []
    }, {
      validator: MustMatch('Mjesto', 'MjestoChk')
    } as AbstractControlOptions);

    callback();

  }

  //Poništavanje mjestoID
  mjestoEmpty() {
    if (!this.frmPartner.get('Mjesto').value) {
      this.frmPartner.get('mjestoID').setValue(0);
      this.frmPartner.get('MjestoChk').setValue('');
    }
  }

  //Filtriranje mjesta
  filterMjesta(strP: string) {

    let output = [];

    if (this.objMjesta) {

      this.objMjesta.forEach((objMj: Vpartner) => {

        if (objMj.Mjesto.toLowerCase().indexOf(strP.toLowerCase()) >= 0) {
          output.push(objMj);
        }

      });

    }

    this.fltMjesta = output;

  }

  //Odabir Mjesta
  postaviMjesto(x: Mjesto) {

    this.frmPartner.get('mjestoID').setValue(x.Mjesto_ID);
    this.frmPartner.get('Mjesto').setValue(x.Mjesto);
    this.frmPartner.get('MjestoChk').setValue(x.Mjesto);

  }

  //Postavke nakon ispravno upisanog oib-a
  oibValid() {

    if (this.frmPartner.get('oib').valid) {

      //partneri by oib
      this.srvKlPartneri.partneriByOib(this.frmPartner.get('oib').value, (rez: Vpartner[]) => {

        if (rez) {

          this.frmPartner.get('mjestoID').setValue(0);

          if (rez.length == 1) {

            this.frmPartner.patchValue(rez[0]);
            this.frmPartner.get('MjestoChk').setValue(rez[0].Mjesto);
            this.objParneriOib = null;

            if (this.frmPartner.get('Mjesto').value && this.frmPartner.get('mjestoID').value == 0) {

              this.nadjiMjesto(this.frmPartner.get('Mjesto').value);

            }

          } else {
            this.objParneriOib = rez;
            /* if (this.objParneriOib.length == 1) {
              this.frmPartner.patchValue(this.objParneriOib[0]);
              this.nadjiMjesto(this.frmPartner.get('Mjesto').value);
              this.objParneriOib = null;
              console.log(rez);
              console.log(this.frmPartner.value);
            } */

          }
        } else {

        }

      });

    }

  }

  //Partner iz tg_cmd ili ...
  partnerFromOstali(x) {

    this.frmPartner.patchValue(x);

    this.nadjiMjesto(this.frmPartner.get('Mjesto').value);

    this.objParneriOib = null;

  }

  //Pretraživanje mjesta i dohvaćanje ID
  nadjiMjesto(naziv: string) {

    let obTmp: Mjesto;

    this.objMjesta.forEach((val) => {
      if (val.Mjesto.toLowerCase() == naziv.toLowerCase()) {
        obTmp = val;
      }
    });

    if (obTmp) {
      this.frmPartner.get('Mjesto').setValue(null);
      this.frmPartner.get('MjestoChk').setValue(null);
      this.frmPartner.get('mjestoID').setValue(0);
      this.frmPartner.get('Mjesto').setValue(obTmp.Mjesto);
      this.frmPartner.get('MjestoChk').setValue(obTmp.Mjesto);
      this.frmPartner.get('mjestoID').setValue(Number(obTmp.Mjesto_ID));
    } else {
      this.frmPartner.get('Mjesto').setValue(null);
      this.frmPartner.get('MjestoChk').setValue(null);
      this.frmPartner.get('mjestoID').setValue(0);
    }

  }

  //Mjesto by ID (popunjavanje naziva u formi)
  nadjiMjestoById(id: number) {

    id = Number(id);
    this.objMjesta.forEach(val => {
      if (val.Mjesto_ID == id) {
        console.log(val);
        this.frmPartner.get('Mjesto').setValue(val.Mjesto);
        this.frmPartner.get('MjestoChk').setValue(val.Mjesto);
        /* this.frmPartner.get('mjestoID').setValue(Number(val.Mjesto_ID)); */
      }
    })

  }


  //Unos bez OIB-a
  oibDaNe() {

    this.bolOibDaNe = this.frmPartner.get('oib').hasValidator(Validators.required);

    if (this.bolOibDaNe) {

      this.frmPartner.get('oib').removeValidators(Validators.required);
      this.frmPartner.get('oib').removeValidators(validatori.oibChk);

    } else {

      this.frmPartner.get('oib').addValidators(Validators.required);
      this.frmPartner.get('oib').addValidators(validatori.oibChk);

    }

    this.frmPartner.get('oib').setValue(this.frmPartner.get('oib').value);


    this.bolOibDaNe = !this.bolOibDaNe;

  }

  //SPREMANJE
  spremi() {

    this.frmPartner.markAllAsTouched();

    if (this.frmPartner.valid) {

      this.srvKlPartneri.spremiPartnera(this.frmPartner.value, (rez: Vpartner) => {

        if (rez) {

          this.frmPartner.patchValue(rez);
          this.srvKlijentiIr.objPartner.next(this.frmPartner.value);

          this.appService.msgBox(1, "Uspješno spremljeno", 2000);

          document.getElementById('btnMdlPartnerClose').click();

          this.napraviFrmPartner(() => { });

        } else {
          this.appService.msgBox(2, "Greška prilikom spremanja", 3000);
        }

      })

    } else {
      this.appService.msgBox(2, "Provjerite ispravnost popunjenih polja", 3000);
    }

  }

  ngOnDestroy() {

  }


}

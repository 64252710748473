<div class="container">


    <div *ngIf="navBool" class="card text-white bg-info mb-3">
        <div class="card-header">Info portal <i class="material-icons">error_outline</i></div>
        <div class="card-body">
            <h5 class="card-title">Dobro došli!</h5>
            <p class="card-text">#Accounting</p>
        </div>
    </div>

    <br />
    <div *ngIf="dlgFail" class="alert alert-danger" role="alert">
        <p>Neuspjela prijava!</p>
    </div>


    <br/>

    <form [formGroup]="registerForm" style="margin-left: 10px">

        <div class="form-group">
            <mat-form-field>
                <mat-label>Korisničko ime</mat-label>
                <input matInput type="email" formControlName="email" placeholder="e-mail adresa" name="kor" (change)="dlgFail=false" [ngClass]="{ 'is-invalid': submitted &amp;&amp; registerForm.controls.email.errors }" required>
            </mat-form-field>
        </div>


        <div class="form-group">
            <mat-form-field>
                <mat-label>Lozinka</mat-label>
                <input matInput type="password" autocomplete="off" formControlName="password" placeholder="minimalno 4 znaka" (change)="dlgFail=false" name="pass1" [ngClass]="{ 'is-invalid': submitted &amp;&amp; registerForm.controls.password.errors }" required>
            </mat-form-field>
        </div>
        <div class="form-group">
            <button class="btn btn-primary" (click)="fnKorID()">Prijava</button>
        </div>



    </form>

</div>
<div *ngFor="let x of srvKlijentiIr.objPredlosci" style="border-bottom: solid 0.5px #d3d3d3;">
    <div class="row">
        <div class="col col-1 d-flex justify-content-center align-items-center"
            style="cursor: pointer; font-size: 1.4rem;" (click)="x.sel = !x.sel; napomenaCheck(x)">
            <fa-icon *ngIf="!x.sel" [icon]="srvFa.faSqureReg"></fa-icon>
            <fa-icon *ngIf="x.sel" [icon]="srvFa.faCheckSquareReg"></fa-icon>
        </div>
        <div class="col d-flex flex-wrap align-items-center">
            <div *ngIf="!x.edit" style="white-space: pre-line;">
                    {{x.rpTekst}}   
            </div>
            <mat-form-field *ngIf="x.edit" style="width: 100%;">
                <mat-label>napomena</mat-label>
                <textarea autocomplete="off" type="text" aria-label="rnnNapomena" matInput
                    [attr.name]="'rnnNapomena' + x.rpID" [(ngModel)]="x.rpTekst"></textarea>
            </mat-form-field>
        </div>
        <div class="col col-1 d-flex justify-content-center align-items-center"
            style="font-size: 2rem; cursor: pointer;">
            <div (click)="x.edit = !x.edit; intEditCheck(); x.text2 = x.rpTekst">
                <fa-icon *ngIf="!x.edit" [icon]="srvFa.faEditReg"></fa-icon>
            </div>
            <div (click)="x.edit = !x.edit; intEditCheck(); x.rpTekst = x.text2">
                <fa-icon *ngIf="x.edit" [icon]="srvFa.faTimes"></fa-icon>
            </div>
        </div>
    </div>
</div>
<div style="margin-top: 15px;">
    <button *ngIf="intOdabir && !intEdit" type="button" class="btn btn-success" style="width: 100%;" (click)="potvrdiOdabir()">POTVRDI
        ODABIR</button>
    <button *ngIf="!intOdabir && !intEdit" type="button" class="btn btn-light" style="width: 100%;">NEMA ODABRANIH
        PREDLOŽAKA</button>

    <button *ngIf="intEdit" type="button" class="btn btn-success" style="width: 100%;" (click)="spremiPredloske()">SPREMI PROMJENE</button>
</div>
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DropDownAnimation } from '../animacije';
import { AppService } from '../app.service';
import { FontawesomeIconsService } from '../fontawesome-icons.service';
import { KlijentiIrStavUredComponent } from '../klijenti-ir-stav-ured/klijenti-ir-stav-ured.component';
import { Artikl, KlijentiIrService, PorezIR, RacunStavka, VracunIR, VracunStavka } from '../klijenti-ir.service';
import { KlijentiService, VposNaplUred } from '../klijenti.service';


@Component({
  selector: 'app-klijenti-ir-stavke',
  templateUrl: './klijenti-ir-stavke.component.html',
  styleUrls: ['./klijenti-ir-stavke.component.css'],
  animations: [DropDownAnimation]
})
export class KlijentiIrStavkeComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private http: HttpClient,
    public appService: AppService,
    public srvKlijentiIr: KlijentiIrService,
    private hotkeysService: HotkeysService,
    public srvKlijenti: KlijentiService,
    public srvFa: FontawesomeIconsService
  ) {
    
  }

  @ViewChild('KlijentiIrStavUredComponent', { static: true }) chlStavkaUred: KlijentiIrStavUredComponent;
  //@ViewChild('artNaziv', { static: true }) artNaziv: HTMLTextAreaElement;


  rnIRID: number;
  objStavke: VracunStavka[];
  intUred: boolean = false;
  rnSel: VracunIR;
  vPosNaplUred: VposNaplUred;
  bolBrisanje: boolean = false;


  ngOnInit() {

    this.hotkeysService.add(new Hotkey('f5', (event: KeyboardEvent): boolean => {
      console.log('F5 ir stavke');
      return false; // Prevent bubbling
    }));

    this.srvKlijentiIr.objArtiklSub$.subscribe(data => {

      this.srvKlijenti.artikliByKlijentId(parseInt(localStorage.getItem('korID')), (rez: Artikl[]) => {
        this.chlStavkaUred.objArtikli = rez;
      });

      this.chlStavkaUred.frmStavka.get('rsArtiklID').setValue(data.artID);
      this.chlStavkaUred.frmStavka.get('artNaziv').setValue(data.artNaziv);
      this.chlStavkaUred.frmStavka.get('artNazivChk').setValue(data.artNaziv);
      this.chlStavkaUred.frmStavka.get('rsJedMj').setValue(data.jedMj);
      this.chlStavkaUred.frmStavka.get('cijena').setValue(data.artCijena);
      this.chlStavkaUred.frmStavka.get('povNak').setValue(data.artPovNak);
      this.chlStavkaUred.frmStavka.get('pdvStopa').setValue(data.artPdvStopa);

      this.chlStavkaUred.frmStavka.markAllAsTouched();

      console.log(this.chlStavkaUred.frmStavka.value);

      setTimeout(() => {
        document.getElementById('artNaziv').focus();
      }, 500);

    });

    //Račun ID
    this.rnSel = JSON.parse(localStorage.getItem('objRnSel'));
    this.rnIRID = this.rnSel.rnID;

    //poslovni prostor / naplatni uređaj
    this.vPosNaplUred = JSON.parse(localStorage.getItem('objPoslNpSel'))

    this.srvKlijentiIr.stavkeByRnId(this.rnIRID, (rez: VracunStavka[]) => {
      this.objStavke = rez;
      if (!this.objStavke) {
        this.intUred = true;
      }
    });

  }

  ngAfterViewInit() {
    //console.log(this.chlStavkaUred.izbaciFrmStavka());
  }

  ngOnDestroy() {
    //this.srvKlijentiIr.objArtiklSub.unsubscribe();
    localStorage.removeItem('rnIRID');
  }

  //Odabir stavke za uređivanje
  odaberiStavku(x) {

    this.intUred = true;

    let obj = x;
    obj.artNaziv = x.artNaziv;
    obj.artNazivChk = x.artNaziv;

    this.chlStavkaUred.frmStavka.patchValue(obj);

    console.log(this.chlStavkaUred.frmStavka.value);

    document.getElementById('top').scrollIntoView({ behavior: "smooth", block: "center" });

  }

  //Očisti frmStavka
  ponistiUnos() {
    //this.intUred = false;
    this.chlStavkaUred.napraviFrmStavka(() => {
      setTimeout(() => {
        document.getElementById('artNaziv').focus();
      }, 500);
    });
  }

  //Spremi stavku
  spremiStavku() {

    this.chlStavkaUred.frmStavka.markAllAsTouched();

    if (this.chlStavkaUred.frmStavka.valid) {

      let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=spremiStavku";
      let obj: string = JSON.stringify(this.chlStavkaUred.frmStavka.value);

      this.http.post(url, obj).subscribe((rez: VracunStavka) => {

        if (rez && !rez['err']) {

          if (this.chlStavkaUred.frmStavka.get('rsID').value) {
            //Update
            let rsIdTmp: number = this.chlStavkaUred.frmStavka.get('rsID').value;


            this.objStavke.forEach((val, ind) => {
              if (val.rsID == rsIdTmp) {
                //val[ind] = rez;
                this.objStavke.splice(ind, 1, rez);
              }
            })

          } else {
            //Nova stavka

            if (!this.objStavke) {
              this.objStavke = [];
            }

            this.objStavke.push(rez);

            this.chlStavkaUred.napraviFrmStavka(() => {
            });

          }

          this.srvKlijentiIr.pdvIznosiByRnID(this.rnSel.rnID, (rez: PorezIR[]) => {

            this.rnSel.pdv = rez;
            this.rnSel.stavke = this.objStavke;
            localStorage.setItem('objRnSel', JSON.stringify(this.rnSel));

            this.appService.msgBox(1, "Spremljeno!", 3000);

            setTimeout(() => {
              document.getElementById('artNaziv').focus();
            }, 500);

          });

        } else {
          this.appService.msgBox(2, "Greška kod dohvaćanja podataka!", 3000);
        }

      });

    } else {
      this.appService.msgBox(2, "Provjerite ispravnost upisanih polja!", 4000);
    }

  }

  //obriši stavku
  obrisiStavku(rsID: number) {

    console.log(rsID);

    let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=obrisiStavku";
    let obj: string = JSON.stringify({ rsID: rsID });

    this.http.post(url, obj).subscribe((rez) => {
      if (rez) {

        this.objStavke.forEach((val, ind) => {
          if (val.rsID == rsID) {
            this.objStavke.splice(ind, 1);
          }
        });

        this.rnSel.stavke = this.objStavke;

        this.srvKlijentiIr.pdvIznosiByRnID(this.rnSel.rnID, (rez: PorezIR[]) => {

          this.rnSel.pdv = rez;

          localStorage.setItem('objRnSel', JSON.stringify(this.rnSel));

          this.chlStavkaUred.napraviFrmStavka(() => { });

          this.bolBrisanje = false;

          this.appService.msgBox(1, "Obrisano!", 3000);

        })


      } else {
        this.appService.msgBox(2, "Greška prilikom brisanja!", 3000);
      }
    });

  }

  //Unos novog artikla
  urediArtikl() {

    if ((!this.chlStavkaUred.frmStavka.valid && this.chlStavkaUred.frmStavka.get('artNaziv').value) || (!this.chlStavkaUred.frmStavka.get('artNaziv').value)) {
      this.chlStavkaUred.frmStavka.get('rsArtiklID').setValue(0);
    }

    this.srvKlijentiIr.objArtStav.next({ artID: this.chlStavkaUred.frmStavka.get('rsArtiklID').value, artNaziv: this.chlStavkaUred.frmStavka.get('artNaziv').value });

    document.getElementById('btnMdlArtikl').click();
  }


}

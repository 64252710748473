<div class="container">

    <div class="row d-flex" style="margin: 0; padding: 10px;">

        <div class="col">
            <button type="button" class="btn btn-outline-info" data-toggle="modal" data-target="#mdlPromjenaLoz">Promjena lozinke</button>
        </div>

        <div class="col">
            <button type="button" class="btn btn-outline-info" routerLink="../intrakt">Interni akt</button>
        </div>

    </div>

</div>
<div class="alert alert-info">
    <div class="row">

        <div class="col-6">
            <!-- <span style="color: gainsboro">Vrsta: </span><span>{{appComp.vrstaR}}</span> -->
            <span>Plaćanja</span>
        </div>
        <div class="col-6">
            <span style="color: gainsboro">Razdoblje:
            </span><span>{{appComp.dat1 | date : "dd.MM.yyyy."}}</span>
            <span> - {{appComp.dat2 | date : "dd.MM.yyyy."}}</span>
            <br />
            <span style="color: gainsboro" *ngIf="appComp.upitR">Filter:</span>
            <span>{{appComp.upitR}}</span>
        </div>


    </div>
</div>

<div class="container" *ngIf="this.racuniSrv.ucitavanje">
    <div style="font-size: 0.8rem;">učitavanje...</div>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <br />
</div>

<!--Okvir za row-->
<div *ngFor="let x of this.racuniSrv.objPlacanja" style="margin-top: 5px;">

    <div class="container card">

        <div class="row card-header" [ngStyle]="{'background-color': x.Duguje != 0 ? 'rgb(253, 244, 238)' : 'mintcream'}" style="padding: 5px;">

            <div class="col-12">

                <div>{{x.Naziv}}</div>

            </div>

        </div>

        <div class="row" style="padding: 5px;">



            <div class="col">{{x.DatumRnF}}</div>
            <div class="col-sm">{{x.SvrhaDoznake}}</div>
            <div *ngIf="x.PozivNaBr" class="col">PNB: {{x.PozivNaBr}}</div>
            <div class="col text-right"><span class="float-left" style="font-size: 0.8rem; color:grey;">{{x.ZrRn}}
                </span><strong>{{x.Iznos | number: '1.2-2' }} Kn</strong></div>




        </div>

    </div>

</div>
<!-- msgBox objDialogInfo-->
<div *ngIf="appService.objDlgInfo" class="alert dlg-msg" style="z-index: 99999999; height: 70px;" [ngClass]="{'alert-success': appService.objDlgInfo.vrsta == 1, 'alert-danger': appService.objDlgInfo.vrsta == 2 }" @navMsg>
    <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
        <div>
            {{appService.objDlgInfo.text}}
        </div>
    </div>
</div>
<!--Material Sidenav-->
<mat-sidenav-container style="overflow: hidden;" fullscreen>

    <mat-sidenav #sidenav>

        <mat-nav-list style="margin-top: 10px;">



            <div style="margin-left: 15px;">

                <div style="width: 300px;"></div>

                <!--GLAVNI IZBORNIK - Klijenti-->
                <div class="row shadow" (click)="sidenav.toggle()" routerLink="klijenti" style="margin-bottom: 15px; cursor: pointer; width: 300px;" *ngIf="strIzbornik == 'glavni' && appService.accAdmin == true">

                    <div class="col-4">
                        <i class="material-icons" style="font-size: 4rem; color: indianred;">assignment_ind</i>
                    </div>

                    <div class="col-8" style="vertical-align: middle;">
                        <strong>Klijenti</strong>
                        <p><span style="color: darkgrey">Odabir klijenta - administratorske stranice</span></p>
                        <p></p>
                    </div>

                </div>

                <!--GLAVNI IZBORNIK - Računi-->
                <div class="row shadow" (click)="strIzbornik = 'racuni'" routerLink="racuni" style="margin-bottom: 15px; cursor: pointer; width: 300px;" *ngIf="strIzbornik == 'glavni'">

                    <div class="col-4">
                        <i class="material-icons" style="font-size: 4rem; color: indianred;">chrome_reader_mode</i>
                    </div>

                    <div class="col-8" style="vertical-align: middle;">
                        <strong>Računi</strong>
                        <p><span style="color: darkgrey">Pregled ulaznih, izlaznih računa i predujmova</span></p>
                        <p></p>
                    </div>

                </div>

                <!--GLAVNI IZBORNIK - Plaćanja-->
                <div class="row shadow" (click)="strIzbornik = 'placanja'" routerLink="placanja" style="margin-bottom: 15px; cursor: pointer; width: 300px;" *ngIf="strIzbornik == 'glavni'">

                    <div class="col-4 text-center" style="vertical-align: middle;">
                        <i class="material-icons" style="font-size: 4rem; color: indianred;">attach_money</i>
                    </div>

                    <div class="col-8" style="vertical-align: middle;">
                        <strong>Plaćanja</strong>
                        <p><span style="color: darkgrey">Pregled gotovinskih plaćanja i transakcija po žr.rn.</span></p>
                    </div>

                </div>

                <!--GLAVNI IZBORNIK - Salda konti-->
                <div class="row shadow" (click)="strIzbornik = 'saldakonti'" routerLink="saldakonti" style="margin-bottom: 15px; cursor: pointer; width: 300px;" *ngIf="strIzbornik == 'glavni'">

                    <div class="col-4">
                        <i class="material-icons" style="font-size: 4rem; color: indianred;">supervised_user_circle</i>
                    </div>

                    <div class="col-8" style="vertical-align: middle;">
                        <strong>Kupci / Dobavljači</strong>
                        <p><span style="color: darkgrey">Pregled stanja s kupacima i dobavljačima</span></p>
                    </div>

                </div>

                <!--GLAVNI IZBORNIK - Postavke-->
                <div class="row shadow" (click)="postavkeClick()" routerLink="postavke" style="margin-bottom: 15px; cursor: pointer; width: 300px;" *ngIf="strIzbornik == 'glavni'">

                    <div class="col-4">
                        <i class="material-icons" style="font-size: 4rem; color: indianred;">settings_applications</i>
                    </div>

                    <div class="col-8" style="vertical-align: middle;">
                        <strong>Postavke</strong>
                        <p><span style="color: darkgrey">Promjena lozinke</span></p>
                    </div>

                </div>

                <!--FORMA - Filtriranje-->
                <form>


                    <!--Dropdown Vrsta Računa-->
                    <div class="form-group" *ngIf="strIzbornik == 'racuni'" style=" margin-left: 20px;">
                        <mat-form-field style="width: 250px;">
                            <mat-label>Odaberite vrstu računa</mat-label>
                            <mat-select [(value)]="vrstaR">
                                <mat-option value="Ulazni">Ulazni</mat-option>
                                <mat-option value="Izlazni">Izlazni</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!--Dropdown Žiro računi-->
                    <div class="form-group" *ngIf="strIzbornik == 'placanja'" style=" margin-left: 20px; margin-top: 10px;">
                        <mat-form-field style="width: 250px;">
                            <mat-label>Odaberite vrstu plaćanja</mat-label>
                            <mat-select [(value)]="zrRnID">
                                <mat-option value="0">Sva plaćanja</mat-option>
                                <mat-option *ngFor="let x of objZrRn" value="{{x.PodaciZrrnID}}">{{x.ZrRn}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!--Odabir partnera (Salda konti)-->
                    <div *ngIf="strIzbornik == 'saldakonti'" style="width: 250px; margin-bottom: 15px;">

                        <div class="card" style="margin-bottom: 10px; width: 280px;">
                            <div>
                                <h6 style="color:lightsalmon; margin: 15px">{{partnerNaziv}}</h6>
                            </div>
                        </div>

                        <button class="btn btn-outline-dark" data-toggle="modal" data-target="#modalPartneri" style="width: 280px; margin-bottom: 15px;">

                            <!-- <i class="material-icons" style="font-size: 2rem;">folder_shared</i> <span>Partneri</span> -->
                            <div class="row">
                                <div class="col-4">
                                    <i class="material-icons" style="font-size: 2rem;">folder_shared</i>
                                </div>
                                <div class="col-8 align-self-center">
                                    Partneri - odabir
                                </div>
                            </div>


                        </button>

                        <mat-checkbox [(ngModel)]="chkGotovina" name="chkGotovina" style="margin-left: 20px;">Uključi gotovinska plaćanja
                        </mat-checkbox>

                    </div>



                    <!--Datepicker Datum OD-->
                    <div class="form-group" *ngIf="strIzbornik == 'racuni' || strIzbornik == 'placanja' || strIzbornik == 'saldakonti'">
                        <mat-form-field style="width: 250px; margin-left: 20px;">
                            <input matInput name="dat1" [(ngModel)]="dat1" [min]="miDate" [matDatepicker]="picker" placeholder="Datum od:">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #picker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <!--Datepicker Datum DO-->
                    <div class="form-group" *ngIf="strIzbornik == 'racuni' || strIzbornik == 'placanja' || strIzbornik == 'saldakonti'">
                        <mat-form-field style="width: 250px; margin-left: 20px;">
                            <input matInput name="dat2" [(ngModel)]="dat2" [min]="miDate" [matDatepicker]="picker2" placeholder="do:">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>


                    <!--TextBox Filter-->
                    <div class="form-group" *ngIf="strIzbornik == 'racuni' || strIzbornik == 'placanja'">
                        <mat-form-field class="example-full-width" style="width: 250px;  margin-left: 20px;">
                            <mat-label>Filter</mat-label>
                            <input matInput name="upitR" autocomplete="off" [(ngModel)]="upitR" placeholder="{{filterPlaceHolder }}">
                            <button mat-button *ngIf="upitR" matSuffix mat-icon-button aria-label="Clear" (click)="upitR=''">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                    <!--Button Fitriraj-->
                    <div class="row" (click)="filtriraj()" routerLink="racuni" style="margin-bottom: 15px; cursor: pointer; width: 300px;" *ngIf="strIzbornik == 'racuni' || strIzbornik == 'placanja' || strIzbornik == 'saldakonti'">

                        <div class="col-4 text-right">
                            <i class="material-icons" style="font-size: 2rem; color: indianred; margin-top: 2px;">search</i>
                        </div>

                        <div class="col-8 align-self-center" style="color: indianred; vertical-align: middle;">
                            <strong><u>Prikaži</u></strong>
                        </div>

                    </div>

                    <!-- <div style="margin-bottom: 15px" *ngIf="strIzbornik != 'glavni'">
                        <button (click)="strIzbornik = 'glavni'" class="btn btn-outline-dark icon-v-centar"
                            style="width: 100%"><i class="material-icons">menu_open</i> Glavni izbornik</button>
                    </div> -->

                    <!--Button Glavni Izbornik-->
                    <div class="row" (click)="strIzbornik = 'glavni'" routerLink="/pocetna" style="margin-bottom: 15px; cursor: pointer; width: 300px;" *ngIf="strIzbornik == 'racuni' || strIzbornik == 'placanja' || strIzbornik == 'saldakonti'">

                        <div class="col-4 text-right align-self-center">
                            <i class="material-icons" style="font-size: 2rem; color: indianred; margin-top: 2px;">menu_open</i>
                        </div>

                        <div class="col-8 align-self-center" style="color: indianred;">
                            <strong><u>Glavni izbornik</u></strong>
                        </div>

                    </div>



                    <!-- <div class="form-group">
                        <button class="btn btn-outline-danger icon-v-centar" style="width: 100%;" (click)="odjava()">                    
                        <i class="material-icons">exit_to_app</i> Odjava
                    </button>
                    </div> -->


                </form>




            </div>

        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>


        <div *ngIf="this.korID" class="row  app-toolbar shadow ne-print" style="background-color: white; padding: 2px;">

            <div clas="col-12" style=" width: 100%;">
                <button (click)="sidenav.toggle()" class="btn btn-light" style="background-color: white;">
                    <mat-icon>menu</mat-icon>
                </button>
                <a routerLink='/pocetna' style="margin-left: 10px"><span
                        style="margin-top: 15px;"><fa-icon [icon]="srvFa.faHouseUser"></fa-icon> {{this.appService.strKorisnik}}</span></a>
                <a class="btn-outline text-center float-right" (click)="odjava()" style="margin-right: 15px; margin-top: 5px; color: darkred; cursor: pointer;"><strong>Odjava</strong>
                </a>
            </div>



        </div>



        <router-outlet></router-outlet>



    </mat-sidenav-content>

</mat-sidenav-container>

<!-- Modal IR Stavke-->
<div class="modal fade" id="myModal" #myModal role="dialog">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-toggle="modal" data-target="#myModal">&times;</button>
            </div>
            <div class="modal-body">

                <div *ngFor="let x of objStavke; let i = index">
                    <div class="row">

                        <div class="col-12" style="color: brown; font-weight: bold;">
                            <strong>{{x.Naziv}}</strong>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col-4">
                            <strong>{{x.pkvKol | number}} </strong> {{x.Jed}}
                        </div>

                        <div class="col-8 text-right">
                            <span>Cijena bez PDV-a: </span>  <strong>{{x.VPC | number : '1.2-2'}}</strong>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col text-right">
                            <span>PDV </span> <strong>{{x.pkvStopaPDV | number}}</strong>% <span> = </span>
                            <strong>{{x.pkiPDViznos | number : '1.2-2'}}</strong>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col text-right" style="margin-bottom: 10px;">
                            <span>Ukupno: </span> <strong>{{x.pkiProdVrijednost | number : '1.2-2'}}</strong>
                        </div>

                    </div>


                    <mat-divider></mat-divider>

                </div>


            </div>

        </div>
    </div>

</div>

<!-- Modal Partneri-->
<div class="modal fade" id="modalPartneri" #modalPartneri role="dialog">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header row" style="margin-bottom: 2px;">

                <mat-radio-group [(ngModel)]="chkKupci" aria-label="Odabir, kupci dobavljači" class="text-center" style="margin-left: 10px;">
                    <mat-radio-button [value]="1" (click)="filterPartneri(upitR,1)">Kupci</mat-radio-button>
                    <mat-radio-button [value]="2" (click)="filterPartneri(upitR,2)" style="margin-left: 10px;">
                        Dobavljači</mat-radio-button>
                </mat-radio-group>

                <div class="col-12">
                    <div class="form-group" style="margin-bottom: 0px;">
                        <mat-form-field class="example-full-width" style="width: 250px;">
                            <mat-label>Filter</mat-label>
                            <input matInput name="upitPa" [(ngModel)]="upitR" placeholder="Naziv" (ngModelChange)="filterPartneri(upitR, chkKupci)">
                        </mat-form-field>
                    </div>
                </div>

            </div>
            <div class="modal-body">

                <div *ngFor="let x of fltPartneri; let i = index" style="cursor: pointer;" (click)="potvrdiOdabirPartnera(x.Naziv, x.rObvezaID, x.Adresa, x.Mjesto, x.OIB)" data-toggle="modal" data-target="#modalPartneri">
                    <div class="row" style="padding-bottom: 10px;">
                        <div class="col-12" style="color: brown; font-weight: bold;">
                            <strong>{{x.Naziv}}</strong>
                        </div>

                    </div>
                    <mat-divider></mat-divider>
                </div>


            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#modalPartneri" style="width: 100%">Odustani</button>
            </div>


        </div>
    </div>

</div>

<!-- Modal Prommjena lozinke-->
<div class="modal fade" id="mdlPromjenaLoz" tabindex="-1" role="dialog" aria-labelledby="mdlPromjenaLozLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="mdlPromjenaLozLabel">Promjena lozinke</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form *ngIf="frmPromjenaLoz" [formGroup]="frmPromjenaLoz">

                  
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Trenutna lozinka</mat-label>
                        <input matInput type="password" autocomplete="off" formControlName="loz1" placeholder="Trenutna lozinka" required>
                        <mat-error *ngIf="frmPromjenaLoz.controls['loz1'].errors?.required">Obavezan unos</mat-error>
                        <mat-error *ngIf="frmPromjenaLoz.controls['loz1'].errors?.minlength">Minimalno 4 znaka
                        </mat-error>
                    </mat-form-field>

                    
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Nova lozinka</mat-label>
                        <input matInput type="password" autocomplete="off" formControlName="loz2" placeholder="Nova lozinka" required>
                        <mat-error *ngIf="frmPromjenaLoz.controls['loz2'].errors?.required">Obavezan unos</mat-error>
                        <mat-error *ngIf="frmPromjenaLoz.controls['loz2'].errors?.minlength">Minimalno 4 znaka
                        </mat-error>
                    </mat-form-field>

                    
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>Ponovite novu lozinku</mat-label>
                        <input matInput type="password" autocomplete="off" formControlName="loz3" placeholder="Ponovite novu lozinku" required>
                        <mat-error *ngIf="frmPromjenaLoz.controls['loz3'].errors?.required">Obavezan unos</mat-error>
                        <mat-error *ngIf="frmPromjenaLoz.controls['loz3'].errors?.minlength">Minimalno 4 znaka
                        </mat-error>
                        <mat-error *ngIf="frmPromjenaLoz.controls['loz3'].errors?.mustMatch">Morate upisati identičnu vrijednost kao u polju 'Lozinka'</mat-error>
                    </mat-form-field>

                </form>
            </div>
            <div class="modal-footer d-block" style="justify-content: center; padding: 0px;">
                <div class="row" style="margin: 0;">
                    <div class="col" style="padding: 5px;">
                        <button type="submit" (click)="promjeniLozinku()" class="btn btn-outline-success w-100">Potvrdi</button>
                    </div>
                    <div class="col" style="padding: 5px;">
                        <button id="btnMdlPromjenaLozClose" type="button" class="btn btn-outline-secondary w-100" data-dismiss="modal">Odustani</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>

<!-- Modal Artikl-->
<button id="btnMdlArtikl" hidden="true" data-toggle="modal" data-target="#mdlArtikl">modal artikl</button>
<div class="modal fade" id="mdlArtikl" tabindex="-1" role="dialog" aria-labelledby="mdlArtikl" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="mdlPromjenaLozLabel">Artikl - uređivanje</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-klijenti-artikli></app-klijenti-artikli>
            </div>
        </div>
    </div>
</div>

<!-- Modal Partner-->
<button id="btnMdlPartner" hidden="true" data-toggle="modal" data-target="#mdlPartner">modal partner - uređivanje</button>
<div class="modal fade" id="mdlPartner" style="z-index: 10002;" role="dialog" aria-labelledby="mdlPartner" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Partner</h5>
                <button id="btnMdlPartnerClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-klijenti-partneri-ured #partner></app-klijenti-partneri-ured>
            </div>
        </div>
    </div>
</div>

<!-- Modal Napomene pred-->
<button id="btnMdlIrNapPred" hidden="true" data-toggle="modal" data-target="#mdlIrNapPred">modal napomena predložak - uređivanje</button>
<div class="modal fade" id="mdlIrNapPred" style="z-index: 10002;" role="dialog" aria-labelledby="mdlIrNapPred" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Napomene - predlošci</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-ir-napomene-predlozak #napPred></app-ir-napomene-predlozak>
            </div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { PocetnaComponent } from './pocetna/pocetna.component';
import { LoginComponent } from './login/login.component';
import { RacuniComponent } from './racuni/racuni.component';
import { PlacanjaComponent } from './placanja/placanja.component';
import { KlijentiComponent } from './klijenti/klijenti.component';
import { SaldakontiComponent } from './saldakonti/saldakonti.component';
import { PostavkeComponent } from './postavke/postavke.component';
import { KlijentiIrComponent } from './klijenti-ir/klijenti-ir.component';
import { KlijentiIrStavkeComponent } from './klijenti-ir-stavke/klijenti-ir-stavke.component';
import { KlijentiIrUredjivanjeComponent } from './klijenti-ir-uredjivanje/klijenti-ir-uredjivanje.component';
import { KlijentiPartneriComponent } from './klijenti-partneri/klijenti-partneri.component';
import { KlijentiPartneriUredComponent } from './klijenti-partneri-ured/klijenti-partneri-ured.component';
import { KlijentiArtikliSifarnikComponent } from './klijenti-artikli-sifarnik/klijenti-artikli-sifarnik.component';
import { InterniAktComponent } from './interni-akt/interni-akt.component';
import { KlijentiPrometComponent } from './klijenti-promet/klijenti-promet.component';
import { KpiComponent } from './kpi/kpi.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pocetna',
    pathMatch: 'full',
  },
  {
    path: 'pocetna',
    component: PocetnaComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'klijenti-partneri',
    component: KlijentiPartneriComponent
  },
  {
    path: 'klijenti-artikli-sifarnik',
    component: KlijentiArtikliSifarnikComponent
  },
  {
    path: 'klijenti-partneri-ured',
    component: KlijentiPartneriUredComponent
  },
  {
    path: 'klijenti-ir',
    component: KlijentiIrComponent
  },
  {
    path: 'klijenti-ir-uredjivanje',
    component: KlijentiIrUredjivanjeComponent
  },
  {
    path: 'klijenti-ir-stavke',
    component: KlijentiIrStavkeComponent
  },
  {
    path: 'racuni',
    component: RacuniComponent
  },

  {
    path: 'placanja',
    component: PlacanjaComponent
  },
  {
    path: 'klijenti',
    component: KlijentiComponent
  },
  {
    path: 'saldakonti',
    component: SaldakontiComponent
  },
  {
    path: 'postavke',
    component: PostavkeComponent
  },
  {
    path: 'intrakt',
    component: InterniAktComponent
  },
  {
    path: 'klijenti-promet',
    component: KlijentiPrometComponent
  },
  {
    path: 'kpi',
    component: KpiComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

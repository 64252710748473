import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { RacuniService } from './racuni.service';
import { RacunUpit } from './racuni.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from './dateformats';
import { formatDate } from "@angular/common";
import { AppService } from './app.service';
//import { PlacanjaComponent } from './placanja/placanja.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import * as validatori from './validatori';
import { NavMsg } from './animacije';
import { KlijentiIrService } from './klijenti-ir.service';
import { FontawesomeIconsService } from './fontawesome-icons.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [NavMsg]
  /* providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    { provide: PlacanjaComponent, useClass: PlacanjaComponent }
  ] */
})

export class AppComponent implements OnInit {

  constructor(
    public srvFa: FontawesomeIconsService,
    private router: Router,
    public loginService: LoginService,
    private racuniService: RacuniService,
    public appService: AppService,
    private http: HttpClient,
    //private dateAdapter: DateAdapter<Date>,
    private frmBuilder: FormBuilder,
    public srvKlijentiIr: KlijentiIrService
  ) {

  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    if (this.appService.objPodaciSel){
      localStorage.setItem('objPodaciSel', JSON.stringify(this.appService.objPodaciSel));
    }
  }

  @ViewChild('sidenav') sidenav: MatSidenav;


  title = 'sharpAccounting';
  korID: number;

  filterPlaceHolder: string;

  //SideNav
  miDate = moment('2018-01-01',).format('YYYY-MM-DD'); //new Date(2018, 0, 1);
  strIzbornik: string = 'glavni';

  vrstaR: string = 'Ulazni';
  zrRnID: string = '0';
  dat1 = moment(moment().format('YYYY') + '-01-01').format('YYYY-MM-DD'); //new Date().getFullYear() + '-01-01';
  dat2 = moment().format('YYYY-MM-DD'); //formatDate(new Date(), 'yyyy-MM-dd', 'en-US'); //'2019-07-31';
  uvjetPr: string = 'Naziv';
  upitR: string = '';

  objStavke;
  objZrRn;

  objPartneri;
  fltPartneri;
  chkKupci: number = 1;
  partnerID: number = 0;
  partnerNaziv: string = 'Nije odabran partner';
  partnerAdresa: string = '';
  partnerMjesto: string = '';
  partnerOIB: string = '';

  chkGotovina;

  frmPromjenaLoz: FormGroup;

  //objPlacanja;

  ngOnInit() {

    if (localStorage.getItem('objInfoKorinsik')) {
      this.appService.objInfoKorinsik = JSON.parse(localStorage.getItem('objInfoKorinsik'));
    }

    if (localStorage.getItem('objPodaciSel')) {
      this.appService.objPodaciSel = JSON.parse(localStorage.getItem('objPodaciSel'));
    }

    this.loginService.autorizirajStranicu(kTmp => {

      if (!kTmp) {
        this.korID = 0;
        this.router.navigate(['pocetna']);
      } else {

        this.napraviFrmPromjenaLoz();

        this.korID = this.loginService.intKorID;
        this.objZrRn = JSON.parse(localStorage.objZrRn);

        if (localStorage.accAdmin == 'true') {
          this.appService.accAdmin = true;
          this.miDate = moment('2000-01-01',).format('YYYY-MM-DD');
        } else {
          this.appService.accAdmin = false;
        }

      }

    });

  }

  filtriraj() {

    if (this.strIzbornik == 'racuni') {
      this.filtrirajRacune();
      this.router.navigate(['racuni']);
    } else if (this.strIzbornik == 'placanja') {
      this.router.navigate(['placanja']);
      this.filtrirajPlacanja();
    } else if (this.strIzbornik == 'saldakonti') {
      this.router.navigate(['saldakonti']);
      this.filtrirajSaldaKonti();
    }

  }

  filtrirajRacune() {

    this.filterPlaceHolder = 'Naziv partnera, br. računa...';
    this.racuniService.rsDataSource = null;
    this.racuniService.ucitavanje = true;

    this.sidenav.close();

    let ruTmp: RacunUpit = {
      vrstaR: this.vrstaR,
      dat1: moment(this.dat1).format('YYYY-MM-DD'), //formatDate(this.dat1, 'yyyy-MM-dd', 'en-US'),
      dat2: moment(this.dat2).format('YYYY-MM-DD'), //formatDate(this.dat2, 'yyyy-MM-dd', 'en-US'),
      uvjetPr: this.uvjetPr,
      upitR: this.upitR
    }

    this.racuniService.ucitajRacune(ruTmp);

  }

  filtrirajPlacanja() {

    this.filterPlaceHolder = 'Naziv, Opis plaćanja, pnb';
    this.racuniService.objPlacanja = null;
    this.racuniService.ucitavanje = true;

    this.sidenav.close();

    let urlTmp: string = this.appService.webUrl + 'php/placanja.php';

    let dat1tmp = moment(this.dat1).format('YYYY-MM-DD') //formatDate(this.dat1, 'yyyy-MM-dd', 'en-US');
    let dat2tmp = moment(this.dat2).format('YYYY-MM-DD') //formatDate(this.dat2, 'yyyy-MM-dd', 'en-US');

    let paramTmp = new HttpParams()
      .set('podaciID', localStorage.korID)
      .set('zrrnID', this.zrRnID)
      .set('datumOD', dat1tmp)
      .set('datumDO', dat2tmp)
      .set('upit', this.upitR);

    this.http.get(urlTmp, { params: paramTmp }).toPromise().then(rez => {

      this.racuniService.objPlacanja = rez;
      this.racuniService.ucitavanje = false;

    });

  }

  ucitajPartnere() {


    let urlTmp: string = this.appService.webUrl + 'php/partneri_salda.php?korID=' + this.korID;

    this.http.get(urlTmp).toPromise().then(rez => {

      this.objPartneri = rez;
      this.fltPartneri = rez;

      this.filterPartneri(this.upitR, this.chkKupci);

    });

  }

  //Textbox Pretraga
  filterPartneri(strP: string, intKD: number) {

    let output = [];

    this.objPartneri.forEach(objIme => {

      if (objIme.Naziv.toLowerCase().indexOf(strP.toLowerCase()) >= 0 && objIme.DokumentID.indexOf(intKD) == 0) {
        output.push(objIme);
      }

    });

    this.fltPartneri = output;

  };

  potvrdiOdabirPartnera(Naziv: string, rObvezaID: number, Adresa: string, Mjesto: string, OIB: string) {

    this.partnerID = rObvezaID;
    this.partnerNaziv = Naziv;
    this.partnerAdresa = Adresa;
    this.partnerMjesto = Mjesto;
    this.partnerOIB = OIB;

  }


  //Filter Salda Konti
  filtrirajSaldaKonti() {

    this.filterPlaceHolder = 'Naziv';
    this.racuniService.objSaldaKonti = null;
    this.racuniService.ucitavanje = true;

    this.sidenav.close();

    let urlTmp: string = this.appService.webUrl + 'php/salda_konti.php';

    let dat1tmp = moment(this.dat1).format('YYYY-MM-DD') //formatDate(this.dat1, 'yyyy-MM-dd', 'en-US');
    let dat2tmp = moment(this.dat2).format('YYYY-MM-DD') //formatDate(this.dat2, 'yyyy-MM-dd', 'en-US');

    let paramTmp = new HttpParams()
      .set('Podaci_ID', localStorage.korID)
      .set('rObvezaID', this.partnerID.toString())
      .set('datOD', dat1tmp)
      .set('datDO', dat2tmp)
      .set('plTmp', this.chkGotovina);

    //alert(urlTmp + '?' + paramTmp);

    this.http.get(urlTmp, { params: paramTmp }).toPromise().then(rez => {

      this.racuniService.objSaldaKonti = rez['Analitika'];
      this.racuniService.objDonosSalda = rez['Donos'];
      this.racuniService.objSaldo = rez['Saldo'];

      let dTmp = rez['Donos'];
      this.racuniService.dDuguje = dTmp['dDuguje'];
      this.racuniService.dPotrazuje = dTmp['dPotrazuje'];

      let sTmp = rez['Saldo'];
      this.racuniService.sDuguje = sTmp['sDuguje'];
      this.racuniService.sPotrazuje = sTmp['sPotrazuje'];
      this.racuniService.sSaldo = sTmp['sSaldo'];

      this.racuniService.ucitavanje = false;

    });

  }

  //Postavke click
  postavkeClick() {
    this.strIzbornik = 'glavni';
    this.sidenav.close();
  }

  odjava() {

    localStorage.clear();

    this.korID = 0;
    localStorage.removeItem('korID');
    localStorage.removeItem('nazivP');
    localStorage.removeItem('objZrRn');
    localStorage.removeItem('objKlijent');
    localStorage.removeItem('accAdmin');
    localStorage.removeItem('objInfoKorinsik');

    localStorage.removeItem('operaterID');
    localStorage.removeItem('vPosNaplUred');
    localStorage.removeItem('godinaSel');
    localStorage.removeItem('objPoslNpSel');
    localStorage.removeItem('objRnSel');
    this.sidenav.close();
    //this.appService.accAdmin = false;
    this.router.navigate(['login']);


  }

  //Napravi frm Lozinka - promjena lozinke
  napraviFrmPromjenaLoz() {

    this.frmPromjenaLoz = this.frmBuilder.group({
      korID: [this.loginService.intKorID],
      loz1: ['', [Validators.required, Validators.minLength(4)]],
      loz2: ['', [Validators.required, Validators.minLength(4)]],
      loz3: ['', [Validators.required, Validators.minLength(4)]]
    },
      {
        validator: validatori.MustMatch('loz3', 'loz2')
      });

  }

  //Promjena lozinke
  promjeniLozinku() {

    this.frmPromjenaLoz.markAllAsTouched();

    if (this.frmPromjenaLoz.valid) {

      let url: string = this.appService.webUrl + 'php/pass_change.php';
      let obj: string = JSON.stringify(this.frmPromjenaLoz.value);

      this.http.post(url, obj).subscribe(rez => {

        if (rez) {

          console.log(rez);
          
          document.getElementById('btnMdlPromjenaLozClose').click();
          this.napraviFrmPromjenaLoz();
          this.appService.msgBox(1, "Lozinka je uspješno promijenjena!", 2000);

        } else {
          this.appService.msgBox(2, "Greška! Lozinka nije promijenjena!", 2000);
        }

       
      });

    } else {
      this.appService.msgBox(2, "Popunite ispravno sva polja!", 2000);
    }

  }

}

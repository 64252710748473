<div class="container">

    <div class="da-print" style="display: none;">
        <div style="margin-bottom: 50px;">
            <div style="font-weight: bold;">
                {{objKlijent.Naziv + ' ' + objKlijent.Djelatnost}}
            </div>
            <div *ngIf="objKlijent.Vl">
                Vl. {{objKlijent.Vl}}
            </div>
            <div>
                {{objKlijent.Adresa + ', ' + objKlijent.Mjesto}}
            </div>
            <div>
                {{'OIB: ' + objKlijent.OIB}}
            </div>
        </div>

        <div style="text-align: center; margin-bottom: 30px;">
            Pregled prometa za razdoblje od {{frmFilter.get('datOd').value | amDateFormat:'DD.MM.YYYY.'}} do {{frmFilter.get('datDo').value | amDateFormat:'DD.MM.YYYY.'}}
        </div>
    </div>

    <div class="row">

        <div class="col col-3 col-left ne-print">

            <form [formGroup]="frmFilter">

                <mat-form-field class="w-100">
                    <mat-label>Naplatni uređaj:</mat-label>
                    <mat-select formControlName="npID">
                        <mat-option *ngFor="let x of objPosNp" [value]="x.npID" (click)="postaviPosNp(x)">{{x.pos + '/' + x.np + ' (' + x.vrstaDok + ' ' + x.npNplacanja + ')'}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-100">
                    <mat-label>Vrsta pregleda:</mat-label>
                    <mat-select formControlName="vrstaID">
                        <mat-option [value]="1">Prikaži sve račune</mat-option>
                        <mat-option [value]="2">Grupiraj po danima</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-100">
                    <input matInput autocomplete="off" formControlName="datOd" [matDatepicker]="picker" placeholder="Datum od:">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="w-100">
                    <input matInput autocomplete="off" formControlName="datDo" [matDatepicker]="picker2" placeholder="do:">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker2></mat-datepicker>
                </mat-form-field>

                <div>
                    <button type="submit" class="btn btn-outline-dark w-100" (click)="prikazi()">Prikaži</button>
                </div>

            </form>

            <div *ngIf="objPosNpSel.npNplacanja != 'T'" style="margin: 20px 0 20px 0;">
                <div>
                    Polog:
                </div>
                <div *ngIf="!pologUred" class="d-flex" style="font-weight: bold; align-items: center;">
                    <div>
                        {{objPosNpSel.polog | number:'1.2-2'}}
                    </div>

                    <div class="ml-auto" style="cursor: pointer;" (click)="pologUredjivanje()">
                        <mat-icon>edit</mat-icon>
                    </div>
                </div>
                <div *ngIf="pologUred" class="d-flex" style="font-weight: bold; align-items: center;">
                    <div *ngIf="frmPolog" style="margin: 0 0 20px 0;">
                        <form [formGroup]="frmPolog">
                            <mat-form-field>
                                <!-- <mat-label>Polog</mat-label> -->
                                <input autocomplete="off" type="tel" aria-label="polog" matInput formControlName="polog" style="text-align: center; font-weight: bold;">
                                <mat-error style="text-align: center;" *ngIf="frmPolog.get('polog').errors?.required">Polje je obavezno za unos</mat-error>
                                <mat-error style="text-align: center;" *ngIf="frmPolog.get('polog').errors?.err">{{frmPolog.get('polog').errors.err}}</mat-error>
                            </mat-form-field>
                        </form>
                    </div>

                    <div class="ml-auto" style="cursor: pointer;" (click)="spremiPolog()">
                        <mat-icon>save</mat-icon>
                    </div>

                    <div class="ml-auto" style="cursor: pointer;" (click)="pologUred = 0;">
                        <mat-icon>close</mat-icon>
                    </div>

                </div>
                <div>
                    Promet:
                </div>
                <div style="font-weight: bold;">
                    {{ukupno | number:'1.2-2'}}
                </div>
                <div style="margin-top: 10px;">
                    Saldo blagajne:
                </div>
                <div style="font-weight: bold;">
                    {{objPosNpSel.polog*1 + ukupno*1 | number:'1.2-2'}}
                </div>
            </div>

            <div style="margin: 20px 0 0 0;">
                <button type="submit" class="btn btn-outline-dark w-100" (click)="ispis()">Ispis</button>
            </div>

        </div>

        <div class="col col-right">

            <div class="row th-right">

                <div class="col col-2" style="padding: 5px; text-align: left;">
                    Broj računa
                </div>

                <div class="col col-3" style="text-align: left; padding: 5px;">
                    Datum
                </div>

                <div class="col col-4" style="padding: 5px;">
                    Kupac
                </div>

                <div class="col col-3" style="text-align: right; padding: 5px;">
                    Iznos
                </div>

            </div>

            <div class="row" *ngFor="let x of objDnevniPromet">

                <div class="col col-2" style="text-align: right; padding: 5px; overflow: hidden;">
                    {{x.rnBroj}}
                </div>

                <div class="col col-3" style="padding: 5px; text-align: center;">
                    {{x.rnDatum | amDateFormat:'DD.MM.YYYY. HH:mm'}}
                </div>

                <div class="col col-4" style="padding: 5px;">
                    {{x.naziv}}
                </div>

                <div class="col col-3" style="text-align: right; padding: 5px;">
                    {{x.ukupno | number:'1.2-2'}}
                </div>

            </div>

            <div class="row th-right">

                <div class="col col-2" style="padding: 5px;">
                    Ukupno:
                </div>

                <div class="col col-3" style="text-align: center; padding: 5px;">

                </div>

                <div class="col col-4" style="padding: 5px;">

                </div>

                <div class="col col-3" style="text-align: right; padding: 5px;">
                    {{ukupno | number:'1.2-2'}}
                </div>

            </div>

            <div *ngIf="objPosNpSel.npNplacanja != 'T'" style="margin: 20px 0 20px 0;">
                <div>
                    Polog:
                </div>
                <div class="d-flex" style="font-weight: bold; align-items: center;">
                    <div>
                        {{objPosNpSel.polog | number:'1.2-2'}}
                    </div>
                </div>
                <div>
                    Promet:
                </div>
                <div style="font-weight: bold;">
                    {{ukupno | number:'1.2-2'}}
                </div>
                <div style="margin-top: 10px;">
                    Saldo blagajne:
                </div>
                <div style="font-weight: bold;">
                    {{(objPosNpSel.polog*1 + ukupno*1) | number:'1.2-2'}}
                </div>
            </div>

        </div>

    </div>

</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Podaci } from './klijenti.service';
import { InfoKorisnik } from './login.service';

export interface Mjesto {
  Mjesto_ID: number, 
  Mjesto: string, 
  Broj_poste: number, 
  Naziv_PU: string, 
  Zupanija: string
}

export const naciniPlacanja = [
  { id: 1, oznaka: 'G', opis: 'Gotovina' },
  { id: 2, oznaka: 'K', opis: 'Kartica' },
  { id: 3, oznaka: 'C', opis: 'Ček' },
  { id: 4, oznaka: 'T', opis: 'Transakcijski račun' },
  { id: 5, oznaka: 'O', opis: 'Ostalo' }
]

export interface DlgInfo {
  vrsta: number,
  text: string
}

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private http: HttpClient
  ) { }

  objDlgInfo: DlgInfo;
  s1: Subscription;

  objInfoKorinsik: InfoKorisnik;

  objPodaciSel: Podaci;

  nPlacanja = naciniPlacanja;

  ////public webUrl: string = 'https://financing.hr/';
  //public webUrl: string = 'https://sharprobotics.synology.me/sharpAccounting/';
  public webUrl: string = './';

  strKorisnik: string = localStorage.nazivP;
  accAdmin: boolean = false;

  //Opis plaćanja By ID
  getPlacanjeByID(placanjeID: number) {
    naciniPlacanja.forEach(val => {
      if (val.id == Number(placanjeID)) {
        console.log(val.opis);
        return val.opis;
      }
    });
  }

  //Opis plaćanja By Ozn
  getPlacanjeByOzn(ozn: string) {
    naciniPlacanja.forEach(val => {
      if (val.oznaka == ozn) {
        return val.opis;
      }
    });
  }

  //Opis plaćanja By Ozn
  getPlacanjeIdByOzn(ozn: string) {
    let valTmp: number;
    naciniPlacanja.forEach(val => {
      if (val.oznaka == ozn) {
        valTmp = val.id;
      }
    });
    return valTmp;
  }


  //msgBox
  msgBox(vrsta: number, tekst: string, trajanje: number) {

    this.objDlgInfo = {
      vrsta: vrsta,
      text: tekst
    };

    let tMsgBox = timer(trajanje);

    this.s1 = tMsgBox.subscribe(t => {
      this.objDlgInfo = null;
      this.s1.unsubscribe();
    })

  }

  //Mjesta u RH
  mjestaSelectAll(callback) {

    let url: string = this.webUrl + "php/mjesta.php?funk=mjestaSelectAll";

    this.http.get(url).subscribe((rez: Mjesto[]) => {
      callback(rez);
    });

  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Artikl, KlijentiIrService } from '../klijenti-ir.service';
import { KlijentiService } from '../klijenti.service';
import * as validatori from '../validatori';

@Component({
  selector: 'app-klijenti-artikli',
  templateUrl: './klijenti-artikli.component.html',
  styleUrls: ['./klijenti-artikli.component.css']
})
export class KlijentiArtikliComponent implements OnInit {

  constructor(
    private frmBuilder: FormBuilder,
    public appService: AppService,
    public srvKlijenti: KlijentiService,
    public srvKlijentiIr: KlijentiIrService
  ) { }

  frmArtikl: FormGroup;

  ngOnInit() {
    this.napraviFrmArtikl(() => {
      this.srvKlijentiIr.objArtStav.subscribe(data => {

        if (data.artID) {
          this.srvKlijenti.artiklById(data.artID, (rez: Artikl) => {
            this.frmArtikl.patchValue(rez);
          });

        } else {
          this.napraviFrmArtikl(() => {
            this.frmArtikl.get('artID').setValue(data.artID);
            this.frmArtikl.get('artNaziv').setValue(data.artNaziv);
          });
        }

      });
    });
  }

  //napravi frmArtikl
  napraviFrmArtikl(callback) {

    this.frmArtikl = this.frmBuilder.group({
      artID: [0],
      artKlijentID: [parseInt(localStorage.getItem('korID'))],
      artVrsta: ['1'],
      artNaziv: ['', Validators.required],
      jedMj: ['kom'],
      artPdvStopa: [25, validatori.decimalCheck],
      artCijena: [0, validatori.decimalCheck],
      artPovNak: [0, validatori.decimalCheck]
    });

    callback(this.frmArtikl.value);

  }

  //Spremi artikl
  spremiArtikl() {

    this.frmArtikl.markAllAsTouched();

    if (this.frmArtikl.valid) {

      this.srvKlijentiIr.spremiArtikl(this.frmArtikl.value, (rez: Artikl) => {

        if (rez) {
          this.appService.msgBox(1, "Spremljeno", 2000);
          document.getElementById('mdlDismiss').click();
        } else {
          this.appService.msgBox(2, "Greška prilikom spremanja", 2000);
        }

      });

    }

  }

  //Formatiranje decimalnog broja
  decFormat(event) {
    let strNaziv: string = event.srcElement.ariaLabel;
    this.frmArtikl.get(strNaziv).setValue(this.frmArtikl.get(strNaziv).value.replace(',','.'));
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { Mjesta, Podaci } from './klijenti.service';

export interface Partner {
  parID: number,
  parKlijentID: number,
  naziv: string,
  djelatnost: string,
  adresa: string,
  mjestoID: number,
  vlasnik: string,
  oib: string
}

export interface Vpartner extends Partner, Mjesta {

}

@Injectable({
  providedIn: 'root'
})
export class KlijentiPartneriService {

  constructor(
    private http: HttpClient,
    public appService: AppService
  ) { }

  objPartTmp: Vpartner;

  //Partneri SELECT ALL
  partneriSelectAll(callback) {

    let podaciID: number = parseInt(localStorage.getItem('korID'));

    let url: string = this.appService.webUrl + "php/klijenti-partneri.php?funk=selectAll";
    let obj: string = JSON.stringify({ podaciID: podaciID });

    this.http.post(url, obj).subscribe((rez: Vpartner[]) => {
      callback(rez);
    });

  }

  //partner by klijentID, OIB
  partneriByOib(oib: string, callback) {

    let podaciID: number = parseInt(localStorage.getItem('korID'));

    let url: string = this.appService.webUrl + "php/klijenti-partneri.php?funk=partnerByOib";
    let obj: string = JSON.stringify({ podaciID: podaciID, oib: oib });

    this.http.post(url, obj).subscribe((rez: Vpartner[]) => {
      callback(rez);
    });

  }

  //Filtriranje partnera
  filterPartneri(obj: Partner[], strP: string) {
    let output = [];
    if (strP.length > 0) {
      obj.forEach(val => {
        if (val.naziv.toLowerCase().indexOf(strP.toLowerCase()) >= 0) {
          output.push(val);
        }
      });
      return output;
    } else {
      return null;
    }
  }

  //Spremi partnera
  spremiPartnera(objPar: Vpartner, callback) {

    let url: string = this.appService.webUrl + "php/klijenti-partneri.php?funk=spremiPartnera";
    let obj: string = JSON.stringify(objPar);

    this.http.post(url, obj).subscribe((rez) => {
      console.log(rez);
      callback(rez);
    });

  }

}

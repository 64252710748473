import { Pipe, PipeTransform } from '@angular/core';
import { naciniPlacanja } from './app.service';

@Pipe({ name: 'placanjeOpisByOzn' })
export class PlacanjeByOzn implements PipeTransform {
    transform(value: string): string {
        let rez: string;
        naciniPlacanja.forEach(val => {
            if (val.oznaka == value) {
                rez = val.opis;
            } 
        });
        return rez;
    }
}

@Pipe({ name: 'placanjeOpisById' })
export class PlacanjeById implements PipeTransform {
    transform(value: number): string {
        let rez: string;
        naciniPlacanja.forEach(val => {
            if (val.id == value) {
                rez = val.opis;
            } 
        });
        return rez;
    }
}
<div class="container">

    <!-- Zaglavlje -->

    <div hidden class="da-print" style="padding: 20px;">
        <div style="font-weight: bold;">
            {{appService.objPodaciSel.Naziv}}
        </div>
        <div *ngIf="appService.objPodaciSel.Vl">
            Vl. {{appService.objPodaciSel.Vl}}
        </div>
        <div>
            {{appService.objPodaciSel.Adresa}}, {{appService.objPodaciSel.Mjesto}}
        </div>
        <div>
            OIB: {{appService.objPodaciSel.OIB}}
        </div>
    </div>
    

    <!--Naslov-->
    <div hidden class="da-print" style="margin: 50px 0;">
        <div style="font-weight: bold; font-size: 1.1rem; text-align: center;">
            Rekapitulacija prometa po partnerima
        </div>
        <div style="text-align: center;">
            Razdoblje od {{srvKpi.frmKpiFilter.get('DatumOd').value | amDateFormat:'DD.MM.YYYY.'}} do {{srvKpi.frmKpiFilter.get('DatumDo').value | amDateFormat:'DD.MM.YYYY.'}}
        </div>
        <div style="margin: 20px 20px 50px;">
            Konto: {{srvKpi.frmKpiFilter.get('intKupDob').value == 1 ? 'Kupci' : 'Dobavljači'}}
        </div>
    </div>
    

    <div class="row">

        <div class="col col-4 l-col ne-print">

            <div *ngIf="srvKpi.frmKpiFilter" style="margin-top: 20px;">

                <form [formGroup]="srvKpi.frmKpiFilter">

                    <mat-form-field class="w-100">
                        <input matInput autocomplete="off" formControlName="DatumOd" [matDatepicker]="picker" placeholder="Datum od:">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                    </mat-form-field>
    
                    <mat-form-field class="w-100">
                        <input matInput autocomplete="off" formControlName="DatumDo" [matDatepicker]="picker2" placeholder="do:">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker2></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="w-100">
                        <mat-label>Vrsta pregleda:</mat-label>
                        <mat-select formControlName="intKupDob">
                            <mat-option [value]="1">Kupci</mat-option>
                            <mat-option [value]="2">Dobavljači</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div>
                        <button type="submit" class="btn btn-success w-100" (click)="prikazi()">Prikaži</button>
                    </div>

                    <div style="margin-top: 15px;">
                        <button type="submit" class="btn btn-secondary w-100" (click)="ispis()">Ispis</button>
                    </div>

                    <div style="margin: 15px 0; font-size: 1.1rem; font-weight: bold;">
                        Ukupan promet: {{srvKpi.decPromet | number:'1.2-2'}}
                    </div>

                </form>

            </div>

        </div>

        <div class="col d-col" id="kpiDcol">

            <div id="stavScroll" class="stav-scroll scroll-print">

                <div class="row" style="border-bottom: solid 0.5px grey; border-top: solid 0.5px grey; margin-right: 20px;">
                    <div class="col-8">
                        Naziv partnera
                    </div>
                    <div class="col" style="text-align: right;">
                        Promet
                    </div>
                </div>

                <div *ngFor="let x of srvKpi.objVwKpi">

                    <div class="row" style="margin-right: 20px;">
                        <div class="col-8">
                            {{x.Naziv}}
                        </div>
                        <div class="col" style="text-align: right;">
                            {{(x.Duguje*1 + x.Potrazuje*1) | number:'1.2-2'}}
                        </div>
                    </div>

                </div>

                <div class="row" style="border-bottom: solid 0.5px grey; border-top: solid 0.5px grey; margin-right: 20px;">
                    <div class="col-8">
                        Ukupno:
                    </div>
                    <div class="col" style="text-align: right;">
                        {{srvKpi.decPromet | number:'1.2-2'}}
                    </div>
                </div>

            </div>

        </div>

    </div>

</div>
<div class="container">

    <div>
        <div style="padding: 15px 0;">
            <mat-form-field style="width: 100%;">
                <mat-label>Pretraga</mat-label>
                <button matPrefix mat-icon-button aria-label="Search">
                    <mat-icon>search</mat-icon>
                </button>
                <input autocomplete="off" type="text" aria-label="strPretaga" matInput [(ngModel)]="strPretaga"
                    (ngModelChange)="filterArtikli()" name="strPretaga" style="text-align: start; font-weight: bold;"
                    #artNaziv>
                <button *ngIf="strPretaga" matSuffix mat-icon-button aria-label="Clear"
                    (click)="strPretaga=''; filterArtikli()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div>
            <button type="button" class="btn btn-success w-100" (click)="noviArtikl()"><i class="fa fa-plus" aria-hidden="true"></i> Novi artikl</button>
        </div>

        <div *ngFor="let x of fltArtikli" class="row-artikl" (click)="urediArtikl(x)">

            <div class="row">
                <div class="col">
                    {{x.artNaziv}}
                </div>
            </div>

        </div>

        <div>
            <div *ngIf="fltArtikli && !fltArtikli.length" class="alert alert-success" style="text-align: center;">
                nema stavki za prikaz
            </div>
        </div>

    </div>



</div>
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppService } from '../app.service';
import { KpiFilter, KpiService, VwKpi } from '../kpi.service';

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.css']
})
export class KpiComponent implements OnInit {

  constructor(
    public appService: AppService,
    public srvKpi: KpiService
  ) { }

  ngOnInit(): void {
    console.log('kpi init');
    this.srvKpi.napraviFrmKpiFilter(null, (val: KpiFilter) => {
      console.log(val);
      this.ucitajKpi();
    });
    
  }

  //Učitavanje podataka
  ucitajKpi() {

    /* let korID: number = JSON.parse(localStorage.getItem('korID'));

    let obj0: KpiFilter = {
      Podaci_ID: korID,
      DatumOd: moment('2021-01-01'),
      DatumDo: moment('2021-12-31'),
      intKupDob: 1
    }
 */
    this.srvKpi.prometPoPartnerima(this.srvKpi.frmKpiFilter.value, (rez: VwKpi[]) => {
      this.srvKpi.objVwKpi = rez;
      this.srvKpi.ukupnoPromet();
      console.log(rez);
    });

  }

  //prikaži podatke
  prikazi() {
    this.srvKpi.prometPoPartnerima(this.srvKpi.frmKpiFilter.value, (rez: VwKpi[]) => {
      this.srvKpi.objVwKpi = rez;
      this.srvKpi.ukupnoPromet();
      console.log(rez);
    });
  }

  //Ispis
  ispis() {
    print();
  }

}

<div class="container" style="overflow: hidden;">

    <div id="top" class="card" style="margin-top: 5px;">

        <div class="alert alert-info" style="text-align: center; background-color: #7797a7; color: #d5f9ff; padding: 2px; margin-bottom: 2px;">

            <div class="row">
                <div class="col d-flex align-items-center">
                    <button type="button" class="btn btn-info" routerLink="/klijenti-ir"><fa-icon [icon]="srvFa.faArrowAltCircleLeft"></fa-icon> POVRATAK</button>
                </div>
                <div class="col">
                    <div>
                        {{vPosNaplUred.np[0].npVrstaDok + ' ' + rnSel.rnBroj + '/' + vPosNaplUred.posOzn + '/' + vPosNaplUred.np[0].npuNaplUr}}
                    </div>
                    <div>
                        {{(rnSel.rnDatum | amDateFormat:'DD.MM.YYYY.') + ' ' + rnSel.naziv}}
                    </div>
                </div>
            </div>
            
            
            
        </div>



        <div class="collapse" [ngClass]="{show: intUred}">
            <div class="menu-item" style="margin-top: 5px;">
                <div style="padding: 0 5px;">
                    <app-klijenti-ir-stav-ured #KlijentiIrStavUredComponent></app-klijenti-ir-stav-ured>
                </div>

                <div *ngIf="bolBrisanje" class="row">
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn btn-danger" style="width: 100%; background-color: #ea8892;"
                        (click)="obrisiStavku(chlStavkaUred.frmStavka.get('rsID').value)">
                        <fa-icon [icon]="srvFa.faMinus"></fa-icon> Potvrdi brisanje
                    </button>
                    </div>
                    <div class="col col-6" style="padding: 2px;">
                        <button type="button" class="btn btn-secondary" style="width: 100%;" (click)="bolBrisanje = !bolBrisanje">
                            <fa-icon [icon]="srvFa.faTimes"></fa-icon> Otkaži
                        </button>
                    </div>
                </div>

                <div *ngIf="!bolBrisanje" class="row" style="margin: 0; margin-top: 5px;">
                    <div class="col-md col-btns-edit" *ngIf="chlStavkaUred.frmStavka">
                        <button type="submit" class="btn btn-success" style="width: 100%;" (click)="spremiStavku()">
                            <fa-icon *ngIf="chlStavkaUred.frmStavka.get('rsID').value" [icon]="srvFa.faSave"></fa-icon>
                            <fa-icon *ngIf="!chlStavkaUred.frmStavka.get('rsID').value" [icon]="srvFa.faFileSignature">
                            </fa-icon>
                            {{chlStavkaUred.frmStavka.get('rsID').value ? 'Spremi promjene' : 'Spremi'}}
                        </button>
                    </div>
                    <div *ngIf="chlStavkaUred.frmStavka && chlStavkaUred.frmStavka.get('rsID').value" class="col-md col-btns-edit">
                        <button type="button" class="btn btn-secondary" style="width: 100%;" (click)="ponistiUnos()">
                            <fa-icon [icon]="srvFa.faTimes"></fa-icon> PONIŠTI
                        </button>
                    </div>
                    <div class="col-md col-btns-edit">
                        <button *ngIf="chlStavkaUred.frmStavka" type="button" class="btn btn-secondary"
                            style="width: 100%;" (click)="urediArtikl()">
                            <fa-icon *ngIf="!chlStavkaUred.frmStavka.get('rsArtiklID').value || !chlStavkaUred.frmStavka.get('artNaziv').valid" [icon]="srvFa.faPlus"></fa-icon><fa-icon *ngIf="chlStavkaUred.frmStavka.valid && chlStavkaUred.frmStavka.get('rsArtiklID').value"  [icon]="srvFa.faEditReg"></fa-icon> {{chlStavkaUred.frmStavka.get('rsArtiklID').value
                            && chlStavkaUred.frmStavka.valid ? 'Uredi artikl' : 'Novi artikl'}}
                        </button>
                    </div>
                    <div class="col-md col-btns-edit">
                        <button *ngIf="chlStavkaUred.frmStavka && chlStavkaUred.frmStavka.get('rsID').value" type="button" class="btn btn-danger" style="width: 100%; background-color: #ea8892;"
                            (click)="bolBrisanje = !bolBrisanje">
                            <fa-icon [icon]="srvFa.faMinus"></fa-icon> Obriši stavku
                        </button>
                    </div>
                </div>
            </div>

        </div>

        <div class="card-footer" style="margin-top: 5px; cursor: pointer; background-color: #a2d276a6;">

            <div class="row">
                <div class="col col-12 d-flex align-items-center justify-content-center" (click)="intUred = !intUred">
                    <div class="d-flex flex-inline justify-content-center align-items-center">
                        <div>
                            {{chlStavkaUred.frmStavka && chlStavkaUred.frmStavka.get('rsID').value ? 'UREĐIVANJE' :
                            'NOVI UNOS'}}
                        </div>
                        <div class="d-flex align-items-center">
                            <i *ngIf="!intUred" class="material-icons" style="color: #141313de;">expand_more</i>
                            <i *ngIf="intUred" class="material-icons"
                                style="color: #1a1818de; transform: rotate(90deg)">expand_more</i>
                        </div>

                    </div>
                </div>
                <!-- <div class="col d-flex align-items-center justify-content-center" routerLink="/klijenti-ir">
                    
                    <button type="button" class="btn btn-info"><fa-icon [icon]="srvFa.faArrowAltCircleLeft"></fa-icon> POVRATAK NA RAČUNE</button>

                </div> -->
            </div>




        </div>

    </div>

    <div *ngIf="!objStavke" class="alert alert-success" style="text-align: center; margin-top: 5px;">
        nema stavki
    </div>

    <div class="card" style="margin-top: 5px; cursor: pointer;"
        [ngStyle]="{'background-color': chlStavkaUred.frmStavka && chlStavkaUred.frmStavka.get('rsID').value == x.rsID ? '#e0ece7' : '#ffffff'}"
        *ngFor="let x of objStavke" (click)="odaberiStavku(x);">

        <div class="row" style="margin: 0px">

            <div class="col col-9 d-flex flex-column">
                <div style="width: 100%; font-size: 1.2em; font-weight: bold; color: #508070; white-space: pre-line;">
                    {{x.artNaziv}}
                </div>
                <div class="d-flex flex-column">
                    <div>
                        {{(x.kol | number: '1.2-2':'en-HR') + ' ' + x.rsJedMj + ' x ' + (x.cijena | number:
                        '1.2-2':'en-HR') + ' kn = ' + (x.iznos | number: '1.2-2':'en-HR') + ' kn'}}
                    </div>
                    <div class="d-flex flex-wrap">
                        <div style="margin-right: 10px;">
                            {{'- rabat ' + (x.rabatIznos | number: '1.2-2':'en-HR')}}
                        </div>
                        <div>
                            {{'+ PDV: ' + (x.pdvIznos | number: '1.2-2':'en-HR')}}
                        </div>
                    </div>
                    <div style="font-weight: bold;">
                        {{'Ukupno: ' + (x.ukupno | number: '1.2-2':'en-HR') + ' kn'}}
                    </div>

                </div>
            </div>
            <div class="col col-3">

            </div>

        </div>

    </div>

</div>
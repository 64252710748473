import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { FontawesomeIconsService } from '../fontawesome-icons.service';
import { KlijentiIrService, RnNapomenaPred } from '../klijenti-ir.service';

@Component({
  selector: 'app-ir-napomene-predlozak',
  templateUrl: './ir-napomene-predlozak.component.html',
  styleUrls: ['./ir-napomene-predlozak.component.css']
})
export class IrNapomenePredlozakComponent implements OnInit {

  constructor(
    public appService: AppService,
    public srvFa: FontawesomeIconsService,
    public srvKlijentiIr: KlijentiIrService
  ) { }

  podaciID: number;
  
  intOdabir: boolean = false;
  intEdit: boolean = false;

  ngOnInit(): void {

    this.podaciID = JSON.parse(localStorage.getItem('korID'));

    this.ucitajPredloske(this.podaciID);

  }

  //Učitavnje predložaka
  ucitajPredloske(podaciID: number) {
    this.srvKlijentiIr.napomenePred(podaciID, (rez: RnNapomenaPred[]) => {
      if (rez) {
        this.srvKlijentiIr.objPredlosci = rez;
        this.srvKlijentiIr.objPredlosci.forEach(val => {
          val['sel'] = false;
        });
        console.log(this.srvKlijentiIr.objPredlosci);
      } else {
        //this.appService.msgBox(2, "Greška prilikom dohvaćanja podataka!", 3000);
      }

    });
  }

  //Provjera jel ima šta označeno
  intOdbirCheck() {
    let odTmp: boolean = false;
    this.srvKlijentiIr.objPredlosci.forEach(val => {
      if (val['sel'] == true) {
        odTmp = true;
      }
    });
    this.intOdabir = odTmp;
  }

  //Provjera uređivanja
  intEditCheck() {
    let odTmp: boolean = false;
    this.srvKlijentiIr.objPredlosci.forEach(val => {
      if (val['edit'] == true) {
        odTmp = true;
      }
    });
    this.intEdit = odTmp;
  }

  //Označi napomenu
  napomenaCheck(x: RnNapomenaPred) {
    this.intOdbirCheck();
  }

  //Spremi promjene
  spremiPredloske() {
    this.srvKlijentiIr.spremiPredloske(this.srvKlijentiIr.objPredlosci, (rez: RnNapomenaPred[]) => {

      if (rez) {
        //this.objPredlosci = rez;
        this.intEdit = false;
        this.srvKlijentiIr.objPredlosci.forEach(val => {
          val['edit'] = false;
        });
        this.appService.msgBox(1, "Spremljeno!", 2000);
      } else {
        this.srvKlijentiIr.objPredlosci.forEach(val => {
          val.rpTekst = val['text2'];
        });
        this.appService.msgBox(2, "Greška prilikom spremanja!", 4000);
      }


    });
  }

  //Potvrdi odabir
  potvrdiOdabir() {

    let objTmp = JSON.parse(localStorage.getItem('objRnSel'));
    let rnID: number = objTmp.rnID;

    this.srvKlijentiIr.potvrdiOdabir(this.srvKlijentiIr.objPredlosci, rnID, (rez) => {
      if (rez) {

        document.getElementById('btnMdlIrNapPred').click();

        this.srvKlijentiIr.objIrNapomene.next(rez);

        this.srvKlijentiIr.objPredlosci.forEach(val => {
          val['sel'] = false;
        });
        this.intOdabir = false;

        this.appService.msgBox(1, "Spremljeno", 2000);

      } else {
        this.appService.msgBox(2, "Greška prilikom unosa!", 3000);
      }
    });

  }

}

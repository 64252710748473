import { Injectable } from '@angular/core';
import { faCoffee, faSave,faTimes, faPlus, faMinus, faPencilAlt, faFileAlt, faPrint, faSquare, faCheckSquare, faSquareFull, faFileSignature, faHouseUser } from '@fortawesome/free-solid-svg-icons';
import { faSquare as faSqureReg, faCheckSquare as faCheckSquareReg, faEdit as faEditReg, faFile, faArrowAltCircleLeft, faIdCard } from '@fortawesome/free-regular-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class FontawesomeIconsService {

  constructor() { }

  faCoffee = faCoffee; //šalica
  faSave = faSave; //disketa
  faTimes = faTimes; // X
  faPlus = faPlus; // +
  faMinus = faMinus; // -
  faPencilAlt = faPencilAlt; //edit
  faFileAlt = faFileAlt; //stavke
  faPrint = faPrint; //print
  faSquare = faSquare; // chk Empty
  faCheckSquare = faCheckSquare; // chk Checked
  faSqureReg = faSqureReg; // Ckeckbox empty
  faCheckSquareReg = faCheckSquareReg; // checkbox checked
  faEditReg = faEditReg; // edit - square
  faFile = faFile;
  faFileSignature = faFileSignature; // upis na listu
  faArrowAltCircleLeft = faArrowAltCircleLeft; // back circle
  faIdCard = faIdCard;
  faHouseUser = faHouseUser; // Home user

}

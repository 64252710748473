<form *ngIf="frmStavka" [formGroup]="frmStavka" style="overflow: hidden;">

    <div>
        <mat-form-field style="width: 100%;">
            <mat-label>Artikl</mat-label>
            <textarea id="artNaziv" type="text" style="font-weight: bold;" placeholder="Upišite dio teksta iz opisa" aria-label="opis robe / usluge" matInput formControlName="artNaziv" [matAutocomplete]="auto" (keyup)="filterArtikli(frmStavka.get('artNaziv').value)"
                #artNaziv></textarea>
            <!-- <button matSuffix type="button" class="btn btn-primary" style="margin-left: 5px;">Uredi artikl</button> -->
            <!-- <mat-icon matSuffix style="font-size: 1.5em;">mode_edit</mat-icon> -->
            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
                <mat-option style="font-weight: bold;" *ngFor="let x of fltArtikli" [value]="x.artNaziv" (onSelectionChange)="postaviArtikl($event, x)">
                    {{x.artNaziv}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="frmStavka.get('artNaziv').errors?.required">Polje je obavezno za unos</mat-error>
            <mat-error *ngIf="frmStavka.get('artNaziv').errors?.mustMatch">Odaberite artikl s liste</mat-error>
        </mat-form-field>

        <mat-form-field style="width: 30%;">
            <mat-label>Količina</mat-label>
            <input autocomplete="off" type="tel" aria-label="kol" matInput formControlName="kol" (keyup)="izracun()" (blur)="decFormat($event)" style="text-align: center; font-weight: bold;" #kol>
            <mat-error *ngIf="frmStavka.get('kol').errors?.required">Polje je obavezno za unos</mat-error>
            <mat-error *ngIf="frmStavka.get('kol').errors?.err">{{frmStavka.get('kol').errors.err}}</mat-error>
        </mat-form-field>

        <mat-form-field style="width: 30%;">
            <mat-label>Cijena</mat-label>
            <input autocomplete="off" type="tel" aria-label="cijena" matInput formControlName="cijena" (keyup)="izracun()" (blur)="decFormat($event)" style="text-align: center; font-weight: bold;" #cijena>
            <mat-error *ngIf="frmStavka.get('cijena').errors?.required">Polje je obavezno za unos</mat-error>
            <mat-error *ngIf="frmStavka.get('cijena').errors?.err">{{frmStavka.get('cijena').errors.err}}</mat-error>
        </mat-form-field>

        <mat-form-field style="width: 30%;">
            <mat-label>Rabat %</mat-label>
            <input autocomplete="off" type="tel" aria-label="rabat" (keyup)="izracun()" (blur)="decFormat($event)" matInput formControlName="rabat" style="text-align: center; font-weight: bold;" #rabat>
            <mat-error *ngIf="frmStavka.get('rabat').errors?.required">Polje je obavezno za unos</mat-error>
            <mat-error *ngIf="frmStavka.get('rabat').errors?.err">{{frmStavka.get('rabat').errors.err}}</mat-error>
        </mat-form-field>
    </div>

    <div style="background-color: #ffffff; padding: 3px 0px;">
        <div class="row" style="margin: 0px;">
            <div class="col-6 col-md-3">
                <div style="color: #2f2f46de;">
                    Iznos
                </div>
                <div style="text-align: center; font-weight: bold;">
                    {{frmStavka.get('iznos').value | number:'1.2-2':'en-HR'}}
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div style="color: #2f2f46de;">
                    Rabat iznos
                </div>
                <div style="text-align: center; font-weight: bold;">
                    {{frmStavka.get('rabatIznos').value | number:'1.2-2':'en-HR'}}
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div style="color: #2f2f46de;">
                    Pov. nakn.
                </div>
                <div style="text-align: center; font-weight: bold;">
                    {{frmStavka.get('povNak').value | number:'1.2-2':'en-HR'}}
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div style="color: #2f2f46de;">
                    Pov. iznos
                </div>
                <div style="text-align: center; font-weight: bold;">
                    {{frmStavka.get('povNakIznos').value | number:'1.2-2':'en-HR'}}
                </div>
            </div>
        </div>
        <div class="row" style="margin: 0px;">
            <div class="col-6 col-md-3">
                <div style="color: #2f2f46de;">
                    Osnovica
                </div>
                <div style="text-align: center; font-weight: bold;">
                    {{frmStavka.get('osnovica').value | number:'1.2-2':'en-HR'}}
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div style="color: #2f2f46de;">
                    PDV %
                </div>
                <div style="text-align: center; font-weight: bold;">
                    {{frmStavka.get('pdvStopa').value | number:'1.2-2':'en-HR'}}
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div style="color: #2f2f46de;">
                    PDV iznos
                </div>
                <div style="text-align: center; font-weight: bold;">
                    {{frmStavka.get('pdvIznos').value | number:'1.2-2':'en-HR'}}
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div style="color: #2f2f46de;">
                    Ukupno
                </div>
                <div style="text-align: center; font-weight: bold;">
                    {{frmStavka.get('ukupno').value | number:'1.2-2':'en-HR'}}
                </div>
            </div>
        </div>
    </div>



</form>
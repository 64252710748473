import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-postavke',
  templateUrl: './postavke.component.html',
  styleUrls: ['./postavke.component.css']
})
export class PostavkeComponent implements OnInit {

  constructor(
    public srvLogin: LoginService
  ) { }

  ngOnInit() {
    this.srvLogin.autorizirajStranicu(()=>{
      
    });
  }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';
import * as moment from 'moment';

export interface Racun {
  RacunID: number, 
  DokumentID: number, 
  URIR: number, 
  BrRn: number, 
  rObvezaID: number, 
  DatumKnj: moment.Moment, 
  DatumRn: moment.Moment, 
  DVO: moment.Moment, 
  Valuta: moment.Moment, 
  Model: string, 
  PozivNaBr: string, 
  NacinPlacanja: number, 
  Neoporezivo: number, 
  Osnovica: number, 
  PDV: number, 
  Duguje: number, 
  Potrazuje: number, 
  Napomena: number, 
  Obrocno: number, 
  ObrocnoID: number, 
  ZrrnID: number, 
  SvrhaDoznake: number, 
  PDV_10: number, 
  PDV_10_iznos: number, 
  Korisnik_ID: number, 
  Podaci_ID: number, 
  PDV_10_moze: number, 
  PDV_10_ne_moze: number, 
  PDV_22_moze: number, 
  PDV_22_ne_moze: number, 
  PDV_nula: number, 
  PDV_stopa: number, 
  PDV_10_Ukupno: number, 
  Ne_Moze_Postotak: number, 
  Porez_PP_Iznos: number, 
  Veza_placanje_ID: number, 
  Veza_RN_ID: number, 
  Proknjizeno: number, 
  Clanak_22: number, 
  KPI_DA_NE: number, 
  Red_br_KPI: number, 
  PDV_5_osnovica: number, 
  PDV_5_PDV: number, 
  PDV_5_moze: number, 
  PDV_5_ne_moze: number, 
  PDV_5_ukupno: number, 
  Regija_int: number, 
  Roba_Usluga_int: number, 
  Stopa_pdv_5: number, 
  Stopa_pdv_10: number, 
  Vrsta_troska: number, 
  PDV_EU: number
}

export interface RacunUpit {
  vrstaR: string;
  dat1: string, //moment.Moment;
  dat2: string, //moment.Moment;
  uvjetPr: string;
  upitR: string;
}

@Injectable({
  providedIn: 'root'
})

export class RacuniService {

  ucitavanje: boolean = false;

  constructor(
    private http: HttpClient,
    public appService: AppService
    ) { }

  rsDataSource;
  objPlacanja;

  objSaldaKonti;
  objDonosSalda;
  dDuguje;
  dPotrazuje;
  objSaldo
  sDuguje;
  sPotrazuje;
  sSaldo;

  ucitajRacune(rnUpit: RacunUpit) {

    this.ucitavanje = true;

    //alert("https://sharprobotics.synology.me/sharpAccounting/php/racuni.php?podaciID=" + localStorage.korID + "&vrsta=" + rnUpit.vrstaR + "&datumOD=" + rnUpit.dat1 + "&datumDO=" + rnUpit.dat2 + "&uvjet=" + rnUpit.uvjetPr + "&upit=" + rnUpit.upitR);

    this.http.get(this.appService.webUrl + "php/racuni.php?podaciID=" + localStorage.korID + "&vrsta=" + rnUpit.vrstaR + "&datumOD=" + rnUpit.dat1 + "&datumDO=" + rnUpit.dat2 + "&uvjet=" + rnUpit.uvjetPr + "&upit=" + rnUpit.upitR).subscribe(rez => {
      this.rsDataSource = rez;
      this.ucitavanje = false;
    });

  }

}

<div class="container" style="font-size: 1.2rem;">


    <form *ngIf="frmRacun" [formGroup]="frmRacun" style="padding-bottom: 15px;">

        <div class="alert alert-info" style="text-align: center; margin-bottom: 5px;">
            {{objRnSel ? objPoslNp.np[0].npVrstaDok + ' br. ' + objRnSel.rnBroj + ' od ' + (objRnSel.rnDatum | amDateFormat:'DD.MM.YYYY.') + ' / ' + objRnSel.naziv : 'Novi račun'}}
        </div>

        <div>

            <div class="card inline" style="width: 100%; padding: 5px; background-color: #f2f2fd; margin-bottom: 2px;">
                <h6>Kupac</h6>
                <mat-form-field>
                    <mat-label>Kupac</mat-label>
                    <input type="text" id="rnKlijentNaziv" placeholder="Upišite par slova iz naziva" aria-label="Kupac" matInput formControlName="rnKlijentNaziv" [matAutocomplete]="auto" (keyup)="filterRnPartneri(frmRacun.get('rnKlijentNaziv').value)" #rnKlijentNaziv>
                    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
                        <mat-option *ngFor="let x of fltPartneri" [value]="x.naziv" (onSelectionChange)="postaviKupca($event, x.parID, x.naziv)">
                            {{x.naziv}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="frmRacun.get('rnKlijentNaziv').errors?.required">Polje je obavezno za unos</mat-error>
                    <mat-error *ngIf="frmRacun.get('rnKlijentNaziv').errors?.mustMatch">Odaberite kupca s liste</mat-error>
                </mat-form-field>
                <!-- <div class="inline">
                    <span>Đakovo </span>
                    <span>OIB: 89209470523 </span>
                </div> -->
            </div>

            <button type="button" class="btn btn-secondary" style="width: 100%;" (click)="dlgPartner()"><fa-icon [icon]="srvFa.faIdCard"></fa-icon> Novi partner</button>


            <div *ngIf="!objRnSel || (objRnSel && !objRnSel.rnJIR)" class="card" style="width: 100%; padding: 5px; margin-top: 5px; background-color: #f2f2fd;">
                <h6>Datum i vrijeme izdavanja</h6>
                <div class="d-flex flex-wrap" style="margin-top: 5px;">
                    <mat-form-field style="width: 150px; margin-right: 15px;">
                        <input matInput formControlName="rnDatum" [matDatepicker]="dpDatumRn" placeholder="Datum" style="text-align: center;">
                        <mat-error *ngIf="frmRacun.get('rnDatum').errors?.required">Polje je obavezno za unos</mat-error>
                        <mat-error *ngIf="frmRacun.get('rnDatum').errors?.err">{{frmRacun.get('rnDatum').errors.err}}</mat-error>
                        <mat-datepicker-toggle matSuffix [for]="dpDatumRn"></mat-datepicker-toggle>
                        <mat-datepicker #dpDatumRn></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field style="width: 80px; margin-right: 15px;">
                        <mat-label>Sati</mat-label>
                        <input matInput type="text" formControlName="rnHH" placeholder="Sati" style="text-align: center;" required>
                        <mat-error *ngIf="frmRacun.get('rnHH').errors?.err">{{frmRacun.get('rnHH').errors.err}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field style="width: 80px; margin-right: 15px;">
                        <mat-label>Minuta</mat-label>
                        <input matInput type="text" formControlName="rnMM" placeholder="Minuta" style="text-align: center;" required>
                        <mat-error *ngIf="frmRacun.get('rnMM').errors?.err">{{frmRacun.get('rnMM').errors.err}}
                        </mat-error>
                    </mat-form-field>
                </div>




                <div class="d-flex flex-wrap" style="margin-top: 5px;">
                    <div>
                        <h6>Rok plaćanja</h6>
                        <mat-form-field style="width: 150px; margin-right: 15px;">
                            <input matInput formControlName="rnDatumVal" [matDatepicker]="dpDatumVal" placeholder="Datum valute" style="text-align: center;">
                            <mat-error *ngIf="frmRacun.get('rnDatumVal').errors?.required">Polje je obavezno za unos</mat-error>
                            <mat-error *ngIf="frmRacun.get('rnDatumVal').errors?.err">{{frmRacun.get('rnDatum').errors.err}}</mat-error>
                            <mat-datepicker-toggle matSuffix [for]="dpDatumVal"></mat-datepicker-toggle>
                            <mat-datepicker #dpDatumVal></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div>
                        <h6>Datum isporuke</h6>
                        <mat-form-field style="width: 150px; margin-right: 15px;">
                            <input matInput formControlName="rnDatumDVO" [matDatepicker]="dpDatumDVO" placeholder="Datum isporuke" style="text-align: center;">
                            <mat-error *ngIf="frmRacun.get('rnDatumDVO').errors?.required">Polje je obavezno za unos</mat-error>
                            <mat-error *ngIf="frmRacun.get('rnDatumDVO').errors?.err">{{frmRacun.get('rnDatum').errors.err}}</mat-error>
                            <mat-datepicker-toggle matSuffix [for]="dpDatumDVO"></mat-datepicker-toggle>
                            <mat-datepicker #dpDatumDVO></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

                <div>
                    <div>
                        <h6>Način plaćanja</h6>
                        <mat-form-field style="width: 250px;">
                            <mat-label>Način plaćanja</mat-label>
                            <mat-select formControlName="rnPlacanjeID" [compareWith]="compareCategoryObjects">
                                <mat-option *ngFor="let x of appService.nPlacanja" [value]="x.id">
                                    {{x.opis}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

            </div>

            <div class="card" style="background-color: #f2f2fd;">
                <div class="d-flex flex-wrap">
                    <button class="btn btn-success" type="submit" style="width: 50%;" (click)="spremiRacun()"><fa-icon [icon]="srvFa.faSave"></fa-icon> Spremi</button>
                    <button class="btn btn-light" style="width: 50%;" routerLink="/klijenti-ir"><fa-icon [icon]="srvFa.faArrowAltCircleLeft"></fa-icon> Povratak</button>
                </div>

            </div>

        </div>

    </form>



</div>
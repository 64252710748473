import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppService } from '../app.service';
import { KlijentiIrService, RacunIr, VracunIR } from '../klijenti-ir.service';
import { KlijentiPartneriService, Partner } from '../klijenti-partneri.service';
import { Podaci, VposNaplUred } from '../klijenti.service';
import * as validatori from '../validatori';
import { MustMatch } from '../validatori';
import { naciniPlacanja } from '../app.service';
import { Router } from '@angular/router';
import { KlijentiPartneriUredComponent } from '../klijenti-partneri-ured/klijenti-partneri-ured.component';
import { FontawesomeIconsService } from '../fontawesome-icons.service';

@Component({
  selector: 'app-klijenti-ir-uredjivanje',
  templateUrl: './klijenti-ir-uredjivanje.component.html',
  styleUrls: ['./klijenti-ir-uredjivanje.component.css']
})
export class KlijentiIrUredjivanjeComponent implements OnInit, OnDestroy {

  constructor(
    public srvFa: FontawesomeIconsService,
    private http: HttpClient,
    private frmBuilder: FormBuilder,
    public srvKlijentiPartneri: KlijentiPartneriService,
    public appService: AppService,
    private router: Router,
    private srvKlijentiIr: KlijentiIrService
  ) { }

  @ViewChild('partner') partner: KlijentiPartneriUredComponent;

  objPartneri: Partner[] = [];
  fltPartneri: Partner[] = [];
  frmRacun: FormGroup;

  objRnSel: VracunIR;
  objPoslNp: VposNaplUred;

  strRedirect: string;

  compareCategoryObjects(obj1: any, obj2: any): boolean {
    return (obj1 == obj2);
  }

  ngOnInit() {

    if (localStorage.getItem('objPoslNpSel')) {
      this.objPoslNp = JSON.parse(localStorage.getItem('objPoslNpSel'));
    }

    if (localStorage.getItem('objRnSel')) {

      this.objRnSel = JSON.parse(localStorage.getItem('objRnSel'));

    }

    //Observable frmRačun
    this.srvKlijentiIr.objPartner$.subscribe(data => {

      this.frmRacun.get('rnKupacID').setValue(Number(data.parID));
      this.frmRacun.get('rnKlijentNaziv').setValue(data.naziv);
      this.frmRacun.get('rnKlijentNazivChk').setValue(data.naziv);

      this.srvKlijentiPartneri.partneriSelectAll((rez: Partner[]) => {
        this.objPartneri = rez;
      });

    });

    //objPartneri select all
    this.srvKlijentiPartneri.partneriSelectAll((rez: Partner[]) => {

      this.objPartneri = rez;

      this.napraviFrmRacun(() => {

        if (this.objRnSel) {

          this.frmRacun.get('rnID').setValue(this.objRnSel.rnID);
          //rnKlijentID: [parseInt(localStorage.getItem('korID'))],
          this.frmRacun.get('rnKupacID').setValue(this.objRnSel.rnKupacID);
          this.frmRacun.get('rnKlijentNaziv').setValue(this.objRnSel.naziv);
          this.frmRacun.get('rnKlijentNazivChk').setValue(this.objRnSel.naziv);
          this.frmRacun.get('rnKlijentOIB').setValue(this.objRnSel.oib);
          this.frmRacun.get('rnNaplUrID').setValue(this.objPoslNp.np[0].npuID);
          this.frmRacun.get('rnBroj').setValue(this.objRnSel.rnBroj);
          this.frmRacun.get('rnDatum').setValue(moment(this.objRnSel.rnDatum).toDate());
          this.frmRacun.get('rnHH').setValue(moment(this.objRnSel.rnDatum).format('HH'));
          this.frmRacun.get('rnMM').setValue(moment(this.objRnSel.rnDatum).format('mm'));
          this.frmRacun.get('rnSS').setValue(moment(this.objRnSel.rnDatum).format('ss'));
          if (this.objRnSel.rnDatumVal) {
            this.frmRacun.get('rnDatumVal').setValue(moment(this.objRnSel.rnDatumVal).toDate());
          } else {
            this.frmRacun.get('rnDatumVal').setValue(null);
          }
          if (this.objRnSel.rnDatumDVO) {
            this.frmRacun.get('rnDatumDVO').setValue(moment(this.objRnSel.rnDatumDVO).toDate());
          } else {
            this.frmRacun.get('rnDatumDVO').setValue(null);
          }
          this.frmRacun.get('rnPlacanjeID').setValue(this.objRnSel.rnPlacanjeID);
          //this.frmRacun.get('rnOperaterID').setValue(parseInt(localStorage.getItem('operaterID')));

          this.strRedirect = '/klijenti-ir';


        } else {
          this.frmRacun.get('rnNaplUrID').setValue(this.objPoslNp.np[0].npuID);
          this.strRedirect = '/klijenti-ir-stavke';
        }

        setTimeout(() => {
          console.log('time out');
          document.getElementById('rnKlijentNaziv').focus();
        }, 500);

      });
    });

  }

  napraviFrmRacun(callback) {


    let plTmp: number = this.appService.getPlacanjeIdByOzn(this.objPoslNp.np[0].npNplacanja)

    this.frmRacun = this.frmBuilder.group({
      rnID: [0],
      rnKlijentID: [parseInt(localStorage.getItem('korID'))], //Odnosi se na klijenta
      rnKlijentNaziv: ['', [Validators.required]], //Odnosi se na kupca
      rnKlijentNazivChk: [], //Odnosi se na kupca
      rnKlijentOIB: [],
      rnNaplUrID: [0],
      rnKupacID: [0],
      rnBroj: [0],
      rnDatum: [moment().toDate(), [Validators.required]],
      rnHH: [moment().format('HH'), [validatori.jelBroj, validatori.maxH]],
      rnMM: [moment().format('mm'), [validatori.jelBroj, validatori.maxMin]],
      rnSS: [moment().format('ss')],
      rnDatumVal: [moment().toDate()],
      rnDatumDVO: [moment().toDate()],
      rnPlacanjeID: [plTmp],
      rnOperaterID: [parseInt(localStorage.getItem('operaterID'))]
    }, { validator: MustMatch('rnKlijentNaziv', 'rnKlijentNazivChk') } as AbstractControlOptions);

    callback();

  }

  //Postavljanje podataka o kupcu u frmRacun
  postaviKupca(event, id: number, naziv: string) {
    if (event.source.selected) {
      this.frmRacun.get('rnKupacID').setValue(id);
      this.frmRacun.get('rnKlijentNazivChk').setValue(naziv);
    }
  }

  //Filtriranje partnera
  filterRnPartneri(strP) {

    this.fltPartneri = this.srvKlijentiPartneri.filterPartneri(this.objPartneri, strP);

  }

  //Unos / Spremanje računa
  spremiRacun() {

    this.frmRacun.markAllAsTouched();

    if (this.frmRacun.valid) {

      let obj0 = this.frmRacun.value;

      obj0.rnDatum = moment(this.frmRacun.get('rnDatum',).value).format('YYYY-MM-DD') + ' ' + this.frmRacun.get('rnHH',).value + ':' + this.frmRacun.get('rnMM',).value + ':00';
      if (obj0.rnDatumVal) {
        obj0.rnDatumVal = moment(this.frmRacun.get('rnDatumVal',).value).format('YYYY-MM-DD');
      }
      
      if (obj0.rnDatumDVO) {
        obj0.rnDatumDVO = moment(this.frmRacun.get('rnDatumDVO',).value).format('YYYY-MM-DD');
      }

      let url: string = this.appService.webUrl + "php/racuni_ir.php?funk=unosUred";
      let obj: string = JSON.stringify(obj0);

      console.log(obj0);

      this.http.post(url, obj).subscribe(rez => {
        if (!rez['err']) {
          this.objRnSel = rez['vRacun'];
          localStorage.setItem('objRnSel',JSON.stringify(this.objRnSel));
          this.router.navigate([this.strRedirect], { replaceUrl: true });
          this.appService.msgBox(1, "Uspješno spremljeno!", 2000);
        } else {
          this.appService.msgBox(2, rez['err'], 2000);
        }
      });


    } else {
      this.appService.msgBox(2, "Provjerite ispravnost upisanih polja", 3000);
    }

  }

  //Dialog - Partner
  dlgPartner() {

    document.getElementById('btnMdlPartner').click();

    setTimeout(() => {
      /* this.partner.napraviFrmPartner(() => {}); */
      if (document.getElementById('oib')) {
        document.getElementById('oib').focus();
      }
    }, 500);
    
  }

  ngOnDestroy() {
    console.log('part ured destroy');
    //this.frmPartner = null;
  }

}

<form *ngIf="frmPartner" [formGroup]="frmPartner">

    <mat-form-field *ngIf="bolOibDaNe || srvKlPartneri.objPartTmp" style="width: 100%;">
        <input type="tel" autocomplete="off" id="oib" matInput formControlName="oib" placeholder="oib" (keyup)="oibValid()" style="text-align: center;" #oib>
        <mat-hint style="width: 100%; text-align: end;">{{frmPartner.get('oib').value}}</mat-hint>
        <mat-error *ngIf="frmPartner.get('oib').errors?.required">Polje je obavezno za unos</mat-error>
        <!-- <mat-error *ngIf="frmPartner.get('oib').errors?.err">{{frmRacun.get('oib').errors?.err}}</mat-error> -->
        <mat-error *ngIf="frmPartner.controls['oib'].errors?.err">{{frmPartner.controls['oib'].errors.err}}</mat-error>
    </mat-form-field>

    <div>

        <div class="card" style="margin: 2px 0; padding: 5px; cursor: pointer;" (click)="partnerFromOstali(x)" *ngFor="let x of objParneriOib">
            <div class="row" style="margin: 0;">
                <div class="col-8" style="padding: 0;">
                    <div style="font-weight: bold;">
                        {{x.naziv + ' ' + (x.djelatnost ? x.djelatnost : '')}}
                    </div>
                    <div>
                        {{(x.adresa ? x.adresa : '') + ' ' + (x.Mjesto ? x.Mjesto : '')}}
                    </div>
                    
                </div>
                <div class="col-4 d-flex justify-content-center align-items-center">
                    <div *ngIf="x.parID != 0" style="font-size: 0.8rem; color:green">
                        Vlastiti šifarnik
                    </div>
                    <div *ngIf="x.parID == 0" style="font-size: 0.8rem; color: orangered;">
                        Drugi izvor
                    </div>
                </div>
            </div>
        </div>
        
    </div>


    <div *ngIf="!objParneriOib && frmPartner && frmPartner.get('oib').valid">

        <mat-form-field style="width: 100%;">
            <input autocomplete="off" matInput formControlName="naziv" placeholder="naziv">
            <mat-error *ngIf="frmPartner.get('naziv').errors?.required">Polje je obavezno za unos</mat-error>
            <mat-error *ngIf="frmPartner.get('naziv').errors?.err">{{frmPartner.get('naziv').errors.err}}</mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%;">
            <input autocomplete="off" matInput formControlName="djelatnost" placeholder="djelatnost">
            <mat-error *ngIf="frmPartner.get('djelatnost').errors?.required">Polje je obavezno za unos</mat-error>
            <mat-error *ngIf="frmPartner.get('djelatnost').errors?.err">{{frmPartner.get('djelatnost').errors.err}}</mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%;">
            <input autocomplete="off" matInput formControlName="vlasnik" placeholder="vlasnik">
            <mat-error *ngIf="frmPartner.get('vlasnik').errors?.required">Polje je obavezno za unos</mat-error>
            <mat-error *ngIf="frmPartner.get('vlasnik').errors?.err">{{frmPartner.get('vlasnik').errors.err}}</mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%;">
            <input autocomplete="off" matInput formControlName="adresa" placeholder="adresa">
            <mat-error *ngIf="frmPartner.get('adresa').errors?.required">Polje je obavezno za unos</mat-error>
            <mat-error *ngIf="frmPartner.get('adresa').errors?.err">{{frmPartner.get('adresa').errors.err}}</mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%;">
            <mat-label>Mjesto</mat-label>
            <input type="text" placeholder="Upišite 3 slova iz naziva" aria-label="Mjesto" matInput formControlName="Mjesto" (keyup)="mjestoEmpty()" [matAutocomplete]="auto">
            <mat-error *ngIf="frmPartner.get('Mjesto').errors?.mustMatch">Odaberite mjesto s liste</mat-error>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let x of fltMjesta" [value]="x.Mjesto" (onSelectionChange)="postaviMjesto(x)">
                    {{x.Mjesto + ' ' + x.Broj_poste}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

    </div>

    <div class="d-flex" style="margin-top: 10px;">
        <button *ngIf="!objParneriOib && frmPartner && frmPartner.get('oib').valid" type="button" class="btn btn-success" (click)="spremi()"><i class="fa fa-floppy-o" aria-hidden="true"></i> SPREMI</button>
        <button *ngIf="objParneriOib" type="button" class="btn btn-secondary" (click)="objParneriOib = null">Unesi ručno</button>
        <button *ngIf="srvKlPartneri.objPartTmp" type="button" class="btn btn-secondary ml-auto" (click)="srvKlPartneri.objPartTmp = null;">Otkaži uređivanje</button>
        <button *ngIf="!srvKlPartneri.objPartTmp" type="button" class="btn btn-primary ml-auto" (click)="oibDaNe()">{{bolOibDaNe ? 'UNESI BEZ OIB-a' : 'PRIKAŽI OIB'}}</button>
    </div>


</form>
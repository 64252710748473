import { Component, OnInit, Input } from '@angular/core';
import { LoginService } from '../login.service';
import { RacuniService } from '../racuni.service';
import { AppComponent } from '../app.component';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';


@Component({
  selector: 'app-racuni',
  templateUrl: './racuni.component.html',
  styleUrls: ['./racuni.component.css']
})

export class RacuniComponent implements OnInit {

  constructor(

    private loginService: LoginService,
    public racuniService: RacuniService,
    public appComponent: AppComponent,
    public http: HttpClient,
    public appService: AppService

  ) { }

  lblPoruka: string = 'Pregled računa';


  ngOnInit() {

    this.loginService.autorizirajStranicu(() => {
      this.appComponent.sidenav.open();
    });


  }

  ngOnDestroy() {
    this.racuniService.rsDataSource = null;
    this.appComponent.objStavke = null;
  }

  //btnStavke Click
  btnStavkeClick(rnID: string) {

    this.appComponent.objStavke = [];

    let strUrl: string = this.appService.webUrl + 'php/racuni_stavke.php?racunID=' + rnID;

    this.http.get(strUrl).toPromise().then(rez => {

      //alert(rez[0].Naziv);

      this.appComponent.objStavke = rez;


    });


  }

}

<form *ngIf="frmArtikl" [formGroup]="frmArtikl">

    <mat-form-field>
        <mat-label>Vrsta</mat-label>
        <mat-select formControlName="artVrsta" name="artVrsta">
            <mat-option [value]="'1'">Roba</mat-option>
            <mat-option [value]="'2'">Usluga</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 100%;">
        <mat-label>Opis</mat-label>
        <textarea autocomplete="off" type="text" aria-label="artNaziv" matInput formControlName="artNaziv"
            style="text-align: start; font-weight: bold;" #artNaziv></textarea>
        <mat-error *ngIf="frmArtikl.get('artNaziv').errors?.required">Polje je obavezno za unos</mat-error>
        <mat-error *ngIf="frmArtikl.get('artNaziv').errors?.err">{{frmArtikl.get('artNaziv').errors.err}}</mat-error>
    </mat-form-field>

    <mat-form-field style="width: 30%;">
        <mat-label>Jed. mj.</mat-label>
        <input autocomplete="off" type="text" aria-label="jedMj" matInput formControlName="jedMj"
            style="text-align: center; font-weight: bold;" #jedMj>
        <mat-error *ngIf="frmArtikl.get('jedMj').errors?.required">Polje je obavezno za unos</mat-error>
        <mat-error *ngIf="frmArtikl.get('jedMj').errors?.err">{{frmArtikl.get('jedMj').errors.err}}</mat-error>
    </mat-form-field>

    <mat-form-field style="width: 30%;">
        <mat-label>PDV %</mat-label>
        <input autocomplete="off" type="tel" aria-label="artPdvStopa" matInput formControlName="artPdvStopa"
            style="text-align: center; font-weight: bold;" (blur)="decFormat($event)" #artPdvStopa>
        <mat-error *ngIf="frmArtikl.get('artPdvStopa').errors?.required">Polje je obavezno za unos</mat-error>
        <mat-error *ngIf="frmArtikl.get('artPdvStopa').errors?.err">{{frmArtikl.get('artPdvStopa').errors.err}}
        </mat-error>
    </mat-form-field>

    <mat-form-field style="width: 30%;">
        <mat-label>Povratna naknada</mat-label>
        <input autocomplete="off" type="tel" aria-label="artPovNak" matInput formControlName="artPovNak"
            style="text-align: center; font-weight: bold;" (blur)="decFormat($event)" #artPovNak>
        <mat-error *ngIf="frmArtikl.get('artPovNak').errors?.required">Polje je obavezno za unos</mat-error>
        <mat-error *ngIf="frmArtikl.get('artPovNak').errors?.err">{{frmArtikl.get('artPovNak').errors.err}}</mat-error>
    </mat-form-field>

    <mat-form-field style="width: 30%;">
        <mat-label>Cijena</mat-label>
        <input autocomplete="off" type="tel" aria-label="artCijena" matInput formControlName="artCijena"
            style="text-align: center; font-weight: bold;" (blur)="decFormat($event)" #artCijena>
        <mat-error *ngIf="frmArtikl.get('artCijena').errors?.required">Polje je obavezno za unos</mat-error>
        <mat-error *ngIf="frmArtikl.get('artCijena').errors?.err">{{frmArtikl.get('artCijena').errors.err}}</mat-error>
    </mat-form-field>

</form>

<div>
    <div>
        <button type="submit" (click)="spremiArtikl()" class="btn btn-outline-success"
            style="width: 50%;">Potvrdi</button>
        <button id="mdlDismiss" type="button" class="btn btn-outline-secondary" style="width: 50%;"
            data-dismiss="modal">Odustani</button>
    </div>
</div>
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';


//Must Match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return null;
        }

        // set error on matchingControl if validation fails
        if (control.value != matchingControl.value) {
           control.setErrors({ mustMatch: true });
        } else {
            control.setErrors(null);
            //return null;
        }
    }
}

//Decimal validator
export function decimalCheck(v: FormControl) {

    let vTmp = v.value.toString();

    let res = vTmp.match('^-?[0-9]\\d*(\\,\\d{1,})?$');
    let res1 = vTmp.match('^-?[0-9]\\d*(\\.\\d{1,})?$');

    if (res && res.length > 0 || res1 && res1.length > 0) {
        return null;
    
    } else {

       /*  if (v.hasError && v.getError('err')) {
            return null;
        } else {
            
        } */
        return { err: 'Neispravan unos' };
    }

}

//Provjera jel unos broj
export function jelBroj(v: FormControl) {

    let vTmp = v.value;

    if(vTmp == 0) {
        return null;
    }
    if(!Number(vTmp)) {
        return { err: 'Polje mora biti broj' };
    } else {
        return null;
    }

}

//Provjera jel unos Datum
export function jelDatum(v: FormControl) {

    let vTmp = v.value;

    if(vTmp instanceof moment) {
        return null;
    } else {
        return { err: 'neispravan datum'};
    }

}

//Validator - maxH
export function maxH(v: FormControl) {

    if (!isNaN(parseInt(v.value))) {

        if (parseInt(v.value) > 24 || parseInt(v.value) < 0) {
            return { err: 'broj ne može biti veći od 24' };
        } else {
            return null;
        }

    } else {
        return { err: 'polje mora biti broj 0-24' };
    }

}

//Validator - maxMin
export function maxMin(v: FormControl) {

    if (!isNaN(parseInt(v.value))) {

        if (parseInt(v.value) > 59 || parseInt(v.value) < 0) {
            return { err: 'broj ne može biti veći od 59' };
        } else {
            return null;
        }

    } else {
        return { err: 'polje mora biti broj 0-59' };
    }

}

//Validator - Provjera OIB-a
export function oibChk(v: FormControl) {

    //console.log('v.value = ' + v.value)

    if (v.value) {

        let oib: string = v.value.toString();

        if (oib.length != 11) return { err: 'OIB mora sadržavati 11 znamenki' };

        let b: number = parseInt(oib, 10);
        if (isNaN(b)) return { err: 'Svi znakovi moraju biti numerički' };

        let a: number = 10;
        for (let i = 0; i < 10; i++) {
            a = a + parseInt(oib.substr(i, 1), 10);
            a = a % 10;
            if (a == 0) a = 10;
            a *= 2;
            a = a % 11;
        }

        let kontrolni: number = 11 - a;
        if (kontrolni == 10) kontrolni = 0;

        if (kontrolni == parseInt(oib.substr(10, 1))) {
            return null;
        } else {
            return { err: 'Neispravan OIB' };
        }
    } else {
        //return null;
        return null; //{ err: 'Obavezan unos' };
    }
    
}
<div class="container" id="content">

    <!--OKVIR ZA ROW-->
    <section>

        <div *ngIf="this.racuniService.ucitavanje">
            <div style="font-size: 0.8rem;">učitavanje...</div>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <br />
        </div>

        <!--BANNER - samo ispis-->
        <div class="row da-print" style="display: none; width: auto;">
            <hr />
            <img src="assets/baneri/{{appComp.korID}}.jpg" class="img-fluid">
            <hr />
        </div>

        <!---->
        <div class="row da-print text-center" style="display: none; margin: 50px;">
            <div>
                <span style="font-size: 1.5rem;">Analitička kartica prometa</span> <br />
                <span>razdoblje od {{appComp.dat1 | date:'dd.MM.yyyy.'}} do {{appComp.dat2 | date:'dd.MM.yyyy.'}}</span>
            </div>
        </div>


        <div *ngIf="appComp.partnerNaziv" class="row alert-info" style="padding: 10px;">


            <div class="col-10">
                <h5>{{appComp.partnerNaziv}} - {{appComp.chkKupci == 1 ? 'Kupci' : 'Dobavljači'}}</h5>

                <span>{{appComp.partnerAdresa}}{{!appComp.partnerAdresa ? appComp.partnerMjesto : ', ' + appComp.partnerMjesto}}</span>
                <br />
                <span>OIB: {{appComp.partnerOIB}}</span> <br />
            </div>
            <div class="col-2 ne-print text-center">
                <button type="button" class="btn btn-outline" (click)="ispis()"><i class="material-icons"
                        style="font-size: 2rem;">
                        print
                    </i>
                </button>
            </div>


        </div>



        <!--ZAGLAVLJE-->
        <div *ngIf="this.racuniService.objSaldaKonti?.length && !this.racuniService.ucitavanje" class="row alert-info print-info" style="padding: 10px;">
            <div class="col-6">
                Datum / Opis
            </div>
            <!-- <div class="col-3">
                Opis
            </div> -->
            <div class="col-3 text-right">
                Duguje
            </div>
            <div class="col-3 text-right">
                Potražuje
            </div>
        </div>

        <!--DONOS-->
        <div *ngIf="this.racuniService.dDuguje && this.racuniService.dPotrazuje && !this.racuniService.ucitavanje" class="row" style="padding: 10px; border-bottom: solid darkslateblue 0.5px;">
            <div class="col-3">
                Donos:
            </div>
            <div class="col-3">

            </div>
            <div class="col-3 text-right">
                {{racuniService.dDuguje | number : '2.1-2'}}
            </div>
            <div class="col-3 text-right">
                {{racuniService.dPotrazuje | number : '2.1-2'}}
            </div>
        </div>

        <!--STAVKE-->
        <div class="row">
            <div class="col-12">
                <div *ngIf="!this.racuniService.objSaldaKonti?.length && !this.racuniService.ucitavanje">
                    <div style="font-size: 1.2rem;">
                        <p>Nema stavki u zadanom periodu!</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngFor="let x of racuniService.objSaldaKonti" style="padding: 10px; border-bottom: solid darkslateblue 0.5px;">


            <!-- <div class="col-3">
                {{x.DatumRn}}
            </div> -->
            <div class="col-6">
                {{x.DatumRnF + ' ' + x.SvrhaDoznake + ' ' + x.BrRn}}
            </div>
            <div class="col-3 text-right">
                {{x.Duguje | number : '1.2-2'}}
            </div>
            <div class="col-3 text-right">
                {{x.Potrazuje | number : '1.2-2'}}
            </div>

        </div>

        <!--SALDO-->
        <div *ngIf="!this.racuniService.ucitavanje" class="row" style="padding: 10px; border-bottom: solid darkslateblue 0.5px;">
            <div class="col-6">
                Promet:
            </div>
            <div class="col-3 text-right">
                {{racuniService.sDuguje | number : '1.2-2'}}
            </div>
            <div class="col-3 text-right">
                {{racuniService.sPotrazuje | number : '1.2-2'}}
            </div>
        </div>

        <div *ngIf="!this.racuniService.ucitavanje" class="row" style="padding: 10px; border-bottom: solid darkslateblue 0.5px;">
            <div class="col-6">
                Saldo:
            </div>
            <div class="col-6 text-right">
                <strong>{{racuniService.sSaldo | number : '1.2-2'}}</strong>
            </div>
        </div>



    </section>

    <!--FOOTER-->



</div>
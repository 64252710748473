import { Component, OnInit } from '@angular/core';
import { RacuniService } from '../racuni.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-placanja',
  templateUrl: './placanja.component.html',
  styleUrls: ['./placanja.component.css']
})

export class PlacanjaComponent implements OnInit {

  constructor(public racuniSrv: RacuniService, public appComp: AppComponent) { }

  //objPlacanja;


  ngOnInit() {

    //alert("objPlacanja: " + JSON.stringify(this.objPlacanja));
    this.appComp.sidenav.open();
    
  }


}

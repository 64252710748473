import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { stringify } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { KlijentiService } from './klijenti.service';

export interface InfoKorisnik {
  korID: number, 
  PodaciIDinf: number, 
  Naziv: string, 
  accToken: string, 
  operIme: string, 
  operPrezime: string, 
  operOIB: string
}

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  constructor(private http: HttpClient, private router: Router, public appService: AppService, public srvKlijenti: KlijentiService) { }

  public intKorID: number = 0;

  //Prijava korisnika
  login(kTmp: string, lTmp: string, callback) {

    let obj0: string = JSON.stringify({ kTmp: kTmp, lTmp: lTmp });
    //let obj0 = {kTmp: kTmp, lTmp: lTmp};

    this.http.post(this.appService.webUrl + 'php/login.php', obj0).subscribe((obj1: InfoKorisnik) => {

      if (obj1) {

        localStorage.setItem('operaterID', obj1.korID.toString());
        localStorage.setItem('korID', obj1.PodaciIDinf.toString());
        this.intKorID = obj1.PodaciIDinf;
        localStorage.setItem('nazivP', obj1.Naziv);
        this.appService.strKorisnik = localStorage.nazivP;

        if (obj1['PodaciIDinf'] == 1) {
          localStorage.accAdmin = 'true';
          this.appService.accAdmin = true;
        } else {
          localStorage.accAdmin = 'false';
          this.appService.accAdmin = false;
        }

        localStorage.setItem('objInfoKorinsik', JSON.stringify(obj1));
        this.appService.objInfoKorinsik = obj1;

        this.srvKlijenti.podaciByID(obj1.PodaciIDinf, () => { });
        this.srvKlijenti.ucitajPoslNapl(obj1.PodaciIDinf, () => { });

        this.router.navigate(['/pocetna']);

      } else {

        localStorage.removeItem('korID');
        this.intKorID = 0;
        localStorage.removeItem('nazivP');
        localStorage.accAdmin = 'false';
        this.appService.accAdmin = false;

      }

      callback();

    });

  }

  //Provjera jel' korisnik još prijavljen (accTok -> localStorage)
  autorizirajStranicu(callback) {

    //alert('AUTORIZACIJA STRANICE');

    let tokTmp: string = localStorage.getItem("korID");

    //Provjera login tokena
    if (!tokTmp) {
      this.intKorID = 0;
      this.router.navigate(['/login']);
    } else {
      this.intKorID = parseInt(localStorage.getItem("korID"));
      callback(this.intKorID);
    }


    //alert('login service intKorID = ' + this.intKorID);

    //return this.intKorID;
  }




}

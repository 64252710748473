import * as moment from "moment";

/* export interface FisRn {
    oib: string,
    havePDV: boolean,
    dateTime: moment.Moment,
    noteOfOrder: string,
    listPDV: TaxRate[],
    listPNP: TaxRate[],
    listOtherTaxRate: TaxRate[],
    taxFreeValuePdv: number,
    marginForTaxRate: number,
    taxFreeValue: number,
    refund: FisRefund[],
    totalValue: number,
    typeOfPaying: string,
    oibOperative: number;
    //securityCode: string
    noteOfRedelivary: boolean,
    noteOfParagonBill: number;
    specificPurpose: string
} */

/* export interface TaxRate {
    name: string // Naziv
    rate: number // Stopa
    baseValue: number // Osnovica
    value: number // Iznos
}
 */

/* export interface FisRefund {
    nameRefund: string,
	valueRefund: number
} */

export class FisRacun {

   /*  constructor(
        public racun: FisRn
    ){} */

    public oib: string; // OIB obveznika fiskalizacije
    public havePDV: boolean; // u sustavu PDV-a
    public dateTime: string; // Datum i vrijeme računa
    public noteOfOrder: string; // oznaka slijednosti računa
    public billNumber: BillNumber; // broj računa
    public noteOfBusinessArea: string; // oznaka poslovnog prostora
    public numberNoteBill: number; // broj naplatnog uređaja
    public listPDV: TaxRate[]; // ukupno PDV razvrstan po stopama
    public listPNP: TaxRate[]; // ukupno porez na poštrošnju razvrstan po stopama
    public listOtherTaxRate: TaxRate[]; // ukupno ostali porezi razvrstani po nazivu i stopama
    public taxFreeValuePdv: number; // oslobođeno plaanja PDV-a
    public marginForTaxRate: number; // oporezivanje marže
    public taxFreeValue: number; // ukupno ne podliježe PDV-u (pov. naknada...)
    public refund: Refund[]; // naknade
    public totalValue: number; // Ukupan iznos računa
    public typeOfPaying: string; // načunplaanja (G,K,C,T,O)
    public oibOperative: string; // OIB operatera
    //securityCode: string (generira se na serveru)  // zaštitni kod (ZKI)
    public noteOfRedelivary: boolean; // naknadno dostavljen
    public noteOfParagonBill: number; // broj paragona
    public specificPurpose: string; // posebna namjena

}

export class TaxRate {
    public name: string; // Naziv
    public rate: number; // Stopa
    public baseValue: number; // Osnovica
    public value: number; // Iznos
}

export class BillNumber {
    numberNoteBill: number;
    noteOfBusinessArea: string;
    noteOfExcangeDevice: number;
}

export class Refund {
    nameRefund: string;
    valueRefund: number;
}

import { Component, OnInit } from '@angular/core';
import { Artikl, KlijentiIrService } from '../klijenti-ir.service';
import { KlijentiService } from '../klijenti.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-klijenti-artikli-sifarnik',
  templateUrl: './klijenti-artikli-sifarnik.component.html',
  styleUrls: ['./klijenti-artikli-sifarnik.component.css']
})
export class KlijentiArtikliSifarnikComponent implements OnInit {

  constructor(
    public srvLogin: LoginService,
    public srvKlijenti: KlijentiService,
    public srvKlijentiIr: KlijentiIrService
  ) { }

  strPretaga: string;

  objArtikli: Artikl[];
  fltArtikli: Artikl[];

  ngOnInit(): void {
    this.artikliByKlijentId();
    this.srvKlijentiIr.objArtiklSub$.subscribe(data => {
      this.artikliByKlijentId();
    });
  }

  //Artikli by klijent ID
  artikliByKlijentId() {
    if (localStorage.getItem)
      this.srvKlijenti.artikliByKlijentId(this.srvLogin.intKorID, (rez: Artikl[]) => {
        console.log(rez);
        this.objArtikli = rez;
        this.fltArtikli = rez;
      });
  }

  //Filtriranje artikala
  filterArtikli() {
    if (!this.strPretaga) {
      this.fltArtikli = this.objArtikli;
    } else {
      this.fltArtikli = this.srvKlijentiIr.filterArtikli(this.objArtikli, this.strPretaga);
    }
  }

  //novi artikl
  noviArtikl() {
    this.srvKlijentiIr.objArtStav.next({ artID: 0, artNaziv: ''});

    document.getElementById('btnMdlArtikl').click();
  }

  //Uređivanje
  urediArtikl(x: Artikl) {

    this.srvKlijentiIr.objArtStav.next({ artID: x.artID, artNaziv: x.artNaziv });

    document.getElementById('btnMdlArtikl').click();
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { AppService } from './app.service';
import { VwPartner } from './partneri.service';
import { Racun } from './racuni.service';

export interface VwKpi extends Racun, VwPartner {

}

export interface KpiFilter {
  Podaci_ID: number,
  DatumOd: Moment,
  DatumDo: Moment,
  intKupDob: number
}

@Injectable({
  providedIn: 'root'
})
export class KpiService {

  constructor(
    private appService: AppService,
    private http: HttpClient,
    public frmBuilder: FormBuilder
  ) { }

  objVwKpi: VwKpi[];

  frmKpiFilter: FormGroup

  decPromet: number;

  napraviFrmKpiFilter(val: KpiFilter, callback) {

    let korID: number = JSON.parse(localStorage.getItem('korID'));

    this.frmKpiFilter = this.frmBuilder.group({
      Podaci_ID: [korID],
      DatumOd: [moment(moment().format('YYYY') + '-01-01').toDate()],
      DatumDo: [moment().toDate()],
      intKupDob: [1]
    });

    if (val) {
      this.frmKpiFilter.patchValue(val);
    }

    callback(this.frmKpiFilter.value)

  }

  //prometPoPartnerima
  prometPoPartnerima(val: KpiFilter, callback) {

    let url: string = this.appService.webUrl + 'php/kpi.php?funk=prometPoPartnerima';
    let obj: string = JSON.stringify(val);

    this.http.post(url, obj).subscribe((rez: VwKpi[]) => {
      callback(rez);
    });

  }

  //Zbroj prometa
  ukupnoPromet() {

    let ukTmp: number = 0;

    this.objVwKpi.forEach(val => {
      ukTmp += Number(val.Duguje) + Number(val.Potrazuje)
    });

    this.decPromet = ukTmp;

  }

}
